import React, { useState, useMemo } from "react";
import { Navigate } from "react-router-dom";
import Nav from "./layout/Nav";
import Sidebar from "./layout/Sidebar";
import jwtDecode from "jwt-decode";
import ErrorBoundary from "../components/ErrorBoundary";
import { getToken } from "../utils";
import EmbedSocialWidget from "../EmbedSocialWidget.js";

// const PrivateRoute = ({ component: Component, heading, userRole, ...rest }) => {
//   const authValue = localStorage.getItem("token");
//   const [role, setRole] = React.useState();

//   React.useEffect(() => {
//     if (authValue) {
//       setRole(
//         jwtDecode(localStorage.getItem("token"))[
//           "https://hasura.io/jwt/claims"
//         ]["x-hasura-allowed-roles"][0]
//       );
//     }
//   }, [authValue]);

//   return (
//     <>
//       {!authValue ? (
//         <Navigate to="/" replace />
//       ) : (
//         role === userRole && (
//           <>
//             <div className="flex w-full">
//               <div className="w-2/12 flex-shrink-0 min-h-screen shadow-1">
//                 <Sidebar></Sidebar>
//               </div>
//               <div className=" w-10/12">
//                 <Nav heading={heading}></Nav>
//                 <Component />
//               </div>
//             </div>
//           </>
//         )
//       )}
//     </>
//   );
// };

// export default PrivateRoute;

// import React, { useState } from "react";
// import { Route, Redirect } from "react-router-dom";
// import { getToken } from "../utils";
// import jwtDecode from "jwt-decode";
// import ErrorBoundary from "../components/ErrorBoundary";

const PrivateRoute = (props) => {
  console.log(props);

  const { component: Component, heading, ...rest } = props;
  // eslint-disable-next-line
  // const [role, setRole] = useState(
  //   jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
  //     "x-hasura-default-role"
  //   ]
  // );
  const hasToken = getToken();

  const role = useMemo(() => {
    if (hasToken)
      return jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
  }, [hasToken]);

  console.log(role, "gettoken");
  return (
    <>
      {hasToken ? (
        <>
          {role === rest.roles[0] ? (
            <>
              <div className="flex w-full">
                {role !== "super_admin" && (
                  <EmbedSocialWidget refid="a23362c2eb3e55988a3d4484d4c35d2e2e3e5632" />
                )}
                <div className="w-2/12 flex-shrink-0 min-h-screen shadow-1">
                  <Sidebar></Sidebar>
                </div>
                <div className=" w-10/12">
                  <Nav heading={heading}></Nav>
                  <Component />
                </div>
              </div>
            </>
          ) : (
            <ErrorBoundary />
          )}
        </>
      ) : (
        <Navigate to="/" replace />
      )}
    </>
  );
};

export default PrivateRoute;
