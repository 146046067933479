import { Tag as AntTag } from "antd";
import React from "react";
import styled from "styled-components";

function Tag({ children, color }) {
  const TagStyle = styled.div`
    .ant-tag {
      padding-left: 12px;
      padding-right: 12px;
      background-color: ${color};
      opacity: 0.8;
    }
  `;
  return (
    <TagStyle>
      <AntTag color={` ${color ? color : ""}`}>{children}</AntTag>
    </TagStyle>
  );
}

export default Tag;
