import { useEffect, useContext, useState } from "react";
import { Button, Card, Input, Form, notification, Spin } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import logo from "../../assets/logo.svg";
import google from "../../assets/google.svg";
import EmailContext from "../../context/EmailContext";
import { useNavigate, useLocation } from "react-router-dom";
import { LOGIN_EMAIL, COMPANY_DETAILS } from "./graphql";
import jwtDecode from "jwt-decode";

function LoginPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const userMail = useContext(EmailContext);
  const [enableLoader, setEnableLoader] = useState(false);
  const [loginEmail] = useMutation(LOGIN_EMAIL);
  const handleGoogleLogin = () => {
    window.location.href = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  };

  const { data, loading, refetch } = useQuery(COMPANY_DETAILS, {
    variables: {
      id: parseInt(
        localStorage.getItem("token")
          ? jwtDecode(localStorage.getItem("token"))[
              "https://hasura.io/jwt/claims"
            ]["X-Hasura-User-Id"]
          : 0
      ),
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (
      localStorage.token &&
      jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
        "x-hasura-allowed-roles"
      ][0] === "company_user" &&
      data &&
      data.company.length !== 0 &&
      props.title !== "signup"
    ) {
      navigate(`/dashboard?base_currency=${data.company[0].base_currency}`);
    } else if (
      localStorage.token &&
      jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
        "x-hasura-allowed-roles"
      ][0] === "super_admin"
    ) {
      navigate("/admin/overview");
    }
    // eslint-disable-next-line
  }, [location, data]);

  const onFinish = (values) => {
    setEnableLoader(true);
    loginEmail({
      variables: {
        email: values.email.toLowerCase(),
      },
    }).then((res) => {
      if (res.data.loginmail.status === "success") {
        if (props.title === "signup") {
          userMail.setEmail(values.email.toLowerCase());
          setEnableLoader(false);
          navigate("/signup-otp");
        } else {
          userMail.setEmail(values.email.toLowerCase());
          setEnableLoader(false);
          navigate("/otp-verification");
        }
      } else {
        notification.error({ message: "Please enter a valid Email id" });
        setEnableLoader(false);
      }
    });
  };

  return (
    <Spin spinning={enableLoader}>
      <div className="flex justify-center items-center h-screen">
        <Card
          style={{
            width: `${props.title === "signup" ? "448px" : "460px"} `,
          }}
          bordered={false}
        >
          <div
            className={`flex flex-col justify-center ${
              props.title === "signup" ? "items-left" : "items-center"
            } `}
          >
            <img
              src={logo}
              alt="expense-insights"
              style={{ width: "49px", height: "49px" }}
            />
            {props.title === "signup" ? (
              <p className="text-text_27 font-extrabold mt-8 mb-0">
                Sign up for{" "}
                <span className="text-indigo">GetInsights.finance</span>{" "}
              </p>
            ) : (
              <>
                <p className="text3xl mt-8 mb-0">Welcome back! </p>
                <p className="textsm mt-2">Please enter your details</p>
              </>
            )}
          </div>
          <div
            className={`${
              props.title === "signup" ? " py-6" : "p-6 shadow-lg"
            }`}
          >
            <Form
              name="login"
              layout="vertical"
              requiredMark={false}
              onFinish={onFinish}
            >
              <Form.Item
                label={<span className="textsm">Email</span>}
                name="email"
                rules={[
                  { message: "Enter your email", required: true },
                  { type: "email", message: "Enter a valid email!" },
                ]}
              >
                <Input
                  size="large"
                  type="text"
                  placeholder="Enter your email"
                  style={{ borderRadius: "6px" }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="buttonCss"
                  block
                  size="large"
                >
                  CONTINUE
                </Button>
              </Form.Item>
            </Form>
            <div className="divider">
              <p className="text-center text-gray-500 text-sm mx-2 mb-0">
                or continue with
              </p>
            </div>
            <div id="signIn">
              <Button
                style={{ borderColor: "#4F46E5", display: "flex" }}
                className="buttonGoogle"
                block
                size="large"
                onClick={() => handleGoogleLogin()}
              >
                <img src={google} alt="google" /> Google Account
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </Spin>
  );
}

export default LoginPage;
