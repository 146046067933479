import React, { useState } from "react";
import { Input } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "../assets/icons/searchCloseIcon.svg";

export default function Search({
  searchVisible,
  setSearchVisible,
  setSelectedCategory,
  selectedCategory,
  placeholder,
  role,
  ...rest
}) {
  const navigate = useNavigate();
  const search = useLocation().search;
  const [searchText, setSearchText] = useState(null);
  const recentList = localStorage.getItem(`recentSearches_${role}`)
    ? JSON.parse(localStorage.getItem(`recentSearches_${role}`)).reverse()
    : [];

  React.useEffect(() => {
    setSearchText(
      new URLSearchParams(search).get("searchText") !== null
        ? new URLSearchParams(search).get("searchText")
        : null
    );
  }, [search]);

  const handlesearch = (value) => {
    // localStorage.setItem("searchValue", value);
    var names =
      JSON.parse(localStorage.getItem(`recentSearches_${role}`)) !== null
        ? JSON.parse(localStorage.getItem(`recentSearches_${role}`))
        : [];
    if (names.length < 3) {
      names.push(value);
    } else {
      names.push(value);
      names.shift();
    }
    localStorage.setItem(`recentSearches_${role}`, JSON.stringify(names));
    role === "company_user"
      ? value === ""
        ? navigate(`/reports`)
        : navigate(
            `/reports?searchText=${value}&category=${selectedCategory || null}`
          )
      : value === ""
      ? navigate(`/admin/users`)
      : navigate(
          `/admin/users?searchText=${value}&category=${
            selectedCategory || null
          }`
        );
    setSelectedCategory("");
    setSearchVisible(false);
    // setSearchText(null);
  };
  return (
    <>
      <div className=" relative h-10 w-96">
        <div
          className={`absolute left-0 transition-all duration-500 right-0 border border-indigo rounded-lg ${
            searchVisible && selectedCategory ? " w-[450px] p-5 bg-white" : ""
          } `}
        >
          <Input
            placeholder={placeholder}
            className={`border-none  top-0 left-0 transition-all duration-500 rounded-lg ${
              searchVisible ? " shadow-md" : ""
            } ${
              selectedCategory && searchVisible ? " border-none shadow-sm" : ""
            }`}
            suffix={
              searchVisible ? (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setSearchVisible(false);
                    setSelectedCategory("");
                  }}
                >
                  <img src={SearchIcon} alt="close icon" />
                </span>
              ) : null
            }
            prefix={
              selectedCategory ? (
                <div
                  className=" border border-indigo rounded-md text-xs font-semibold text-indigo px-3 py-2 cursor-pointer flex justify-center items-center mr-5 "
                  onClick={() => {
                    setSelectedCategory("");
                  }}
                >
                  {selectedCategory}{" "}
                  <CloseOutlined style={{ color: "#000" }} className="ml-8" />
                </div>
              ) : (
                <SearchOutlined className=" mr-4" />
              )
            }
            size="large"
            onFocus={() => {
              setSearchVisible(true);
            }}
            onClick={() => {
              setSearchVisible(true);
            }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onPressEnter={(e) => {
              handlesearch(e.target.value);
            }}
          />
          {selectedCategory && recentList && recentList.length !== 0 && (
            <div
              className={` rounded-lg  left-0 right-0 top-full bg-white pt-5`}
            >
              <div className=" text-sm font-normal text-black mb-2">
                Recent searches
              </div>
              {recentList.map((item) => {
                return (
                  <div
                    className=" text-sm font-semibold text-black mb-2 cursor-pointer"
                    onClick={() => {
                      setSearchText(item);
                      handlesearch(item);
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          )}
          <div
            className={` absolute left-0 right-0 bg-white top-full mt-2 py-2 px-3 border-indigo border shadow-md rounded-br-lg rounded-bl-lg transition duration-500 ${
              !searchVisible || selectedCategory
                ? " opacity-0 pointer-events-none"
                : " opacity-100"
            } `}
            // onMouseEnter={() => {
            //   setSearchVisible(true);
            // }}
          >
            {recentList && recentList.length !== 0 && (
              <div
                className={` rounded-lg  left-0 right-0 top-full bg-white pt-5`}
              >
                <div className=" text-xs font-normal text-black mb-2">
                  Recent searches
                </div>
                {recentList.map((item) => {
                  return (
                    <div
                      className=" text-sm font-semibold text-black mb-2 cursor-pointer hover:text-indigo"
                      onClick={() => {
                        setSearchText(item);
                        handlesearch(item);
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            )}
            <div className=" text-xs font-normal text-black mb-2">
              Search in
            </div>
            {role === "company_user" ? (
              <div className="flex items-center gap-4">
                <div
                  className=" border border-indigo rounded-md text-xs font-semibold text-indigo px-3 py-2 cursor-pointer "
                  onClick={() => {
                    setSelectedCategory("Expense");
                  }}
                >
                  Expense
                </div>
                <div
                  className=" border border-indigo rounded-md text-xs font-semibold text-indigo px-3 py-2 cursor-pointer "
                  onClick={() => {
                    setSelectedCategory("Category");
                  }}
                >
                  Category
                </div>
                <div
                  className=" border border-indigo rounded-md text-xs font-semibold text-indigo px-3 py-2 cursor-pointer "
                  onClick={() => {
                    setSelectedCategory("Tag");
                  }}
                >
                  Tag
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-4">
                <div
                  className=" border border-indigo rounded-md text-xs font-semibold text-indigo px-3 py-2 cursor-pointer "
                  onClick={() => {
                    setSelectedCategory("User");
                  }}
                >
                  User
                </div>
                <div
                  className=" border border-indigo rounded-md text-xs font-semibold text-indigo px-3 py-2 cursor-pointer "
                  onClick={() => {
                    setSelectedCategory("Email");
                  }}
                >
                  Email
                </div>
                <div
                  className=" border border-indigo rounded-md text-xs font-semibold text-indigo px-3 py-2 cursor-pointer "
                  onClick={() => {
                    setSelectedCategory("Company_name");
                  }}
                >
                  Company name
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
