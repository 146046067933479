import { Badge, Select as ReactSelect, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import SelectSuffix from "../../assets/icons/select.svg";

const { Option, OptGroup } = ReactSelect;
const { Paragraph } = Typography;

const SelectStyle = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #4f46e5;
    cursor: pointer;
  }
  .ant-select-selection-overflow {
    width: 90%;
  }
  .ant-select-selection-overflow-item {
    max-width: 80%;
    position: relative;
    &:nth-child(1) {
      width: 100%;
    }
  }
  .ant-select-selection-overflow-item {
    font-size: 14px;
  }
  .ant-select-selection-placeholder {
    font-size: 14px;
    line-height: 20px;
    color: #4f46e5;
    font-weight: 600;
  }
`;

export default function Select({ onChange, data, value, type, ...rest }) {
  const [selectValue, setSelectValue] = React.useState([]);
  React.useEffect(() => {
    setSelectValue(value || []);
  }, [value]);

  return (
    <SelectStyle>
      <ReactSelect
        // defaultValue="lucy"
        onChange={(value) => {
          setSelectValue(value);
          if (onChange) {
            onChange(value, type);
          }
        }}
        size={"large"}
        style={{
          width: 200,
        }}
        value={selectValue}
        showArrow
        mode="multiple"
        className=" border-indigo cursor-pointer"
        suffixIcon={<img src={SelectSuffix} alt="select suffix" />}
        dropdownClassName=" customSelect border-indigo border border-solid bg-white shadow-1 mt-4"
        tagRender={(props) => {
          if (props.value === selectValue[0]) {
            return (
              <div className="pl-4 py-2 max-w-[80%] cursor-pointer">
                <Paragraph ellipsis={true} className="!mb-0">
                  {props.value}
                </Paragraph>
                {selectValue.length >= 2 && (
                  <div className="absolute right-0 top-0 bottom-0 flex items-center justify-center ">
                    <Badge
                      count={selectValue.length}
                      style={{
                        background: "#E5E3FB",
                        color: "#4F46E5",
                      }}
                    ></Badge>
                  </div>
                )}
              </div>
            );
          }
        }}
        {...rest}
      >
        <OptGroup
          label={
            <div className="text-sm-l5-semibold font-normal text-[#ACB1BC]  flex items-center justify-center mx-auto text-center my-1">
              <span className="pb-0.5 border-b">Search for {type}</span>
            </div>
          }
        >
          {data.map((item, index) => {
            return (
              <Option value={item} key={`${item}-type-index`} className=" py-2">
                {item}
              </Option>
            );
          })}
        </OptGroup>
      </ReactSelect>
    </SelectStyle>
  );
}
