import { gql } from "@apollo/client";

export const LOGIN_EMAIL = gql`
  mutation LOGIN_EMAIL($email: String!) {
    loginmail(email: $email) {
      message
      status
    }
  }
`;

export const VALIDATE_OTP = gql`
  mutation VALIDATE_OTP($email: String!, $otp: Int!) {
    validateotp(email: $email, otp: $otp) {
      message
      status
      token
    }
  }
`;

export const INSERT_COMPANY = gql`
  mutation INSERT_COMPANY($object: [company_insert_input!]!) {
    insert_company(objects: $object) {
      affected_rows
      returning {
        id
        base_currency
      }
    }
  }
`;

export const UPDATE_USERS = gql`
  mutation UPDATE_USERS($userId: Int, $companyId: Int) {
    update_users(
      where: { id: { _eq: $userId } }
      _set: { company_id: $companyId }
    ) {
      affected_rows
    }
  }
`;

export const COMPANY_DETAILS = gql`
  query COMPANY_DETAILS($id: Int) {
    company(where: { users: { id: { _eq: $id } } }) {
      id
      name
      base_currency
    }
  }
`;

export const INSERT_USER_PLAN = gql`
  mutation INSERT_USER_PLAN($object: [users_x_plans_insert_input!]!) {
    insert_users_x_plans(objects: $object) {
      affected_rows
    }
  }
`;
