import { gql } from "@apollo/client";

export const ADMIN_LOGIN = gql`
  mutation ADMIN_LOGIN($email: String!, $password: String!) {
    admin_login(email: $email, password: $password) {
      message
      status
      token
    }
  }
`;

export const GET_USER_DETAILS_ADMIN = gql`
  query GET_USER_DETAILS($where: users_bool_exp) {
    users(where: $where, order_by: { created_at: desc }) {
      id
      name
      email
      created_at
      avatar
      company {
        name
        base_currency
      }
      users_x_plans {
        plan {
          name
        }
      }
    }
  }
`;

export const GET_USER_BY_PK = gql`
  query USER_BY_PK($id: Int!) {
    users_by_pk(id: $id) {
      id
      name
      email
      created_at
      comment
      avatar
      company {
        name
        base_currency
      }
      users_x_plans {
        plan {
          name
        }
      }
    }
  }
`;

export const UPDATE_USERS = gql`
  mutation UPDATE_USERS($id: Int, $comment: jsonb) {
    update_users(where: { id: { _eq: $id } }, _set: { comment: $comment }) {
      affected_rows
    }
  }
`;

export const GET_DOWNLOAD_URL = gql`
  mutation GET_VIEW_URL($name: String!) {
    getviewurl(file_name: $name) {
      status
      url
    }
  }
`;

export const UPDATE_AVATAR = gql`
  mutation UPDATE_AVATAR($avatar: String, $id: Int) {
    update_users(_set: { avatar: $avatar }, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        avatar
      }
    }
  }
`;

export const USERS_AGGREGATE = gql`
  query USERS_AGGREGATE($start: timestamptz, $end: timestamptz) {
    allusers: users_aggregate(where: { role_id: { _eq: 2 } }) {
      aggregate {
        count
      }
    }
    newusers: users_aggregate(
      where: {
        _or: { created_at: { _gt: $start, _lt: $end } }
        role_id: { _eq: 2 }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ADMIN_PASSWORD_CHANGE = gql`
  mutation ADMIN_PASSWORD_CHANGE(
    $email: String!
    $currentPassword: String!
    $newPassword: String!
  ) {
    admin_change_password(
      current_password: $currentPassword
      email: $email
      new_password: $newPassword
    ) {
      message
      status
    }
  }
`;

export const GET_PLANS = gql`
  query GET_PLANS {
    plans {
      name
      id
    }
  }
`;
