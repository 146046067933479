import { gql } from "@apollo/client";

export const EXPENSE_AGGREGATE = gql`
  query EXPENSE_AGGREGATE(
    $startdate: date!
    $enddate: date!
    $monthstartdate: date!
    $monthenddate: date!
    $quarterstartdate: date!
    $quarterenddate: date!
  ) {
    expense_aggregate(
      where: { date: { _gte: $startdate, _lte: $enddate } }
      order_by: { date: desc }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    currentMonth: expense_aggregate(
      where: { date: { _gte: $monthstartdate, _lte: $monthenddate } }
      order_by: { date: desc }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    quarterly: expense_aggregate(
      where: { date: { _gte: $quarterstartdate, _lte: $quarterenddate } }
      order_by: { date: desc }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export const TOP_CATEGORIES = gql`
  query TOP_CATEGORIES($startdate: date!, $enddate: date!, $id: Int) {
    categories {
      category_text
      categories_x_expenses(
        where: {
          expense: {
            _and: {
              user_id: { _eq: $id }
              date: { _gte: $startdate, _lte: $enddate }
            }
          }
        }
      ) {
        expense {
          id
          amount
        }
      }
    }
  }
`;

export const TOP_TAGS = gql`
  query TOP_TAGS($startdate: date!, $enddate: date!, $id: Int) {
    tags {
      tag_text
      expense_tags(
        where: {
          expense: {
            _and: {
              user_id: { _eq: $id }
              date: { _gte: $startdate, _lte: $enddate }
            }
          }
        }
      ) {
        expense {
          id
          amount
        }
      }
    }
  }
`;

export const LAST_5_TRANSACTION = gql`
  query LAST_5_TRANSACTION {
    expense(limit: 5, order_by: { date: desc }) {
      amount
      expense_text
      date
      id
      expense_tags {
        tag {
          tag_text
        }
      }
      categories_x_expenses {
        category {
          category_text
        }
      }
    }
  }
`;

export const COMPANY_DETAILS = gql`
  query COMPANY_DETAILS($id: Int) {
    company(where: { users: { id: { _eq: $id } } }) {
      id
      name
      base_currency
    }
  }
`;

export const GET_EXPENSE = gql`
  query GET_EXPENSE($startdate: date!, $enddate: date!, $id: Int) {
    expense(
      where: {
        _and: {
          date: { _gte: $startdate, _lte: $enddate }
          user_id: { _eq: $id }
        }
      }
    ) {
      amount
      expense_text
    }
    expense_aggregate(
      where: {
        _and: {
          date: { _gte: $startdate, _lte: $enddate }
          user_id: { _eq: $id }
        }
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;
