import { useQuery } from "@apollo/client";
import moment from "moment";
import { Spin } from "antd";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import ExpenseCard from "../../../components/expenseCard";
import Table from "../../../components/Table";
import { USERS_AGGREGATE } from "../graphql";

export default function Overview() {
  const { data, loading } = useQuery(USERS_AGGREGATE, {
    variables: {
      start: moment().startOf("week"),
      end: moment().endOf("week"),
    },
  });

  const columns = useMemo(
    () => [
      {
        title: "DATE JOINED",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "User",
        dataIndex: "expense",
        key: "expense",
      },
      {
        title: "email",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "Company Name",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "PLAN",
        dataIndex: "addInfo",
        key: "addInfo",
      },
    ],
    []
  );
  return (
    <Spin spinning={loading}>
      <div className="p-8">
        <div className="grid grid-cols-3 gap-8 mt-5">
          <ExpenseCard
            count={
              data?.allusers?.aggregate?.count === null
                ? 0
                : data?.allusers?.aggregate?.count
            }
            description={`All Users`}
            role="superadmin"
            className="flex-grow rounded-lg"
            range="allUsers"
          />
          <ExpenseCard
            count={0}
            description="Paid Users"
            role="superadmin"
            className="flex-grow rounded-lg"
            range="paidUsers"
          />
          <ExpenseCard
            count={
              data?.newusers?.aggregate?.count === null
                ? 0
                : data?.newusers?.aggregate?.count
            }
            description="New Users"
            role="superadmin"
            className="flex-grow rounded-lg"
            range="newUsers"
          />
        </div>
        <div className="py-8 flex justify-between">
          <div className="text-lg-l5-semibold">Recent Paid Users</div>
          <div>
            <Link
              to="/admin/users"
              className=" text-sm font-normal mt-5 text-indigo"
            >
              View all
            </Link>
          </div>
        </div>
        <div>
          <Table
            locale={{ emptyText: "No results found" }}
            dataSource={[]}
            columns={columns}
            pagination={true}
          ></Table>
        </div>
      </div>
    </Spin>
  );
}
