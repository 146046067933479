import { useLazyQuery } from "@apollo/client";
import { Tabs, Spin } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ExpensePieChart from "../../components/ExpensePieChart";
import { GET_EXPENSE } from "./graphql";
import moment from "moment";
import jwtDecode from "jwt-decode";

const { TabPane } = Tabs;

function TopExpenses(props) {
  const [roleID, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "X-Hasura-User-Id"
    ]
  );
  // const [activeTabExpense, setActiveTabExpense] = useState("monthly");
  const [data, setData] = useState([]);
  const [getExpenseList, { data: expenseList, loading: loadingPieChart }] =
    useLazyQuery(GET_EXPENSE);
  // const data = [
  // { name: "Group A", value: 400 },
  // { name: "Group B", value: 300 },
  // { name: "Group C", value: 300 },
  // ];

  const getDetails = (start, end) => {
    getExpenseList({
      variables: {
        startdate: start,
        enddate: end,
        id: parseInt(roleID),
      },
      fetchPolicy: "network-only",
    });
  };

  useEffect(() => {
    let startdate = moment().startOf("month").format("YYYY-MM-DD");
    let enddate = moment().endOf("month").format("YYYY-MM-DD");
    getDetails(startdate, enddate);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (expenseList && expenseList.expense.length !== 0) {
      let object = JSON.parse(JSON.stringify(expenseList.expense));
      const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
          (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
          );
          return result;
        }, {}); // empty object is the initial value for result object
      };
      const groupedData = groupBy(object, "expense_text");
      let newarray = [];
      Object.keys(groupedData).forEach(function (key, index) {
        let sum = 0;
        groupedData[key].map((item) => {
          sum += item.amount;
        });
        newarray.push({
          name: key,
          value: Math.abs(sum),
          correctVal: sum,
        });
      });
      const numDescending = [...newarray].sort((a, b) => b.value - a.value);

      let first3Expense = numDescending.slice(0, 5);
      let total = first3Expense.reduce(
        (arr, cur) => arr + (cur?.correctVal || 0),
        0
      );
      first3Expense.push({
        name: "Other",
        value: expenseList.expense_aggregate.aggregate.sum.amount - total,
        correctVal: expenseList.expense_aggregate.aggregate.sum.amount - total,
      });
      const COLORS = [
        "#3B34AC",
        "#534BDD",
        "#776FF9",
        "#958EFF",
        "#B6B2FE",
        "#D0CDF8",
      ];
      let finalPie = [];
      first3Expense.map((item, index) => {
        finalPie.push({
          title: item.name,
          color: COLORS[index],
          value: item.value,
          expenseValue: item.correctVal,
        });
      });

      setData(finalPie);
    } else if (expenseList && expenseList.expense.length === 0) {
      setData([]);
    }
  }, [expenseList]);

  useEffect(() => {
    if (props.activeTabExpense === "quarterly") {
      let startdate = moment().subtract(3, "months").format("YYYY-MM-DD");
      let enddate = moment().format("YYYY-MM-DD");
      getDetails(startdate, enddate);
    } else {
      let startdate = moment().subtract(30, "day").format("YYYY-MM-DD");
      let enddate = moment().format("YYYY-MM-DD");
      getDetails(startdate, enddate);
    }
  }, [props.activeTabExpense]);

  const onChange = (key) => {
    props.setActiveTabExpense(key);
  };

  return (
    <div className="shadow-lg px-5 py-10 min-h-46 w-full">
      <Tabs
        defaultActiveKey="1"
        activeKey={props.activeTabExpense}
        onChange={onChange}
        tabBarStyle={{ display: "flex", justifyContent: "space-between" }}
        tabBarExtraContent={{
          left: (
            <>
              <span className=" mr-4 font-medium text-base text-indigo">
                Top Expenses
              </span>
            </>
          ),
        }}
      >
        <TabPane
          key="monthly"
          tab={<span className="textHeadings">Last 30 days</span>}
        >
          <Spin spinning={loadingPieChart}>
            <ExpensePieChart
              data={data}
              total={
                Math.abs(
                  expenseList?.expense_aggregate?.aggregate?.sum?.amount
                ) || 0
              }
              base_currency={props.base_currency}
            />
          </Spin>
        </TabPane>
        <TabPane
          tab={<span className="textHeadings">Last 90 days</span>}
          key="quarterly"
        >
          <Spin spinning={loadingPieChart}>
            <ExpensePieChart
              data={data}
              total={
                Math.abs(
                  expenseList?.expense_aggregate?.aggregate?.sum?.amount
                ) || 0
              }
              base_currency={props.base_currency}
            />
          </Spin>
        </TabPane>
      </Tabs>
      <div className="float-right">
        <Link
          to={`/reports?dateRange=${props.activeTabExpense}&currency=${props.base_currency}`}
          className={`hideviewall text-sm font-normal  ${
            data.length === 0 ? "pointer-events-none text-gray-500" : ""
          }`}
        >
          View all
        </Link>
      </div>
    </div>
  );
}

export default TopExpenses;
