import { Button, Select as ReactSelect, Tag, Spin, Form, Input } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import Modal from "../../components/Modal";
// import styled from "styled-components";
import EmptyState from "../../assets/reports/emptyState.jpg";
import DatePicker from "../../components/DatePicker";
import Select from "../../components/Select";
import Table from "../../components/Table";
import {
  COMPANY_DETAILS,
  REPORTS_TABLE,
  GET_TAGS,
  GET_CATEGORIES,
  GET_DOWNLOAD_URL,
  GET_USER_PLAN,
} from "./graphql";
import { useNavigate, useLocation } from "react-router-dom";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import namedColors from "color-name-list";
import lightOrDarkColor from "@check-light-or-dark/color";
// import colorListArray from "css-color-names";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { capitalizeFirst, downloadCSVFile } from "../../utils";
import Download from "../../assets/icons/download.svg";

// const SelectCount = styled.div`
//   .ant-select-selector {
//     background: transparent !important ;
//     border: none !important;
//     & .ant-select-selection-item {
//       background-color: transparent !important;
//       border: none !important;
//     }
//   }
// `;

export default function Report() {
  const dateFormat = "DD/MMM/YYYY";
  const navigate = useNavigate();
  const search = useLocation().search;
  const location = useLocation();
  const [data, setData] = React.useState([]);
  const downloadDataRef = React.useRef("");
  const [roleID, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "X-Hasura-User-Id"
    ]
  );
  const [categoriesList, setcategoriesList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [searchCategories, setSearchCategories] = useState([]);
  const [searchTags, setSearchTags] = useState([]);
  const [initialCat, setInitialCat] = useState([]);
  const [initialTag, setInitialTag] = useState([]);
  const [initialDate, setInitialDate] = useState(null);
  const [form] = Form.useForm();
  const [colorList, setColorList] = useState([]);
  const [dateChanged, setDateChanged] = useState(false);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [showEditPopup, setShowEditPopup] = React.useState(false);
  const [expenseName, setExpense] = useState();
  const [searchTerm, setSearchTerm] = useState({});
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const todayDate = moment().format("DD/MMM/YYYY");

  const [getReports, { data: reports, loading: reportsLoading }] = useLazyQuery(
    REPORTS_TABLE,
    {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );
  const { data: planDetails, refetch: refetchUserPlan } = useQuery(
    GET_USER_PLAN,
    {
      variables: {
        id: parseInt(roleID),
      },
    }
  );

  const {
    data: company_details,
    loading: companyLoading,
    refetch: loadingCompany,
  } = useQuery(COMPANY_DETAILS, {
    variables: {
      id: parseInt(roleID),
    },
  });
  // eslint-disable-next-line
  const {
    data: categories,
    loading: loadingcategories,
    refetch: loadingcategoriesList,
  } = useQuery(GET_CATEGORIES, {
    variables: {
      id: parseInt(roleID),
    },
    onCompleted: (data) => {
      let newArray = [];
      let colorObject = {};
      let darkColorList = [];
      let checkList = namedColors.slice(500, 1600);

      // eslint-disable-next-line
      checkList.map((key, index) => {
        if (lightOrDarkColor(key.hex) === "dark") {
          darkColorList.push(key.hex);
        }
      });
      data.categories.map((item, index) => {
        newArray.push(capitalizeFirst(item.category_text));
        colorObject[item.category_text] = darkColorList[index];
      });
      setColorList(colorObject);
      setcategoriesList(newArray);
      setSearchCategories(newArray);
    },
  });

  // eslint-disable-next-line
  const {
    data: tags,
    loading: loadingTags,
    refetch: loadingTagsList,
  } = useQuery(GET_TAGS, {
    variables: {
      id: parseInt(roleID),
    },
    onCompleted: (data) => {
      let newArray = [];
      // eslint-disable-next-line
      data.tags.map((item) => {
        newArray.push(capitalizeFirst(item.tag_text));
      });
      setTagList(newArray);
      setSearchTags(newArray);
    },
  });

  useEffect(() => {
    loadingCompany();
    loadingcategoriesList();
    loadingTagsList();
    refetchUserPlan();
    setDateChanged(
      new URLSearchParams(search).get("dateRange") === null ? false : true
    );
    setSearchEnabled(
      new URLSearchParams(search).get("searchText") === null ? false : true
    );
    const today = new Date();
    const cur_year = today.getFullYear();
    let startdate =
      new URLSearchParams(search).get("currency") === "inr"
        ? today.getMonth() + 1 <= 3
          ? moment((cur_year - 1).toString())
              .month("April")
              .startOf("month")
          : moment(cur_year.toString()).month("April").startOf("month")
        : today.getMonth() + 1 <= 9
        ? moment((cur_year - 1).toString())
            .month("October")
            .startOf("month")
        : moment(cur_year.toString()).month("October").startOf("month");

    let enddate =
      new URLSearchParams(search).get("currency") === "inr"
        ? today.getMonth() + 1 <= 3
          ? moment(cur_year.toString()).month("March").endOf("month")
          : moment((cur_year + 1).toString())
              .month("March")
              .endOf("month")
        : today.getMonth() + 1 <= 9
        ? moment(cur_year.toString()).month("September").endOf("month")
        : moment((cur_year + 1).toString())
            .month("September")
            .endOf("month");

    setInitialDate(
      new URLSearchParams(search).get("searchText") === null
        ? new URLSearchParams(search).get("dateRange") === null
          ? [
              moment(moment().subtract(30, "day"), dateFormat),
              moment(moment(), dateFormat),
            ]
          : new URLSearchParams(search).get("dateRange") === "yearly"
          ? [startdate, enddate]
          : new URLSearchParams(search).get("dateRange") === "quarterly"
          ? [moment().subtract(3, "months"), moment()]
          : [moment().subtract(30, "day"), moment()]
        : []
    );
  }, [location, company_details]);

  useEffect(() => {
    getReports({
      variables: {
        where: searchTerm,
      },
    });
  }, [searchTerm]);

  const handleDownload = (name) => {
    const fileDetails = name.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];
    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.getviewurl.url;
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attachment");
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    if (searchTags && searchCategories && initialDate) {
      if (
        window.location.href.includes("?") &&
        new URLSearchParams(search).get("searchText") !== null &&
        new URLSearchParams(search).get("category") === "null"
      ) {
        setSearchTerm({
          _and: [
            {
              _or: [
                {
                  categories_x_expenses: {
                    category: {
                      category_text: {
                        _ilike: `%${new URLSearchParams(search).get(
                          "searchText"
                        )}%`,
                      },
                    },
                  },
                },
                {
                  expense_tags: {
                    tag: {
                      tag_text: {
                        _ilike: `%${new URLSearchParams(search).get(
                          "searchText"
                        )}%`,
                      },
                    },
                  },
                },
                {
                  expense_text: {
                    _ilike: `%${new URLSearchParams(search).get(
                      "searchText"
                    )}%`,
                  },
                },
              ],
            },
            {
              categories_x_expenses: {
                category: { category_text: { _in: searchCategories } },
              },
            },
            {
              expense_tags: {
                _and: { tag: { tag_text: { _in: searchTags } } },
              },
            },
            initialDate.length !== 0
              ? { date: { _gte: initialDate[0], _lte: initialDate[1] } }
              : { date: {} },
          ],
        });
        // setSearchTerm({
        //   _or: [
        //     {
        //       categories_x_expenses: {
        //         category: {
        //           category_text: {
        //             _ilike: `%${new URLSearchParams(search).get(
        //               "searchText"
        //             )}%`,
        //           },
        //         },
        //       },
        //     },
        //     {
        //       expense_tags: {
        //         tag: {
        //           tag_text: {
        //             _ilike: `%${new URLSearchParams(search).get(
        //               "searchText"
        //             )}%`,
        //           },
        //         },
        //       },
        //     },
        //     {
        //       expense_text: {
        //         _ilike: `%${new URLSearchParams(search).get("searchText")}%`,
        //       },
        //     },
        //   ],
        // });
      } else if (
        window.location.href.includes("?") &&
        new URLSearchParams(search).get("searchText") !== null &&
        new URLSearchParams(search).get("category") !== null
      ) {
        new URLSearchParams(search).get("category") === "Expense"
          ? setSearchTerm({
              _and: [
                {
                  expense_text: {
                    _ilike: `%${new URLSearchParams(search).get(
                      "searchText"
                    )}%`,
                  },
                },
                {
                  categories_x_expenses: {
                    category: { category_text: { _in: searchCategories } },
                  },
                },
                {
                  expense_tags: {
                    _and: { tag: { tag_text: { _in: searchTags } } },
                  },
                },
                initialDate.length !== 0
                  ? { date: { _gte: initialDate[0], _lte: initialDate[1] } }
                  : { date: {} },
              ],
            })
          : // setSearchTerm({
          //     expense_text: {
          //       _ilike: `%${new URLSearchParams(search).get("searchText")}%`,
          //     },
          //   })
          new URLSearchParams(search).get("category") === "Category"
          ? setSearchTerm({
              _and: [
                {
                  categories_x_expenses: {
                    category: {
                      category_text: {
                        _ilike: `%${new URLSearchParams(search).get(
                          "searchText"
                        )}%`,
                      },
                    },
                  },
                },
                {
                  categories_x_expenses: {
                    category: { category_text: { _in: searchCategories } },
                  },
                },
                {
                  expense_tags: {
                    _and: { tag: { tag_text: { _in: searchTags } } },
                  },
                },
                initialDate.length !== 0
                  ? { date: { _gte: initialDate[0], _lte: initialDate[1] } }
                  : { date: {} },
              ],
            })
          : // setSearchTerm({
            //     categories_x_expenses: {
            //       category: {
            //         category_text: {
            //           _ilike: `%${new URLSearchParams(search).get(
            //             "searchText"
            //           )}%`,
            //         },
            //       },
            //     },
            //   })
            setSearchTerm({
              _and: [
                {
                  expense_tags: {
                    _and: {
                      tag: {
                        tag_text: {
                          _ilike: `%${new URLSearchParams(search).get(
                            "searchText"
                          )}%`,
                        },
                      },
                    },
                  },
                },
                {
                  categories_x_expenses: {
                    category: { category_text: { _in: searchCategories } },
                  },
                },
                {
                  expense_tags: {
                    _and: { tag: { tag_text: { _in: searchTags } } },
                  },
                },
                initialDate.length !== 0
                  ? { date: { _gte: initialDate[0], _lte: initialDate[1] } }
                  : { date: {} },
              ],
            });

        // setSearchTerm({
        //     expense_tags: {
        //       _and: {
        //         tag: {
        //           tag_text: {
        //             _ilike: `%${new URLSearchParams(search).get(
        //               "searchText"
        //             )}%`,
        //           },
        //         },
        //       },
        //     },
        //   });
      } else {
        setSearchTerm({
          expense_tags: { _and: { tag: { tag_text: { _in: searchTags } } } },
          categories_x_expenses: {
            category: { category_text: { _in: searchCategories } },
          },
          date: { _gte: initialDate[0], _lte: initialDate[1] },
        });
      }
    }
  }, [searchCategories, searchTags, initialDate]);

  useEffect(() => {
    if (reports && company_details && colorList) {
      let object = JSON.parse(JSON.stringify(reports.expense));

      object.map((item) => {
        let newarray = [];
        item.datetable = moment(item.date).format("DD MMM YYYY");
        item.amountTable =
          item.amount > 0
            ? `${
                company_details.company[0].base_currency === "inr"
                  ? `Rs.${item.amount.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })}`
                  : `$${item.amount.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}`
              } `
            : `${
                company_details.company[0].base_currency === "inr"
                  ? `Rs.(${Math.abs(item.amount).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })})`
                  : `$(${Math.abs(item.amount).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })})`
              } `;
        item.category = item.categories_x_expenses[0].category.category_text;
        item.colorList = colorList;
        item.expense_tags.map((ele) => {
          return newarray.push(ele.tag.tag_text);
        });
        item.tags = newarray;
        item.transaction_id = item.transaction_id;
        item.addInfo = newarray.toString();
        item.downloadURL = item.upload.url;
      });
      setData(object);
    }
  }, [reports, company_details, colorList]);

  const handleselectedValues = (value, type) => {
    if (type === "category") {
      setInitialCat(value);
      value.length !== 0
        ? setSearchCategories(value)
        : setSearchCategories(categoriesList);
    } else if (type === "tag") {
      setInitialTag(value);
      value.length !== 0 ? setSearchTags(value) : setSearchTags(tagList);
    }
  };

  const handleDate = (value) => {
    setInitialDate(value);
  };

  const columns = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "datetable",
        key: "datetable",
      },
      {
        title: "Expense",
        dataIndex: "expense_text",
        key: "expense_text",
      },
      {
        title: "Amount",
        dataIndex: "amountTable",
        key: "amountTable",
        className: "w-44",
        render: (text, record) => {
          return (
            <>
              {record.amount > 0 ? (
                <span>{text}</span>
              ) : (
                <span className="text-brightRed">{text}</span>
              )}
            </>
          );
        },
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: (text, record) => {
          let colorname;
          if (record.colorList) {
            colorname = record.colorList;
          }
          return (
            <>
              {colorname && (
                <Tag className="capitalize text-xs" color={colorname[text]}>
                  {text.length > 20 ? (
                    <div className="flex flex-col">
                      <span>
                        {capitalizeFirst(text.slice(0, text.length / 2))}
                      </span>
                      <span>{text.slice(text.length / 2, text.length)}</span>
                    </div>
                  ) : (
                    <span>{capitalizeFirst(text)}</span>
                  )}
                </Tag>
              )}
            </>
          );
        },
      },
      {
        title: "Additional Info (Tags)",
        dataIndex: "tags",
        key: "tags",
        className: "w-64",
        render: (text, record, index) => {
          return (
            record.tags.length !== 0 && (
              <>
                <span>{`${
                  record.tags[0].length > 15
                    ? `${record.tags[0].slice(0, 15)}...`
                    : record.tags[0]
                } `}</span>
                <span className="text-indigo">
                  {record.tags.length > 1 && `,  +${record.tags.length - 1}`}
                </span>
              </>
              // <SelectCount>
              //   <ReactSelect
              //     className="w-full max-w-[200px] text-xs"
              //     mode="tags"
              //     placeholder=""
              //     maxTagPlaceholder={(values) => (
              //       <span className="text-indigo text-xs pl-3 ">{`+${values.length}`}</span>
              //     )}
              //     maxTagCount="responsive"
              //     value={record.tags}
              //     dropdownMatchSelectWidth={false}
              //     dropdownStyle={{ width: "125px" }}
              //     tagRender={(props) => {
              //       return (
              //         <div className="text-xs pl-1">{`${props.label} ${
              //           record.tags.length > 1
              //             ? record.tags[record.tags.length - 1] !== props.label
              //               ? ", "
              //               : ""
              //             : ""
              //         } `}</div>
              //       );
              //     }}
              //   />
              // </SelectCount>
            )
          );
        },
      },
    ],
    []
  );

  return (
    <Spin
      spinning={
        reportsLoading || companyLoading || loadingcategories || loadingTags
      }
    >
      {(!!data.length || !!categoriesList.length || !!tagList.length) && (
        <div className="flex p-8 w-full justify-between  ">
          <div className="flex gap-5">
            <DatePicker
              handleDate={handleDate}
              value={initialDate}
              setDateChanged={setDateChanged}
              separator=""
              pickerName="reports"
              base_currency={
                company_details && company_details.company.length !== 0
                  ? company_details.company[0].base_currency
                  : ""
              }
            />
            <Select
              placeholder="Category"
              type="category"
              data={categoriesList}
              onChange={handleselectedValues}
              value={initialCat}
            />
            <Select
              placeholder="Tag"
              type="tag"
              data={tagList}
              onChange={handleselectedValues}
              value={initialTag}
            />
            <Button
              size="large"
              className={` border-metal text-metal ${
                initialCat.length !== 0 ||
                initialTag.length !== 0 ||
                dateChanged ||
                searchEnabled
                  ? ""
                  : "hidden"
              }`}
              onClick={() => {
                setSearchTags(tagList);
                setSearchCategories(categoriesList);
                setInitialCat([]);
                setInitialTag([]);
                setInitialDate([moment().subtract(30, "day"), moment()]);
                setDateChanged(false);
                setSearchEnabled(false);
                // localStorage.removeItem("searchValue");
                navigate("/reports", { replace: true });
              }}
            >
              Clear Filters
            </Button>
          </div>
          <div>
            <Button
              size="large"
              type="primary"
              className="uppercase ml-4"
              // onClick={handleDownloadExcel}
              onClick={() => {
                downloadCSVFile(
                  !!data.length ? data : [],
                  columns,
                  `${company_details.company[0].name}-${todayDate}`
                );
              }}
              disabled={
                planDetails?.users_x_plans[0]?.entry_count >=
                planDetails?.users_x_plans[0]?.allowed_count
                  ? true
                  : false
              }
            >
              Export
            </Button>
          </div>
        </div>
      )}

      {!!!data.length && !!!categoriesList.length && !!!tagList.length && (
        <div className=" mt-20">
          <div className="shadow-1 p-8 max-w-sm mx-auto rounded-md ">
            <div className="text-center mb-8">
              <div className=" text-gray-900 text-lg-l6-medium">
                View Your Reports
              </div>
              <div className=" mt-2 text-gray-500 text-sm-l5 w-8/12 mx-auto ">
                You can view your data here once you upload it
              </div>
            </div>
            <img
              src={EmptyState}
              alt="empty state"
              className=" max-w-sm mx-auto"
            />
            <div className=" mt-8">
              <Button
                size="large"
                type="primary"
                block
                className=" uppercase"
                onClick={() => navigate("/upload")}
              >
                Upload Data
              </Button>
            </div>
          </div>
        </div>
      )}
      {!!categoriesList.length && !!tagList.length && (
        <div>
          {/* <div className=" pl-8 mb-5 text-lg-l5-semibold">Upload History</div> */}
          <div className="p-1">
            <Table
              locale={{ emptyText: "No results found" }}
              dataSource={!!data.length ? data : []}
              columns={columns}
              key="dataIndex"
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    setShowEditPopup(true);
                    setExpense(record);
                    form.setFieldsValue({
                      tags: record.addInfo,
                      transactionId: record.transaction_id,
                    });
                  }, // click row
                };
              }}
            />
          </div>
          <Modal
            title={expenseName?.expense_text || ""}
            visible={showEditPopup}
            keyboard={false}
            maskClosable={false}
            onCancel={() => {
              setShowEditPopup(false);
            }}
          >
            <Form
              name="login"
              layout="vertical"
              requiredMark={false}
              form={form}
            >
              <Form.Item
                label={<span className="textsm">Tags</span>}
                name="tags"
              >
                <Input
                  size="large"
                  type="text"
                  placeholder="Tags"
                  className="rounded-md"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label={<span className="textsm">Transaction ID</span>}
                name="transactionId"
              >
                <Input
                  size="large"
                  type="text"
                  placeholder="Transaction id"
                  className="rounded-md"
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label={<span className="textsm text-indigo">Source File</span>}
                className="mb-0"
              >
                <p className="text-lg font-normal">
                  {expenseName
                    ? (expenseName?.upload?.file_name).replaceAll(".csv", "")
                    : ""}
                </p>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  ghost
                  size="large"
                  className="flex"
                  disabled={
                    planDetails?.users_x_plans[0]?.entry_count >=
                    planDetails?.users_x_plans[0]?.allowed_count
                      ? true
                      : false
                  }
                  onClick={() =>
                    handleDownload(expenseName ? expenseName?.downloadURL : "")
                  }
                >
                  <img
                    src={Download}
                    alt="download"
                    className={`${
                      planDetails?.users_x_plans[0]?.entry_count >=
                      planDetails?.users_x_plans[0]?.allowed_count
                        ? "opacity-50 mr-2.5"
                        : "mr-2.5"
                    }`}
                  />
                  Download File
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      )}
    </Spin>
  );
}
