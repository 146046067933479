import { Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ExpenseBarChart from "../../components/ExpenseBarChart";
import { useLazyQuery } from "@apollo/client";
import { TOP_CATEGORIES, TOP_TAGS } from "./graphql";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { isTypeSystemExtensionNode } from "graphql";

const { TabPane } = Tabs;

function TopCategories(props) {
  // eslint-disable-next-line
  const [roleID, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "X-Hasura-User-Id"
    ]
  );
  // const [activeCategory, setActiveCategory] = useState("category");
  // const [activeTabCategories, setActiveTabCategories] = useState("1");
  const [data, setData] = useState([]);

  const [
    getTopCategories,
    { data: topCategories, loading: categoriesLoading },
  ] = useLazyQuery(TOP_CATEGORIES);

  const [getTopTags, { data: topTags, loading: tagLoading }] =
    useLazyQuery(TOP_TAGS);

  const getDetails = (start, end) => {
    if (props.activeCategory === "category") {
      getTopCategories({
        variables: {
          startdate: start,
          enddate: end,
          id: parseInt(roleID),
        },
        fetchPolicy: "network-only",
      });
    } else {
      getTopTags({
        variables: {
          startdate: start,
          enddate: end,
          id: parseInt(roleID),
        },
        fetchPolicy: "network-only",
      });
    }
  };

  useEffect(() => {
    let startdate = moment().subtract(30, "day").format("YYYY-MM-DD");
    let enddate = moment().format("YYYY-MM-DD");
    getDetails(startdate, enddate);
    // eslint-disable-next-line
  }, [props.activeCategory]);

  useEffect(() => {
    let graphdata = [];
    if (topCategories && props.activeCategory === "category") {
      let object = JSON.parse(JSON.stringify(topCategories.categories));
      object.map((item) => {
        let sum = 0;
        if (item.categories_x_expenses.length !== 0) {
          item.categories_x_expenses.map((element) => {
            sum += element.expense.amount;
            item.totalAmount = sum > 0 ? sum : Math.abs(sum);
            item.value = sum;
          });
        } else {
          item.totalAmount = 0;
        }
      });
      object.map((item) => {
        if (item?.totalAmount !== 0) {
          graphdata.push({
            name: item.category_text,
            expense: item.totalAmount,
            total: item.value,
          });
        }
      });
      const numDescending = [...graphdata].sort(
        (a, b) => b.expense - a.expense
      );
      let firstTenExpense = numDescending.slice(0, 10);
      setData(firstTenExpense);
    } else if (topTags && props.activeCategory === "tag") {
      let object = JSON.parse(JSON.stringify(topTags.tags));
      object.map((item) => {
        let sum = 0;
        if (item.expense_tags.length !== 0) {
          item.expense_tags.map((element) => {
            sum += element.expense.amount;
            item.totalAmount = sum > 0 ? sum : Math.abs(sum);
            item.value = sum;
          });
        } else {
          item.totalAmount = 0;
        }
      });
      object.map((item) => {
        if (item?.totalAmount !== 0) {
          graphdata.push({
            name: item.tag_text,
            expense: item.totalAmount,
            total: item.value,
          });
        }
      });
      let numDescending = [...graphdata].sort((a, b) => b.expense - a.expense);

      let firstTenExpense = numDescending.slice(0, 10);
      setData(firstTenExpense);
    }
  }, [topCategories, topTags, props.activeCategory]);

  useEffect(() => {
    const today = new Date();
    const cur_year = today.getFullYear();
    if (parseInt(props.activeTabCategories) === 2) {
      let startdate = moment().subtract(3, "months").format("YYYY-MM-DD");
      let enddate = moment().format("YYYY-MM-DD");
      getDetails(startdate, enddate);
    } else if (parseInt(props.activeTabCategories) === 3) {
      let startdate =
        props.base_currency === "inr"
          ? today.getMonth() + 1 <= 3
            ? moment((cur_year - 1).toString())
                .month("April")
                .startOf("month")
                .format("YYYY-MM-DD")
            : moment(cur_year.toString())
                .month("April")
                .startOf("month")
                .format("YYYY-MM-DD")
          : today.getMonth() + 1 <= 9
          ? moment((cur_year - 1).toString())
              .month("October")
              .startOf("month")
              .format("YYYY-MM-DD")
          : moment(cur_year.toString())
              .month("October")
              .startOf("month")
              .format("YYYY-MM-DD");

      let enddate =
        props.base_currency === "inr"
          ? today.getMonth() + 1 <= 3
            ? moment(cur_year.toString())
                .month("March")
                .endOf("month")
                .format("YYYY-MM-DD")
            : moment((cur_year + 1).toString())
                .month("March")
                .endOf("month")
                .format("YYYY-MM-DD")
          : today.getMonth() + 1 <= 9
          ? moment(cur_year.toString())
              .month("September")
              .endOf("month")
              .format("YYYY-MM-DD")
          : moment((cur_year + 1).toString())
              .month("September")
              .endOf("month")
              .format("YYYY-MM-DD");

      getDetails(startdate, enddate);
    } else {
      let startdate = moment().subtract(30, "day").format("YYYY-MM-DD");
      let enddate = moment().format("YYYY-MM-DD");
      getDetails(startdate, enddate);
    }
  }, [props.activeTabCategories]);

  const onChange = (key) => {
    props.setActiveTabCategories(key);
  };

  return (
    <div className="mt-2.5 shadow-lg px-5 py-10">
      <Tabs
        defaultActiveKey="1"
        onChange={onChange}
        activeKey={props.activeTabCategories}
        tabBarExtraContent={{
          left: (
            <>
              <span
                className={`font-medium text-lg cursor-pointer ${
                  props.activeCategory === "category" ? "text-indigo" : ""
                }`}
                onClick={() => {
                  props.setActiveTabCategories("1");
                  props.setActiveCategory("category");
                }}
              >
                Top Categories
              </span>
              <span
                className={`ml-4 font-medium text-lg cursor-pointer ${
                  props.activeCategory === "tag" ? "text-indigo" : ""
                }`}
                onClick={() => {
                  props.setActiveTabCategories("1");
                  props.setActiveCategory("tag");
                }}
              >
                Top Tags
              </span>
            </>
          ),
        }}
      >
        <TabPane
          key="1"
          tab={<span className="textHeadings">Last 30 days</span>}
        >
          <Spin spinning={categoriesLoading || tagLoading}>
            <ExpenseBarChart data={data} base_currency={props.base_currency} />
          </Spin>
        </TabPane>
        <TabPane
          tab={<span className="textHeadings">Last 90 days</span>}
          key="2"
        >
          <Spin spinning={categoriesLoading || tagLoading}>
            <ExpenseBarChart data={data} base_currency={props.base_currency} />
          </Spin>
        </TabPane>
        <TabPane
          tab={<span className="textHeadings">Financial year</span>}
          key="3"
        >
          <Spin spinning={categoriesLoading || tagLoading}>
            <ExpenseBarChart data={data} base_currency={props.base_currency} />
          </Spin>
        </TabPane>
      </Tabs>
      <div className="float-right">
        <Link
          to={`/reports?dateRange=${
            props.activeTabCategories === "1"
              ? "monthly"
              : props.activeTabCategories === "2"
              ? "quarterly"
              : "yearly"
          }&currency=${props.base_currency}`}
          className={`hideviewall text-sm font-normal py-5 ${
            data.length === 0 ? "pointer-events-none text-gray-500" : ""
          }`}
        >
          View all
        </Link>
      </div>
    </div>
  );
}

export default TopCategories;
