import { gql } from "@apollo/client";

export const GET_AVATAR = gql`
  query GET_AVATAR($id: Int) {
    users(where: { id: { _eq: $id } }) {
      avatar
    }
  }
`;

export const GET_DOWNLOAD_URL = gql`
  mutation GET_VIEW_URL($name: String!) {
    getviewurl(file_name: $name) {
      status
      url
    }
  }
`;

export const COMPANY_DETAILS = gql`
  query COMPANY_DETAILS($id: Int) {
    company(where: { users: { id: { _eq: $id } } }) {
      id
      name
      base_currency
    }
  }
`;

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription NOTIFICATION_SUBSCRIPTION {
    notifications_aggregate(where: { is_active: { _eq: true } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  subscription NOTIFICATIONS {
    notifications(
      order_by: { updated_at: desc }
      limit: 3
      where: { is_active: { _eq: true } }
    ) {
      text
      is_active
      created_at
      user_id
    }
  }
`;

export const UPDATE_READ = gql`
  mutation UPDATE_READ($user_id: Int) {
    update_notifications(
      where: { user_id: { _eq: $user_id } }
      _set: { is_active: false }
    ) {
      affected_rows
    }
  }
`;

export const GET_USER_PLAN = gql`
  query GET_USER_PLAN($id: Int) {
    users_x_plans(where: { user_id: { _eq: $id } }) {
      entry_count
      allowed_count
    }
  }
`;
