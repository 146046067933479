import React, { useState } from "react";
import "./index.css";
import Routes from "./routes";
import EmailContext from "./context/EmailContext";
import AppLogout from "./AppLogout";

function App() {
  const [email, setEmail] = useState("");
  return (
    <AppLogout>
      <EmailContext.Provider value={{ email, setEmail }}>
        <Routes />
      </EmailContext.Provider>
    </AppLogout>
  );
}

export default App;
