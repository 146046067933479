import React from "react";

export default function EmbedSocialWidget(props) {
  React.useEffect(() => {
    (function (d, s, id) {
      let js;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://embedsocial.com/cdn/ef.js";
      d.getElementsByTagName("head")[0].appendChild(js);
    })(document, "script", "EmbedSocialFormsScript");
  });

  return (
    <div
      style={{ width: 0 }}
      class="embedsocial-forms-iframe"
      data-ref={props.refid}
      data-widget="true"
      data-height="auto"
    ></div>
  );
}
