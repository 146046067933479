import { Link } from "react-router-dom";
import styled from "styled-components";
import { useState, useMemo, useEffect } from "react";
import { Button, Select as ReactSelect, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import recentTransaction from "../../assets/recentTransaction.svg";
import Table from "../../components/Table";
import Tag from "../../components/Tag";
import { LAST_5_TRANSACTION, COMPANY_DETAILS } from "./graphql";
import { numberWithCommas } from "../../utils";
import namedColors from "color-name-list";
import lightOrDarkColor from "@check-light-or-dark/color";
import { useQuery } from "@apollo/client";
import moment from "moment";
import jwtDecode from "jwt-decode";

const SelectCount = styled.div`
  .ant-select-selector {
    background: transparent !important ;
    border: none !important;
    & .ant-select-selection-item {
      background-color: transparent !important;
      border: none !important;
    }
  }
`;

function RecentTransactions(props) {
  const navigate = useNavigate();
  const [roleID, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "X-Hasura-User-Id"
    ]
  );
  const {
    data: lastTransaction,
    loading: lastTransactionLoading,
    refetch: recent,
  } = useQuery(LAST_5_TRANSACTION);
  const { data: company_details, loading: companyLoading } = useQuery(
    COMPANY_DETAILS,
    {
      variables: {
        id: parseInt(roleID),
      },
    }
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    recent();
  }, []);

  useEffect(() => {
    if (lastTransaction && company_details) {
      let colorObject = {};
      let darkColorList = [];
      let object = JSON.parse(JSON.stringify(lastTransaction.expense));
      let checkList = namedColors.slice(500, 1600);

      // eslint-disable-next-line
      checkList.map((key) => {
        if (lightOrDarkColor(key.hex) === "dark") {
          darkColorList.push(key.hex);
        }
      });

      object.map((item, index) => {
        colorObject[item.categories_x_expenses[0].category.category_text] =
          darkColorList[index];
      });

      object.map((item) => {
        let newarray = [];
        item.datetable = moment(item.date).format("DD MMM YYYY");
        item.amountTable =
          item.amount > 0
            ? `${
                company_details.company[0].base_currency === "inr"
                  ? `Rs.${item.amount.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })}`
                  : `$${item.amount.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}`
              } `
            : `${
                company_details.company[0].base_currency === "inr"
                  ? `Rs.(${Math.abs(item.amount).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })})`
                  : `$(${Math.abs(item.amount).toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })})`
              } `;
        item.category = item.categories_x_expenses[0].category.category_text;
        item.colorList = colorObject;
        item.expense_tags.map((ele) => {
          return newarray.push(ele.tag.tag_text);
        });
        item.tags = newarray;
      });
      setData(object);
    }
  }, [lastTransaction, company_details]);

  const columns = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "datetable",
        key: "datetable",
      },
      {
        title: "Expense",
        dataIndex: "expense_text",
        key: "expense_text",
      },
      {
        title: "Amount",
        dataIndex: "amountTable",
        key: "amountTable",
        render: (text, record) => {
          return (
            <>
              {record.amount > 0 ? (
                <span>{text}</span>
              ) : (
                <span className="text-brightRed">{text}</span>
              )}
            </>
          );
        },
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: (text, record) => {
          let colorname;
          if (record.colorList) {
            colorname = record.colorList;
          }
          return (
            <>
              {colorname && (
                <Tag className="capitalize text-xs" color={colorname[text]}>
                  {text}
                </Tag>
              )}
            </>
          );
        },
      },
      {
        title: "Additional Info (Tags)",
        dataIndex: "tags",
        key: "tags",
        className: "w-96",
        render: (text, record, index) => {
          return (
            record.tags.length !== 0 && (
              <SelectCount>
                <ReactSelect
                  className="w-full text-xs"
                  mode="tags"
                  placeholder=""
                  maxTagPlaceholder={(values) => (
                    <span className="text-indigo text-xs pl-3 ">{`+${values.length}`}</span>
                  )}
                  maxTagCount="responsive"
                  value={record.tags}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: "125px" }}
                  tagRender={(props) => {
                    return (
                      <div className=" text-xs pl-1">{`${props.label} ${
                        record.tags.length > 1
                          ? record.tags[record.tags.length - 1] !== props.label
                            ? ", "
                            : ""
                          : ""
                      } `}</div>
                    );
                  }}
                />
              </SelectCount>
            )
          );
        },
      },
    ],
    []
  );
  //   () => [
  //     {
  //       title: "Date",
  //       dataIndex: "datetable",
  //       key: "datetable",
  //       // className: "w-24",
  //     },
  //     {
  //       title: "Expense",
  //       dataIndex: "expense_text",
  //       key: "expense_text",
  //       // className: "w-32",
  //     },
  //     {
  //       title: "Amount",
  //       dataIndex: "amountTable",
  //       key: "amountTable",
  //       // className: "w-28",
  //     },
  //     {
  //       title: "Category",
  //       dataIndex: "category",
  //       key: "category",
  //       // className: "w-32",
  //       render: (text, record) => {
  //         let colorname;
  //         if (record.colorList) {
  //           colorname = record.colorList;
  //         }
  //         return (
  //           <>
  //             {colorname && (
  //               <Tag className="capitalize text-xs" color={colorname[text]}>
  //                 {text}
  //               </Tag>
  //             )}
  //           </>
  //         );
  //       },
  //     },
  //     {
  //       title: "AddTL. INfo",
  //       dataIndex: "tags",
  //       key: "tags",
  //       // className: "w-72",
  //       render: (text, record, index) => {
  //         return (
  //           record.tags.length !== 0 && (
  //             <SelectCount>
  //               <ReactSelect
  //                 className="w-full max-w-[200px] text-xs"
  //                 mode="tags"
  //                 placeholder=""
  //                 maxTagPlaceholder={(values) => (
  //                   <span className="text-indigo text-xs pl-3 ">{`+${values.length}`}</span>
  //                 )}
  //                 maxTagCount="responsive"
  //                 value={record.tags}
  //                 dropdownMatchSelectWidth={false}
  //                 dropdownStyle={{ width: "125px" }}
  //                 tagRender={(props) => {
  //                   return (
  //                     <div className="text-xs pl-1">{`${props.label} ${
  //                       record.tags.length > 1
  //                         ? record.tags[record.tags.length - 1] !== props.label
  //                           ? ", "
  //                           : ""
  //                         : ""
  //                     } `}</div>
  //                   );
  //                 }}
  //               />
  //             </SelectCount>
  //           )
  //         );
  //       },
  //     },
  //   ],
  //   []
  // );

  return (
    <div
      id="recentTransaction"
      className="min-h-46 shadow-lg px-3 py-10 w-full"
    >
      <>
        <p className="ml-4 mt-4 font-medium text-base text-indigo">
          Recent Transactions
        </p>
        {data.length !== 0 ? (
          <Spin spinning={lastTransactionLoading || companyLoading}>
            <Table
              dataSource={!!data.length ? data : []}
              columns={columns}
              key="recentTransaction"
              pagination={false}
              className="w-full"
              style={{ overflow: "auto" }}
            />
          </Spin>
        ) : (
          <div className="flex flex-col justify-center items-center min-h-32">
            <img src={recentTransaction} alt="recent" />
            <p className="text-lg-l5-semibold text-gray-500 mt-4">
              There is no data added yet
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="hidebutton font-bold"
              onClick={() => navigate("/upload")}
            >
              UPLOAD DATA
            </Button>
          </div>
        )}
      </>
      <div className="float-right mt-2.5">
        <Link
          to="/reports"
          className={`hideviewall text-sm font-normal ${
            data.length === 0 ? "pointer-events-none text-gray-500" : ""
          }`}
        >
          View all
        </Link>
      </div>
    </div>
  );
}

export default RecentTransactions;
