import { Button, DatePicker as ReactDatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import NextIcon from "../../assets/icons/dateArrowNext.svg";
import PrevIcon from "../../assets/icons/dateArrowPrev.svg";
import SelectSuffix from "../../assets/icons/select.svg";
const { RangePicker } = ReactDatePicker;

const PickerStyle = styled.div`
  .ant-picker {
    border-color: #4f46e5;
    cursor: pointer;
  }
  &.disableEndDate {
    .ant-picker-input:nth-child(3) {
      display: none;
    }
    .ant-picker-range-separator {
      diaplay: none;
    }
  }
`;
const GlobalStyle = createGlobalStyle`

.ant-picker-header-prev-btn:nth-child(1){
  visibility: hidden !important;
}
.ant-picker-header-next-btn:nth-child(1){
  visibility: hidden !important;
}
.ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn,.ant-picker-range-arrow{
  display: none !important;
}
.ant-picker-header{
  border-bottom: 0;
}
.ant-picker-panel-container .ant-picker-panel-focused,.ant-picker-panel{
  border:0;
}
.ant-picker-date-panel:nth-child(1){
  border-right:1px solid #E5E3FB;
}
.ant-picker-panel-container{
  box-shadow: none;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
  background:#E5E3FB
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner,.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner,.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border-radius: 0;
}
.ant-picker-footer{
  min-width: initial;
  width:170px;
  margin-top: -28px;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  background: initial;
  border: initial;
}

`;

export default function DatePicker({
  handleDate,
  value,
  setDateChanged,
  base_currency,
  placeholder,
  separator,
  pickerName,
  ...rest
}) {
  const dateFormat = "DD-MMM-YYYY";
  const [dateValue, setDateValue] = React.useState([]);
  const [openpicker, setOpenPicker] = useState(false);
  const [enableRange, setEnableRange] = useState("Last 30 Days");
  const today = new Date();
  const cur_year = today.getFullYear();

  useEffect(() => {
    if (value && value.length !== 0 && value[0] && value[1]) {
      setDateValue(value);
      if (
        enableRange === "Last 30 Days" &&
        value[1].diff(value[0], "days") === 30
      ) {
        setEnableRange("Last 30 Days");
      } else if (
        enableRange === "Current financial year" &&
        (value[1].diff(value[0], "days") === 364 ||
          value[1].diff(value[0], "days") === 365)
      ) {
        setEnableRange("Current financial year");
      } else if (value[1].diff(value[0], "days") === 92) {
        setEnableRange("");
      }
    } else {
      setDateValue(value);
      setEnableRange("");
    }
  }, [value]);

  // useEffect(() => {
  //   window.addEventListener("click", function (e) {
  //     if (document.getElementById("clickbox").contains(e.target)) {
  //       console.log("in");
  //       setOpenPicker(!openpicker);
  //       // Clicked in box
  //     } else if (document.getElementById("panel").contains(e.target)) {
  //       if (
  //         document.getElementById("update").contains(e.target) ||
  //         document.getElementById("clear").contains(e.target)
  //       ) {
  //         setOpenPicker(false);
  //       } else {
  //         setOpenPicker(true);
  //       }
  //     } else {
  //       setOpenPicker(false);
  //       console.log("out");
  //       // Clicked outside the box
  //     }
  //   });
  // }, []);

  return (
    <>
      {openpicker && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 z-10"
          onClick={() => setOpenPicker(false)}
        ></div>
      )}
      <PickerStyle
        className={
          (dateValue || [])?.length === 0 ? "disableEndDate" : "Enabledate"
        }
      >
        <GlobalStyle />
        <RangePicker
          size="large"
          dropdownClassName="rangePicker"
          onCalendarChange={(dates) => {
            setDateChanged(true);
            setEnableRange("");
            setDateValue(dates);
            // handleDate(dates);
          }}
          onChange={() => setOpenPicker(true)}
          onOpenChange={() => setOpenPicker(true)}
          placeholder={
            placeholder ? [placeholder, ""] : ["Start date", "End date"]
          }
          allowClear={false}
          separator={separator}
          value={dateValue}
          format={dateFormat}
          open={openpicker}
          suffixIcon={<img src={SelectSuffix} alt="suffix select" />}
          nextIcon={<img src={NextIcon} alt="next icon" />}
          prevIcon={<img src={PrevIcon} alt="prev icon" className="ml-3" />}
          panelRender={(node) => {
            return (
              <div className=" p-5 border shadow-1 border-indigo rounded-md">
                <div className="text-sm-l5-semibold mb-5">
                  Select a date range
                </div>
                <div className="flex ">
                  <div>
                    <div className="flex text-xs gap-3 max-w-full ">
                      <div className="w-1/2 pb-3 border-0 border-r border-solid flex justify-between ">
                        <div>Start date</div>
                        <div className="pr-3">
                          {dateValue?.[0]
                            ? moment(dateValue?.[0]).format("MM/DD/YYYY")
                            : ""}
                        </div>
                      </div>
                      <div className="w-1/2 pb-3 border-0 border-r border-solid flex justify-between ">
                        <div>End date</div>
                        <div className="pr-3">
                          {dateValue?.[1]
                            ? moment(dateValue?.[1]).format("MM/DD/YYYY")
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className=" -ml-3 flex">{node}</div>
                  </div>
                  <div className="p-3 text-sm-l5 w-48">
                    {pickerName !== "expiryDate" && (
                      <>
                        {" "}
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange === "Last 7 Days"
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange("Last 7 Days");
                            setDateValue([
                              moment().subtract(7, "day"),
                              moment(),
                            ]);
                          }}
                        >
                          Last 7 Days
                        </div>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange === "Last 30 Days"
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange("Last 30 Days");
                            setDateValue([
                              moment().subtract(30, "day"),
                              moment(),
                            ]);
                          }}
                        >
                          Last 30 Days
                        </div>
                      </>
                    )}
                    {pickerName === "reports" && (
                      <>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange === "Current month"
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange("Current month");
                            setDateValue([
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ]);
                          }}
                        >
                          Current month
                        </div>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange === "Previous month"
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange("Previous month");
                            setDateValue([
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ]);
                          }}
                        >
                          Previous month
                        </div>

                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange === "Current financial year"
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange("Current financial year");
                            let startdate =
                              base_currency === "inr"
                                ? today.getMonth() + 1 <= 3
                                  ? moment((cur_year - 1).toString())
                                      .month("April")
                                      .startOf("month")
                                  : moment(cur_year.toString())
                                      .month("April")
                                      .startOf("month")
                                : today.getMonth() + 1 <= 9
                                ? moment((cur_year - 1).toString())
                                    .month("October")
                                    .startOf("month")
                                : moment(cur_year.toString())
                                    .month("October")
                                    .startOf("month");
                            let enddate =
                              base_currency === "inr"
                                ? today.getMonth() + 1 <= 3
                                  ? moment(cur_year.toString())
                                      .month("March")
                                      .endOf("month")
                                  : moment((cur_year + 1).toString())
                                      .month("March")
                                      .endOf("month")
                                : today.getMonth() + 1 <= 9
                                ? moment(cur_year.toString())
                                    .month("September")
                                    .endOf("month")
                                : moment((cur_year + 1).toString())
                                    .month("September")
                                    .endOf("month");
                            setDateValue([startdate, enddate]);
                          }}
                        >
                          Current financial year
                        </div>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange === "Previous financial year"
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange("Previous financial year");
                            let startdate =
                              base_currency === "inr"
                                ? today.getMonth() + 1 <= 3
                                  ? moment((cur_year - 2).toString())
                                      .month("April")
                                      .startOf("month")
                                  : moment((cur_year - 1).toString())
                                      .month("April")
                                      .startOf("month")
                                : today.getMonth() + 1 <= 9
                                ? moment((cur_year - 2).toString())
                                    .month("October")
                                    .startOf("month")
                                : moment((cur_year - 1).toString())
                                    .month("October")
                                    .startOf("month");
                            let enddate =
                              base_currency === "inr"
                                ? today.getMonth() + 1 <= 3
                                  ? moment((cur_year - 1).toString())
                                      .month("March")
                                      .endOf("month")
                                  : moment(cur_year.toString())
                                      .month("March")
                                      .endOf("month")
                                : today.getMonth() + 1 <= 9
                                ? moment((cur_year - 1).toString())
                                    .month("September")
                                    .endOf("month")
                                : moment(cur_year.toString())
                                    .month("September")
                                    .endOf("month");
                            setDateValue([startdate, enddate]);
                          }}
                        >
                          Previous financial year
                        </div>
                      </>
                    )}
                    {pickerName === "dateJoined" && (
                      <>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange ===
                            moment().startOf("month").format("MMMM YYYY")
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange(
                              moment().startOf("month").format("MMMM YYYY")
                            );
                            setDateValue([
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ]);
                          }}
                        >
                          {moment().startOf("month").format("MMMM YYYY")}
                        </div>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange ===
                            moment()
                              .subtract(1, "month")
                              .startOf("month")
                              .format("MMMM YYYY")
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange(
                              moment().subtract(1, "month").format("MMMM YYYY")
                            );
                            setDateValue([
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ]);
                          }}
                        >
                          {moment()
                            .subtract(1, "month")
                            .startOf("month")
                            .format("MMMM YYYY")}
                        </div>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange ===
                            moment()
                              .subtract(2, "month")
                              .startOf("month")
                              .format("MMMM YYYY")
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange(
                              moment()
                                .subtract(2, "month")
                                .startOf("month")
                                .format("MMMM YYYY")
                            );
                            setDateValue([
                              moment().subtract(2, "month").startOf("month"),
                              moment().subtract(2, "month").endOf("month"),
                            ]);
                          }}
                        >
                          {moment()
                            .subtract(2, "month")
                            .startOf("month")
                            .format("MMMM YYYY")}
                        </div>
                      </>
                    )}
                    {pickerName === "expiryDate" && (
                      <>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange ===
                            moment().startOf("month").format("MMMM YYYY")
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange(
                              moment().startOf("month").format("MMMM YYYY")
                            );
                            setDateValue([
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ]);
                          }}
                        >
                          {moment().startOf("month").format("MMMM YYYY")}
                        </div>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange ===
                            moment()
                              .add(1, "month")
                              .startOf("month")
                              .format("MMMM YYYY")
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange(
                              moment().add(1, "month").format("MMMM YYYY")
                            );
                            setDateValue([
                              moment().add(1, "month").startOf("month"),
                              moment().add(1, "month").endOf("month"),
                            ]);
                          }}
                        >
                          {moment()
                            .add(1, "month")
                            .startOf("month")
                            .format("MMMM YYYY")}
                        </div>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange ===
                            moment()
                              .add(2, "month")
                              .startOf("month")
                              .format("MMMM YYYY")
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange(
                              moment()
                                .add(2, "month")
                                .startOf("month")
                                .format("MMMM YYYY")
                            );
                            setDateValue([
                              moment().add(2, "month").startOf("month"),
                              moment().add(2, "month").endOf("month"),
                            ]);
                          }}
                        >
                          {moment()
                            .add(2, "month")
                            .startOf("month")
                            .format("MMMM YYYY")}
                        </div>

                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange ===
                            moment()
                              .add(3, "month")
                              .startOf("month")
                              .format("MMMM YYYY")
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange(
                              moment()
                                .add(3, "month")
                                .startOf("month")
                                .format("MMMM YYYY")
                            );
                            setDateValue([
                              moment().add(3, "month").startOf("month"),
                              moment().add(3, "month").endOf("month"),
                            ]);
                          }}
                        >
                          {moment()
                            .add(3, "month")
                            .startOf("month")
                            .format("MMMM YYYY")}
                        </div>
                        <div
                          className={`pb-3 cursor-pointer ${
                            enableRange ===
                            moment()
                              .add(4, "month")
                              .startOf("month")
                              .format("MMMM YYYY")
                              ? "font-bold text-indigo"
                              : ""
                          }`}
                          onClick={() => {
                            setEnableRange(
                              moment()
                                .add(4, "month")
                                .startOf("month")
                                .format("MMMM YYYY")
                            );
                            setDateValue([
                              moment().add(4, "month").startOf("month"),
                              moment().add(4, "month").endOf("month"),
                            ]);
                          }}
                        >
                          {moment()
                            .add(4, "month")
                            .startOf("month")
                            .format("MMMM YYYY")}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex justify-end mt-5 ">
                  <Button
                    size="large"
                    className="mr-5"
                    type="primary"
                    onClick={() => {
                      setOpenPicker(false);
                      handleDate(dateValue);
                      if (
                        enableRange === "Last 30 Days" &&
                        pickerName === "reports"
                      ) {
                        setDateChanged(false);
                      } else {
                        setDateChanged(true);
                      }
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    size="large"
                    onClick={() => {
                      setDateChanged(false);
                      setOpenPicker(false);

                      if (pickerName === "reports") {
                        setEnableRange("Last 30 Days");
                        setDateValue([
                          moment(moment().subtract(30, "day"), dateFormat),
                          moment(moment(), dateFormat),
                        ]);
                        handleDate([
                          moment(moment().subtract(30, "day"), dateFormat),
                          moment(moment(), dateFormat),
                        ]);
                      } else {
                        setEnableRange("");
                        setDateValue([]);
                        handleDate([]);
                      }
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            );
          }}
        />
        <div id="datePicker"></div>
      </PickerStyle>
    </>
  );
}
