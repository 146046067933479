import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { COMPANY_DETAILS } from "./uploadData/graphql";
import jwtDecode from "jwt-decode";

export default function Pages() {
  const [company_details] = useLazyQuery(COMPANY_DETAILS, {
    onCompleted: (data) => {
      if (data.company.length === 0) {
        window.location.replace("/company-details");
      } else {
        window.location.replace(
          `/dashboard?base_currency=${data.company[0].base_currency}`
        );
      }
    },
  });
  const search = useLocation().search;

  useEffect(() => {
    const token = new URLSearchParams(search).get("token");

    if (token) {
      localStorage.setItem("token", token);
      company_details({
        variables: {
          id: parseInt(
            jwtDecode(localStorage.getItem("token"))[
              "https://hasura.io/jwt/claims"
            ]["X-Hasura-User-Id"]
          ),
        },
      });
    } else {
      company_details({
        variables: {
          id: parseInt(
            jwtDecode(localStorage.getItem("token"))[
              "https://hasura.io/jwt/claims"
            ]["X-Hasura-User-Id"]
          ),
        },
      });
    }
  }, []);

  return (
    <div className="py-6 px-8">
      <p className="text-xl">Redirecting...</p>
    </div>
  );
}
