import { Tabs } from "antd";
import { useState, useMemo } from "react";
import freePlan from "../../assets/freePlan.svg";
// import annualPlan from "../../assets/annualPlan.svg";
import Download from "../../assets/icons/download.svg";
import PlanCard from "./planCard";
import SelectPlan from "./selectPlan";
import Table from "../../components/Table";

const { TabPane } = Tabs;

function Billing(props) {
  const [data, setData] = useState([
    {
      key: "1",
      date: "22/Jun/2011",
      service: "salary",
      payment: "bike,fuel",
      total: "Rs.32",
    },
  ]);

  const columns = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Service period",
        dataIndex: "service",
        key: "service",
      },
      {
        title: "Payment method",
        dataIndex: "payment",
        key: "payment",
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
      {
        title: <div className="text-center">Invoice</div>,
        dataIndex: "download",
        key: "download",
        width: "20%",
        render: () => {
          return (
            <div className="text-center">
              <img src={Download} alt="download" className=" mx-auto" />
            </div>
          );
        },
      },
    ],
    []
  );

  const onChange = (key) => {
    console.log(key);
  };
  const handleUpgrade = () => {
    props.setResetTab(!props.resetTab);
  };

  return (
    <div className="px-4 py-2">
      <Tabs
        defaultActiveKey="myPlan"
        onChange={onChange}
        size="large"
        tabBarStyle={{ width: "200px" }}
      >
        <TabPane
          key="myPlan"
          tab={<span className="textHeadings">My Plan</span>}
        >
          {!props.resetTab ? (
            <PlanCard
              planName="Free"
              rate="$ 0"
              image={freePlan}
              planList={[
                "500 entries allowed",
                "Custom filters, date ranges",
                "Categorical searches",
                "Visual insights",
                "Downloadable report",
                "CSV file exports",
              ]}
              handleUpgrade={handleUpgrade}
            />
          ) : (
            <SelectPlan handleUpgrade={handleUpgrade} />
          )}
        </TabPane>
        <TabPane
          tab={<span className="textHeadings">History</span>}
          key="history"
          disabled
        >
          {" "}
          <Table
            dataSource={data}
            columns={columns}
            key="recentTransaction"
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Billing;
