import { useMutation, useQuery } from "@apollo/client";
import { Button, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../assets/icons/closeIcon.svg";
import Download from "../../assets/icons/download.svg";
import EmptyState from "../../assets/upload/emptyState.jpg";
import UploadFailed from "../../assets/uploadFailed.svg";
import Modal from "../../components/Modal";
import AntTable from "../../components/Table";
import { getS3UploadUrl, uploadFiles } from "../../utils";
import {
  INSERT_FILES,
  GET_UPLOADS_LIST,
  GET_DOWNLOAD_URL,
  GET_USER_PLAN,
} from "./graphql";
import moment from "moment";
import jwtDecode from "jwt-decode";

export default function UploadData() {
  // eslint-disable-next-line
  const [roleID, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "X-Hasura-User-Id"
    ]
  );
  const [fileList, setFileList] = useState([]);
  const [uploadFailedModal, setUploadFailed] = useState(false);
  const navigate = useNavigate();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [file, setFile] = useState();
  const [fileURL, setFileUrl] = useState();
  const [insertfiles] = useMutation(INSERT_FILES);
  // const [enableModal, setEnableModal] = useState(false);
  const [enablePlanModal, setEnablePlanModal] = useState(false);
  // const [openModal, setOpenModal] = useState(false);
  const [errormessage, setErrormessage] = useState();
  const [entryCount, setEntryCount] = useState();
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const [data, setData] = useState([]);
  const { data: planDetails, refetch: refetchUserPlan } = useQuery(
    GET_USER_PLAN,
    {
      variables: {
        id: parseInt(roleID),
      },
    }
  );
  const { data: uploadFilesList, loading: uploadloading } = useQuery(
    GET_UPLOADS_LIST,
    {
      variables: {
        id: parseInt(roleID),
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    refetchUserPlan();
  }, []);

  useEffect(() => {
    if (uploadFilesList) {
      let object = JSON.parse(JSON.stringify(uploadFilesList.uploads));
      object.forEach((item, index) => {
        item.key = index;
        item.date = moment(item.created_at).format("DD MMM YYYY");
        item.url = item.url.split("/")[3];
      });
      setData(object);
    }
  }, [uploadFilesList]);

  useEffect(() => {
    if (fileURL && file && !uploadFailedModal && !enablePlanModal) {
      insertfiles({
        variables: {
          url: fileURL,
          filename: file.name,
          status: "declined",
          userId: parseInt(roleID),
        },
      }).then((res) => {
        let tempObject = {},
          tempData = [];
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = function (e) {
          let result = e.target.result;
          result = result.split("\r\n");
          result = result.filter((e) => e !== ",,,");
          result.shift();
          result.forEach((item) => {
            item = item.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            tempObject.date = item[0];
            tempObject.expense =
              item[1] !== undefined ? item[1].replaceAll('"', "") : "";
            tempObject.amount = item[2];
            tempObject.category = item[3];
            tempObject.addInfo =
              item[4] !== undefined ? item[4].replaceAll(", ", ",") : "";
            tempObject.transaction_id = item[5];
            tempData.push(tempObject);
            tempObject = {};
          });
          return new Promise((resolve, reject) => {
            navigate(
              `/upload/review?fileid=${res?.data?.insert_uploads?.returning[0]?.id}`,
              {
                state: { tableData: tempData },
              }
            );
          });
        };
      });
    }
    // eslint-disable-next-line
  }, [fileURL, file, uploadFailedModal, enablePlanModal]);

  // useEffect(() => {
  //   if (uploadSuccess) {
  //     setOpenModal(true);
  //   } else {
  //     setOpenModal(false);
  //   }
  // }, [uploadSuccess]);

  const handleDownload = (name) => {
    const fileDetails = name.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];
    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.getviewurl.url;
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attachment");
      document.body.appendChild(link);
      link.click();
    });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "20%",
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: <div className="text-center">Download File</div>,
      dataIndex: "url",
      key: "url",
      width: "20%",
      render: (text) => {
        return (
          <div className="text-center">
            <img
              src={Download}
              alt="download"
              className={`mx-auto ${
                planDetails?.users_x_plans[0]?.entry_count >=
                planDetails?.users_x_plans[0]?.allowed_count
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              onClick={() =>
                planDetails?.users_x_plans[0]?.entry_count >=
                planDetails?.users_x_plans[0]?.allowed_count
                  ? ""
                  : handleDownload(text)
              }
            />
          </div>
        );
      },
    },
  ];
  const uploadFile = async (data) => {
    let signedURL = await getS3UploadUrl(data.file.name, "text/csv");
    if (signedURL[1].statuscode === 200) {
      let URLs = await uploadFiles([signedURL[0]], [data.file]);
      setFile(data.file);
      setFileUrl(URLs[0]);
      setUploadSuccess(false);
    } else {
      setUploadFailed(true);
      setUploadSuccess(false);
    }
  };

  return (
    <div className="w-full">
      <div className="flex p-8 ">
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setUploadSuccess(true);
          }}
        >
          ADD CSV FILE
        </Button>

        <Button
          type="primary"
          ghost
          size="large"
          className="ml-6 flex"
          onClick={() => handleDownload("SampleCSVTemplate.csv")}
        >
          <img src={Download} alt="download" className="mr-2.5" />
          CSV Template
        </Button>
      </div>
      {!!data.length && (
        <div>
          <div className=" pl-8 mb-5 text-lg-l5-semibold">Upload History</div>
          <div className="p-1">
            <Spin spinning={uploadloading}>
              <AntTable
                dataSource={data}
                columns={columns}
                key="dataIndex"
                pagination={true}
              />
            </Spin>
          </div>
        </div>
      )}
      {!!!data.length && (
        <div className=" mt-24">
          <img
            src={EmptyState}
            alt="empty state"
            className=" max-w-sm mx-auto"
          />
        </div>
      )}
      <Modal
        title={<p className="textHeadings text-center">File Upload failed</p>}
        visible={uploadFailedModal}
        footer={null}
        closable={false}
        keyboard={false}
        bodyStyle={{ padding: "0 24px 24px 24px" }}
        style={{ boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)" }}
        mask={true}
        onCancel={() => {
          setUploadSuccess(false);
          // setEnableModal(false);
          setUploadFailed(false);
          setFileList([]);
          setFileUrl();
        }}
      >
        <div className="flex flex-col  ">
          <p className="text-sm-l5 text-gray-500 text-center">{errormessage}</p>
          <img
            src={UploadFailed}
            alt="empty state"
            className="flex max-w-sm mx-auto"
          />
          <Button
            type="primary"
            size="large"
            className="w-full mt-4"
            onClick={() => {
              setUploadSuccess(true);
              setUploadFailed(false);
              setFileList([]);
              setFileUrl();
            }}
          >
            UPLOAD FILE AGAIN
          </Button>
        </div>
      </Modal>
      <Modal
        title={<p className="textHeadings text-center">Plan Expired</p>}
        visible={enablePlanModal}
        footer={null}
        closable={false}
        keyboard={false}
        style={{ boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)" }}
        bodyStyle={{ padding: "0 24px 24px 24px" }}
        mask={true}
        onCancel={() => {
          setEnablePlanModal(false);
          setUploadSuccess(false);
          // setEnableModal(false);
          setUploadFailed(false);
          setFileList([]);
          setFileUrl();
        }}
      >
        <div className="flex flex-col  ">
          <p className="text-sm-l5 text-gray-500 text-center">
            {planDetails?.users_x_plans[0]?.entry_count >=
            planDetails?.users_x_plans[0]?.allowed_count
              ? "Your free plan has expired. You cannot upload new expenses or download any reports. To continue using this service, please upgrade your plan or contact support@nfnlabs.in."
              : entryCount + planDetails?.users_x_plans[0]?.entry_count >
                planDetails?.users_x_plans[0]?.allowed_count
              ? `You have ${
                  planDetails?.users_x_plans[0]?.allowed_count -
                  planDetails?.users_x_plans[0]?.entry_count
                } free entries left. Please update your file to match the limit. Once your free plan expires, you cannot upload new expenses or download any reports. To continue using this service, please upgrade your plan or contact support@nfnlabs.in.`
              : ""}
          </p>
          <Button
            type="primary"
            size="large"
            className="w-full"
            onClick={() => {
              setEnablePlanModal(false);
              setUploadSuccess(false);
              // setEnableModal(false);
              setUploadFailed(false);
              setFileList([]);
              setFileUrl();
            }}
          >
            I UNDERSTAND
          </Button>
        </div>
      </Modal>

      {/* <Modal
        title={<p className="textHeadings text-center">Upload your data</p>}
        visible={enableModal}
        footer={null}
        closable={false}
        maskClosable={true}
        keyboard={false}
        bodyStyle={{ padding: "0 24px 24px 24px" }}
        style={{ boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)" }}
        mask={true}
        onCancel={() => {
          setUploadSuccess(false);
          setEnableModal(false);
          setUploadFailed(false);
        }}
        // afterClose={() => setUploadSuccess(true)}
      >
        <div className="flex flex-col  ">
          <p className="text-sm-l5 text-gray-500 text-center">
            You can upload your csv file
          </p>
          <Button
            type="primary"
            size="large"
            className="w-full"
            onClick={() => {
              setUploadSuccess(true);
              setEnableModal(false);
              setUploadFailed(false);
            }}
          >
            I UNDERSTAND
          </Button>
        </div>
      </Modal> */}
      <Modal
        title={<p className="textHeadings text-center">Upload your data</p>}
        visible={uploadSuccess}
        onCancel={() => {
          setUploadSuccess(false);
          // setEnableModal(false);
          setUploadFailed(false);
        }}
        footer={null}
        keyboard={false}
        maskClosable={false}
        mask={true}
        bodyStyle={{ padding: "0 24px 24px 24px" }}
        style={{ boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)" }}
        closeIcon={<img src={CloseIcon} alt="close icon" />}
      >
        <div className="flex flex-col  fileUpload">
          <p className="text-sm-l5 text-gray-500 text-center">
            You can upload your csv file
          </p>
          <Upload
            multiple={false}
            accept=".csv"
            method="PUT"
            customRequest={uploadFile}
            progress={true}
            maxCount={1}
            fileList={fileList}
            onChange={(info) => {
              let newFileList = [...info.fileList];

              setFileList(newFileList);
            }}
            showUploadList={{
              removeIcon: <div style={{ display: "none" }} />,
            }}
            beforeUpload={async (file) => {
              let columnHeadingSample =
                "Date,Expense,Amount,Category,Tags,Transaction ID";
              let errorFile = false;
              let errorText;
              var reader = new FileReader();
              reader.readAsText(file);
              reader.onload = async function (e) {
                let result = e.target.result;
                result = result.split("\r\n");
                let fileHeading = result[0];
                result = result.filter((e) => e !== ",,,");
                result.shift();
                let count = 0;
                result.forEach((item) => {
                  count += 1;
                });
                result.forEach((item) => {
                  let dateregex =
                    /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./]([0]?[1-9]|[1][0-2])[./]([0-9]{4}|[0-9]{2})$/;
                  let alphanumericWithSpecial =
                    /[A-Za-z0-9!@#$%^&*(),.?":;{}'|+~`_\\\/[\]=<>-]/;
                  let alphanumericCategory =
                    /^[a-zA-Z0-9()\\-`.+\,/\?\-\s:;]*$/;
                  let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                  let amountCheck = /^[+-]?(\d*\.)?\d+$/;
                  // let amountCheck = /^[\d]+[\.][\d]{2}$/;
                  item = item.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
                  let amount =
                    item[2] && item[2].replace(/"/g, "").replaceAll(",", "");
                  let expenseName =
                    item[1] && item[1].replace(/"/g, "").replaceAll(",", "");
                  if (!dateregex.test(item[0])) {
                    errorText =
                      "Some rows are using the wrong date format. Please make sure format is dd/mm/yyyy";
                    errorFile = true;
                    return;
                  } else if (!alphanumericWithSpecial.test(expenseName)) {
                    errorText =
                      "Some rows are having wrong expense name. Please make sure it is not blank and follows the proper format";
                    errorFile = true;
                    return;
                  } else if (!alphanumericCategory.test(item[3])) {
                    errorText =
                      "Some rows are having wrong format for category. Please make sure it is not blank and follows the proper format";
                    errorFile = true;
                    return;
                  } else if (item[5] !== "" && !alphanumeric.test(item[5])) {
                    errorText =
                      "Some rows are having wrong format for transaction ID. Please make sure the ID is alphanumeric and not formatted to scientific notation";
                    errorFile = true;
                    return;
                  } else if (!amountCheck.test(amount)) {
                    errorText =
                      "Some rows are having wrong format for amount. Allowed formats are: '1234', '1,234', '1234.56'";
                    errorFile = true;
                    return;
                  }
                });
                if (fileHeading !== columnHeadingSample) {
                  setErrormessage(
                    "Column headings should be same as in sample file"
                  );
                  setUploadFailed(true);
                  return false;
                } else if (errorFile) {
                  setErrormessage(errorText);
                  setUploadFailed(true);
                  return false;
                } else if (
                  planDetails?.users_x_plans[0]?.entry_count >=
                    planDetails?.users_x_plans[0]?.allowed_count ||
                  count + planDetails?.users_x_plans[0]?.entry_count >
                    planDetails?.users_x_plans[0]?.allowed_count
                ) {
                  setEntryCount(count);
                  setEnablePlanModal(true);
                  return false;
                } else {
                  return true;
                }
              };
            }}
          >
            <Button type="primary" size="large" className="w-full">
              I UNDERSTAND
            </Button>
            {/* {loading ? (
              <>
                <LoadingOutlined className="ml-4" />
              </>
            ) : (
              <></>
            )} */}
          </Upload>
        </div>
      </Modal>
    </div>
  );
}
