import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Badge, Dropdown, Menu, Popover, Button, Tooltip } from "antd";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import packageJson from "../../../package.json";
import DropdownIcon from "../../assets/icons/dropdown.svg";
import Notification from "../../assets/icons/notifications.svg";
import Search from "../../components/Search";
import { avatarMap } from "../../utils";
import { UserOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import {
  GET_AVATAR,
  GET_DOWNLOAD_URL,
  NOTIFICATION_SUBSCRIPTION,
  GET_NOTIFICATION,
  UPDATE_READ,
  GET_USER_PLAN,
} from "./graphql";
import moment from "moment";
import { createPdfFromHtml } from "../../utils/print";
import Download from "../../assets/icons/download.svg";

export default function Nav({ heading }) {
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(null);
  const [list, setList] = useState([]);
  const search = useLocation().search;
  const [username, setUsername] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "user-name"
    ]
  );
  const [role, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "x-hasura-default-role"
    ]
  );
  const [roleID, setRoleID] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "X-Hasura-User-Id"
    ]
  );
  const [notificationCount, setNotificationCount] = useState(0);
  const { data: count } = useSubscription(NOTIFICATION_SUBSCRIPTION);
  const { data: notificationData, loading: notificationLoading } =
    useSubscription(GET_NOTIFICATION);

  const { data: planDetails, refetch: refetchUserPlan } = useQuery(
    GET_USER_PLAN,
    {
      variables: {
        id: parseInt(roleID),
      },
    }
  );

  const { data, loading: avatarLoading } = useQuery(GET_AVATAR, {
    variables: {
      id: parseInt(
        jwtDecode(localStorage.getItem("token"))[
          "https://hasura.io/jwt/claims"
        ]["X-Hasura-User-Id"]
      ),
    },
  });
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const [searchVisible, setSearchVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [updateRead] = useMutation(UPDATE_READ);

  useEffect(() => {
    refetchUserPlan();
  }, []);

  useEffect(() => {
    if (count) {
      setNotificationCount(count?.notifications_aggregate?.aggregate?.count);
    }
  }, [count]);

  useEffect(() => {
    if (notificationData) {
      let object = JSON.parse(JSON.stringify(notificationData.notifications));

      object.forEach((item) => {
        item.text = item?.text;
        item.createdTime = moment(item?.created_at).fromNow();
      });
      setList(object);
    }
  }, [notificationData]);

  useEffect(() => {
    if (data && data.users.length !== 0 && data.users[0].avatar !== null) {
      const fileDetails = data.users[0].avatar.split("/");
      if (fileDetails[2] === process.env.REACT_APP_AWS_URL) {
        const fileKey = fileDetails[fileDetails.length - 1];
        getDownloadURL({
          variables: {
            name: fileKey,
          },
        }).then((res) => {
          const url = res.data.getviewurl.url;
          setAvatar(url);
        });
      } else {
        setAvatar(data.users[0].avatar);
      }
    }
  }, [data]);

  const handleDownloadPdf = () => {
    let newArr = [];
    let tablecolor = [];
    const element = document.getElementById("printDashboard");
    let nodes = element.getElementsByClassName("hideviewall");
    let hidebuttonode = element.getElementsByClassName("hidebutton");
    let tablebg = element.getElementsByClassName("ant-table-row ");
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].style.display = "none";
    }
    for (let i = 0; i < hidebuttonode.length; i++) {
      hidebuttonode[i].style.display = "none";
    }
    let tagBg = document.getElementsByClassName("ant-tag");
    for (let i = 0; i < tagBg.length; i++) {
      newArr.push(tagBg[i].style.backgroundColor);
      tagBg[i].style.color = "#000";
      tagBg[i].style.backgroundColor = "#fff";
    }
    for (let i = 0; i < tablebg.length; i++) {
      tablecolor.push(tablebg[i].style.backgroundColor);
      tablebg[i].style.backgroundColor = "#fff";
    }
    navigate(
      `/dashboard?base_currency=${new URLSearchParams(search).get(
        "base_currency"
      )}&downloadPdf=true`,
      { replace: true }
    );
    createPdfFromHtml(document.getElementById("printDashboard")).then(() => {
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].style.display = "block";
      }
      for (let i = 0; i < hidebuttonode.length; i++) {
        hidebuttonode[i].style.display = "block";
      }

      let tablebg = element.getElementsByClassName("ant-table-row ");
      for (let i = 0; i < tablebg.length; i++) {
        if (i % 2 == 0) {
          tablebg[i].style.backgroundColor = "#fff";
        } else {
          tablebg[i].style.backgroundColor = "#f8f7ff";
        }
      }
      let tagBg = document.getElementsByClassName("ant-tag");
      for (let i = 0; i < tagBg.length; i++) {
        tagBg[i].style.color = "#fff";
        tagBg[i].style.backgroundColor = newArr[i];
      }
      navigate(
        `/dashboard?base_currency=${new URLSearchParams(search).get(
          "base_currency"
        )}`,
        { replace: true }
      );
    });
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined />}
        onClick={() => logout()}
      >
        Logout
      </Menu.Item>
      <Menu.Item key="version" disabled>
        {packageJson.version}
      </Menu.Item>
    </Menu>
  );

  const content = (
    <div>
      <p>
        <span className="text-black text-sm font-semibold">Notifications</span>
        <Button
          type="link"
          className="float-right p-0"
          disabled={notificationCount === 0 ? true : false}
          onClick={() => {
            updateRead({
              variables: {
                user_id: parseInt(roleID),
              },
            });
          }}
        >
          Mark all as read
        </Button>
      </p>
      {list &&
        list.length !== 0 &&
        list.map((item, index) => {
          return (
            <div className="mb-2" key={index}>
              <div>
                <span className="text-gray-900 font-normal text-sm w-full">
                  {item.text}
                </span>
              </div>

              <div className="float-right bg-bgYellow text-sm rounded-xl text-center text-gray-500 ">
                {item.createdTime}
              </div>

              <div className="clear-both"></div>
            </div>
          );
        })}
      {list && list.length === 0 && (
        <div className="mb-12">
          <div className="float-left w-72">
            <span className="text-gray-900 font-normal text-sm w-full">
              No new notifications
            </span>
          </div>
        </div>
      )}
    </div>
  );
  return (
    <div className="w-full px-8 py-3 flex justify-between items-center shadow-1 sticky top-0 z-10 bg-white">
      <div className=" text-lg-l5-semibold ">
        {heading}
        {heading === "Dashboard" && (
          <Tooltip title="Download current view" placement="right">
            <span
              className={`float-right ml-4 ${
                planDetails?.users_x_plans[0]?.entry_count >=
                planDetails?.users_x_plans[0]?.allowed_count
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              onClick={
                planDetails?.users_x_plans[0]?.entry_count >=
                planDetails?.users_x_plans[0]?.allowed_count
                  ? ""
                  : handleDownloadPdf
              }
            >
              {" "}
              <img src={Download} alt="download" className="mr-2.5" />
            </span>
          </Tooltip>

          // <Button
          //   type="primary"
          //   ghost
          //   className={`ml-4 ${
          //     planDetails?.users_x_plans[0]?.entry_count >=
          //     planDetails?.users_x_plans[0]?.allowed_count
          //       ? "cursor-not-allowed"
          //       : "cursor-pointer"
          //   }`}
          //   onClick={
          //     planDetails?.users_x_plans[0]?.entry_count >=
          //     planDetails?.users_x_plans[0]?.allowed_count
          //       ? ""
          //       : handleDownloadPdf
          //   }
          // >
          //   {" "}
          //    Download current view
          // </Button>
        )}
      </div>
      {searchVisible && (
        <div
          className=" fixed inset-0 bg-black bg-opacity-5"
          onClick={() => {
            setSearchVisible(false);
          }}
        ></div>
      )}
      <Search
        searchVisible={searchVisible}
        placeholder={
          role === "company_user"
            ? "Search for category, tag, expense"
            : "Search for user, email, company name"
        }
        role={role}
        setSearchVisible={setSearchVisible}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div className="flex items-center justify-center">
        <Badge
          count={notificationCount >= 3 ? 3 : notificationCount}
          size="small"
          offset={[-5, 7]}
        >
          <Popover
            placement="bottomRight"
            content={content}
            trigger="click"
            overlayStyle={{
              width: "420px",
            }}
            overlayInnerStyle={{
              background: "#F7F7F7",
            }}
          >
            <img
              src={Notification}
              alt="Notification"
              className=" cursor-pointer"
            />
          </Popover>
        </Badge>
        <Dropdown overlay={menu}>
          <div className="flex items-center ml-3 cursor-pointer" size="large">
            <div className="bg-orange bg-opacity-10 rounded-full">
              {avatar ? (
                <Avatar src={avatar}></Avatar>
              ) : (
                <Avatar
                  className=" bg-indigo"
                  style={{
                    // backgroundColor: "#FFF6D6",
                    // color: '#4C39B3',
                    fontSize: 14,
                  }}
                  size={32}
                >
                  {username ? avatarMap(username, 3) : <UserOutlined />}
                </Avatar>
              )}
            </div>

            <img src={DropdownIcon} className="ml-3" alt="Dropdown icon" />
          </div>
        </Dropdown>
      </div>
    </div>
  );
}
