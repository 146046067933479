import {
  ApolloClient,
  split,
  concat,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GQL_SUBSCRIPTION_ENDPOINT,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    },
  },
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API,
});

const authLink = setContext((_, { headers }) => {
  let userToken = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      ...(userToken ? { authorization: `Bearer ${userToken}` } : {}),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: errorLink.concat(concat(authLink, link)),
});

export default client;
