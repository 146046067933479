import { Route, Routes } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import Dashboard from "../pages/dashboard";
import Login from "../pages/login/login";
import OTPPage from "../pages/login/otpverification";
import SignUpForm from "../pages/login/signUp";
import Report from "../pages/report";
import Settings from "../pages/settings";
import AdminLogin from "../pages/superadmin/login";
import AdminOverview from "../pages/superadmin/overview";
import AdminSettings from "../pages/superadmin/settings";
import AdminUsers from "../pages/superadmin/users";
import AdminUserDetails from "../pages/superadmin/users/id";
import UploadData from "../pages/uploadData";
import ReviewData from "../pages/uploadData/review";
import PrivateRoute from "./privateRoute";
import GoogleAuth from "../pages";

export default function AppRoutes() {
  return (
    <main>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<SignUpForm />} />
        <Route path="/signup-otp" element={<SignUpForm />} />
        <Route path="/company-details" element={<SignUpForm />} />
        <Route path="/otp-verification" element={<OTPPage />} />
        <Route
          path="/upload"
          element={
            <PrivateRoute
              heading="Upload Data"
              component={UploadData}
              roles={["company_user"]}
            />
          }
        />
        <Route
          path="/upload/review"
          element={
            <PrivateRoute
              heading="Review Data"
              component={ReviewData}
              roles={["company_user"]}
            />
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute
              heading={"Dashboard"}
              component={Dashboard}
              roles={["company_user"]}
            />
          }
        />
        <Route path="/google-auth" element={<GoogleAuth />} />
        <Route
          path="/settings"
          element={
            <PrivateRoute
              heading={"Settings"}
              component={Settings}
              roles={["company_user"]}
            />
          }
        />
        <Route
          path="/reports"
          element={
            <PrivateRoute
              heading={"Reports"}
              component={Report}
              roles={["company_user"]}
            />
          }
        />

        {/** Super Admin */}
        <Route path="/login" element={<AdminLogin />} />
        <Route
          path="/admin/overview"
          element={
            <PrivateRoute
              heading={"Overview"}
              component={AdminOverview}
              roles={["super_admin"]}
            />
          }
        />
        <Route
          path="/admin/users"
          element={
            <PrivateRoute
              heading={"Users"}
              component={AdminUsers}
              roles={["super_admin"]}
            />
          }
        />
        <Route
          path="/admin/users/:id"
          element={
            <PrivateRoute
              heading={"Users"}
              component={AdminUserDetails}
              roles={["super_admin"]}
            />
          }
        />
        <Route
          path="/admin/settings"
          element={
            <PrivateRoute
              heading={"Settings"}
              component={AdminSettings}
              roles={["super_admin"]}
            />
          }
        />

        <Route path="*" element={<ErrorBoundary />} />
      </Routes>
    </main>
  );
}
