import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
// import { numberWithCommas } from "../utils";

const ExpenseCard = (props) => {
  return (
    <div className={`p-5 flex flex-col bg-white shadow-1 ${props.className}`}>
      <div>
        <span className="text-sm font-medium text-gray-500">
          {props.description}
        </span>
      </div>
      <div
        className={`text-3xl font-medium mt-2.5 ${
          props.count === 0
            ? "text-gray-500 "
            : props.count > 0
            ? "text-indigo"
            : "text-brightRed"
        }`}
      >
        {props.count === 0
          ? "0"
          : props.count > 0
          ? `${
              props.role !== "superadmin"
                ? props.base_currency === "inr"
                  ? `Rs.${props.count.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                    })}`
                  : `$ ${props.count.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}`
                : props.count
            }`
          : `${
              props.role !== "superadmin"
                ? props.base_currency === "inr"
                  ? "Rs."
                  : "$"
                : ""
            }${Math.abs(props.count).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}`}
      </div>
      <Link
        to={
          props.role !== "superadmin"
            ? `/reports?dateRange=${props.range}&currency=${props.base_currency}`
            : `/admin/users?dateRange=${props.range}`
        }
        className={`hideviewall text-sm font-normal mt-5 w-2/12 ${
          props.count === 0
            ? "pointer-events-none text-gray-500"
            : "text-indigo"
        }`}
      >
        View all
      </Link>
    </div>
  );
};

ExpenseCard.defaultProps = {
  count: 0,
  description: "",
  className: "",
};

ExpenseCard.propTypes = {
  count: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ExpenseCard;
