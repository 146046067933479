import React, { useEffect, useState } from "react";
import ExpenseCard from "../../components/expenseCard";
import TopCategories from "./topCategories";
import TopExpenses from "./topExpenses";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import RecentTransactions from "./recentTransactions";
import { getCurrentFinancialYear } from "../../utils";
import { EXPENSE_AGGREGATE, COMPANY_DETAILS } from "./graphql";
import { useQuery } from "@apollo/client";
import moment from "moment";
import jwtDecode from "jwt-decode";

export default function Dashboard() {
  const [roleID, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "X-Hasura-User-Id"
    ]
  );
  const [activeCategory, setActiveCategory] = useState("category");
  const [activeTabCategories, setActiveTabCategories] = useState("1");
  const [activeTabExpense, setActiveTabExpense] = useState("monthly");
  const search = useLocation().search;
  const today = new Date();
  const cur_year = today.getFullYear();

  const {
    data: expenseAggregate,
    loading: expenseLoading,
    refetch: expenseAggregateRefetch,
  } = useQuery(EXPENSE_AGGREGATE, {
    variables: {
      startdate:
        new URLSearchParams(search).get("base_currency") === "inr"
          ? today.getMonth() + 1 <= 3
            ? moment((cur_year - 1).toString())
                .month("April")
                .startOf("month")
                .format("YYYY-MM-DD")
            : moment(cur_year.toString())
                .month("April")
                .startOf("month")
                .format("YYYY-MM-DD")
          : today.getMonth() + 1 <= 9
          ? moment((cur_year - 1).toString())
              .month("October")
              .startOf("month")
              .format("YYYY-MM-DD")
          : moment(cur_year.toString())
              .month("October")
              .startOf("month")
              .format("YYYY-MM-DD"),
      enddate:
        new URLSearchParams(search).get("base_currency") === "inr"
          ? today.getMonth() + 1 <= 3
            ? moment(cur_year.toString())
                .month("March")
                .endOf("month")
                .format("YYYY-MM-DD")
            : moment((cur_year + 1).toString())
                .month("March")
                .endOf("month")
                .format("YYYY-MM-DD")
          : today.getMonth() + 1 <= 9
          ? moment(cur_year.toString())
              .month("September")
              .endOf("month")
              .format("YYYY-MM-DD")
          : moment((cur_year + 1).toString())
              .month("September")
              .endOf("month")
              .format("YYYY-MM-DD"),
      monthstartdate: moment().subtract(30, "day").format("YYYY-MM-DD"),
      monthenddate: moment().format("YYYY-MM-DD"),
      quarterstartdate: moment().subtract(3, "months").format("YYYY-MM-DD"),
      quarterenddate: moment().format("YYYY-MM-DD"),
    },
  });

  const {
    data: company_details,
    loading: companyLoading,
    refetch: companyDetails,
  } = useQuery(COMPANY_DETAILS, {
    variables: {
      id: parseInt(roleID),
    },
  });

  useEffect(() => {
    companyDetails();
    expenseAggregateRefetch();
  }, []);
  return (
    <Spin
      spinning={
        (expenseLoading && companyLoading) ||
        new URLSearchParams(search).get("downloadPdf")
      }
    >
      <div id="printDashboard" className="py-6 px-8 w-full">
        <div className="grid grid-cols-3 gap-4 mt-5">
          <ExpenseCard
            count={
              expenseAggregate?.expense_aggregate?.aggregate?.sum?.amount ===
              null
                ? 0
                : expenseAggregate?.expense_aggregate?.aggregate?.sum?.amount
            }
            description={`Financial Year (${getCurrentFinancialYear(
              company_details && company_details.company.length !== 0
                ? company_details.company[0].base_currency
                : ""
            )})`}
            range="yearly"
            className="flex-grow rounded-lg"
            base_currency={
              company_details && company_details.company.length !== 0
                ? company_details.company[0].base_currency
                : ""
            }
            type="print"
          />
          <ExpenseCard
            count={
              expenseAggregate?.quarterly?.aggregate?.sum?.amount === null
                ? 0
                : expenseAggregate?.quarterly?.aggregate?.sum?.amount
            }
            description="Last 90 days"
            range="quarterly"
            className="flex-grow rounded-lg"
            base_currency={
              company_details && company_details.company.length !== 0
                ? company_details.company[0].base_currency
                : ""
            }
            type="print"
          />
          <ExpenseCard
            count={
              expenseAggregate?.currentMonth?.aggregate?.sum?.amount === null
                ? 0
                : expenseAggregate?.currentMonth?.aggregate?.sum?.amount
            }
            description="Last 30 days"
            range="monthly"
            className="flex-grow rounded-lg"
            base_currency={
              company_details && company_details.company.length !== 0
                ? company_details.company[0].base_currency
                : ""
            }
            type="print"
          />
        </div>
        <TopCategories
          base_currency={
            company_details && company_details.company.length !== 0
              ? company_details.company[0].base_currency
              : ""
          }
          type="print"
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          activeTabCategories={activeTabCategories}
          setActiveTabCategories={setActiveTabCategories}
        />
        <TopExpenses
          base_currency={
            company_details && company_details.company.length !== 0
              ? company_details.company[0].base_currency
              : ""
          }
          type="print"
          activeTabExpense={activeTabExpense}
          setActiveTabExpense={setActiveTabExpense}
        />
        <RecentTransactions />
        <div className="mb-6 py-4">
          <span className="text-sm font-medium mb-0 text-gray-400 float-left">
            ⓒ GetInsights.finance {moment().year()}
          </span>
          <span className="text-sm font-medium mb-0 text-gray-400 float-right">
            {company_details && company_details.company.length !== 0
              ? company_details.company[0].name
              : ""}
          </span>
        </div>
      </div>
    </Spin>
  );
}
