import { Button, Card, Input, Form, Select, Tooltip, Spin } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import {
  INSERT_COMPANY,
  UPDATE_USERS,
  COMPANY_DETAILS,
  INSERT_USER_PLAN,
} from "./graphql";
import { useMutation, useQuery } from "@apollo/client";
import jwtDecode from "jwt-decode";
import moment from "moment";

const { Option } = Select;

function CompanyDetails() {
  const { data, loading, refetch } = useQuery(COMPANY_DETAILS, {
    variables: {
      id: parseInt(
        jwtDecode(localStorage.getItem("token"))[
          "https://hasura.io/jwt/claims"
        ]["X-Hasura-User-Id"]
      ),
    },
  });
  const navigate = useNavigate();
  const [insertCompany] = useMutation(INSERT_COMPANY);
  const [updateUsers] = useMutation(UPDATE_USERS);
  const [insertUserPlan] = useMutation(INSERT_USER_PLAN);
  const [currencyType, setCurrencyType] = useState();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data && data.company.length !== 0) {
      navigate(`/dashboard?base_currency=${data.company[0].base_currency}`);
    }
  }, [data]);

  const onFinish = (values) => {
    let companyObject = {
      name: values.companyName,
      base_currency: values.currencyType,
    };
    let planObject = {
      plan_id: 1,
      start_date: moment(moment(), "DD/MM/YYYY").format("DD/MMM/YYYY"),
      allowed_count: 500,
    };
    insertCompany({
      variables: {
        object: companyObject,
      },
    }).then((res) => {
      updateUsers({
        variables: {
          userId: parseInt(
            jwtDecode(localStorage.getItem("token"))[
              "https://hasura.io/jwt/claims"
            ]["X-Hasura-User-Id"]
          ),
          companyId: res?.data?.insert_company?.returning[0]?.id,
        },
      }).then(() => {
        insertUserPlan({
          variables: {
            object: planObject,
          },
        }).then(() => {
          navigate(
            `/dashboard?base_currency=${res?.data?.insert_company?.returning[0]?.base_currency}`
          );
        });
      });
    });
  };

  return (
    <Spin spinning={loading}>
      <div className="flex justify-center items-center h-screen">
        <Card
          style={{
            width: "460px",
          }}
          bordered={false}
        >
          <div className="flex flex-col justify-center items-left companyForm">
            <img
              src={logo}
              alt="expense-insights"
              style={{ width: "49px", height: "49px" }}
            />
            <p className="text3xl mt-8 mb-0">Enter your details </p>
            <div className="py-6">
              <Form
                name="company_details"
                layout="vertical"
                requiredMark={false}
                onFinish={onFinish}
              >
                <Form.Item
                  label={<span className="textsm">Company Name</span>}
                  name="companyName"
                  rules={[
                    { message: "Enter the company name", required: true },
                  ]}
                >
                  <Input
                    size="large"
                    type="text"
                    placeholder="Enter company name"
                    style={{ borderRadius: "6px" }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      <span className="textsm">Base Currency</span>
                      <Tooltip
                        placement="right"
                        title="This cannot be changed later"
                        arrowPointAtCenter
                      >
                        <InfoCircleOutlined className="float-left ml-2" />
                      </Tooltip>
                    </>
                  }
                  name="currencyType"
                  rules={[
                    { message: "Choose the base currency", required: true },
                  ]}
                >
                  <Select
                    value={currencyType}
                    size="large"
                    placeholder="Choose base currency"
                    onChange={(value) => setCurrencyType(value)}
                    dropdownStyle={{ padding: "10px 0" }}
                  >
                    <Option key="inr">Indian Rupee (INR)</Option>
                    <Option key="usd">US Dollar (USD)</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="buttonCss mt-4"
                    block
                    size="large"
                  >
                    SIGN UP
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="py-6"></div>
        </Card>
      </div>
    </Spin>
  );
}

export default CompanyDetails;
