import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";

function SelectPlan(props) {
  return (
    <>
      <div
        className="text-lg flex font-normal text-charcoal cursor-pointer"
        onClick={props.handleUpgrade}
      >
        <ArrowLeftOutlined
          className="justify-center"
          style={{ marginTop: "5px" }}
        />
        <span className="ml-2">Go back</span>
      </div>
      <div
        style={{
          border: "2px solid #4F46E5",
          width: "855px",
          height: "450px",
        }}
        className="p-6 flex flex-col justify-center items-center mt-10 rounded-lg "
      >
        <p className="text-2xl text-indigo font-semibold">
          Manage your expense smartly
        </p>
        <p className="font-normal text-xl text-indigo">
          The right price for you, whoever you are
        </p>
        <div className="mt-6 w-98 h-64 flex items-center justify-center ">
          <div className="float-left text-gray-900 border-2 border-r-0 border-indigo rounded-tl-lg rounded-bl-lg h-52 w-56">
            <div className="flex flex-col items-center justify-center border-b-2 h-32">
              <p className="font-medium text-2xl mb-2 ">Free Plan</p>
              <p className="flex mb-2">
                <span className=" text-3xl mt-2">$</span>
                <span className="text-5xl font-bold ml-2">0</span>
              </p>
            </div>
            <div className="flex items-center justify-center h-4.5 bg-gray-50">
              <Button className="shadow-lg border-0 text-indigo text-base w-170">
                Current plan
              </Button>
            </div>
          </div>
          <div className="float-left w-330 text-gray-900 border-2 border-indigo rounded-lg h-64">
            <div className="flex flex-col items-center justify-center rounded-tr-lg rounded-tl-lg h-44 bg-indigo">
              <div className="w-24 h-5 bg-white text-indigo rounded-full text-center pt-0.5 text-text_8 border-2 border-indigo relative bottom-4">
                MOST POPULAR
              </div>
              <div className="w-20 h-8 bg-white text-indigo rounded-full text-center pt-1.5">
                Save $20
              </div>
              <p className="font-medium text-2xl mb-2 text-white mt-4">
                Annual Plan
              </p>
              <p className="flex text-white">
                <span className=" text-3xl mt-2">$</span>
                <span className="text-5xl font-bold ml-2">100</span>
              </p>
            </div>
            <div className="flex items-center justify-center h-4.5 bg-gray-50">
              <Button className="shadow-lg border-0 text-white  text-base bg-indigo w-72">
                Choose this plan
              </Button>
            </div>
          </div>
          <div className="float-left text-gray-900 border-2 border-l-0 border-indigo rounded-tr-lg rounded-br-lg h-52 w-56">
            <div className="flex flex-col items-center justify-center border-b-2 h-32">
              <p className="font-medium text-2xl mb-2 ">Monthly Plan</p>
              <p className="flex mb-2">
                <span className=" text-3xl mt-2">$</span>
                <span className="text-5xl font-bold ml-2">10</span>
                <span className="ml-2 flex justify-center items-center">
                  /month
                </span>
              </p>
            </div>
            <div className="flex items-center justify-center h-4.5 bg-gray-50">
              <Button className="shadow-lg border-0 text-indigo text-base w-170">
                Choose this plan
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectPlan;
