import { Button, Card } from "antd";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { VALIDATE_OTP, COMPANY_DETAILS } from "./graphql";
import logo from "../../assets/logo.svg";
import OtpInput from "react-otp-input";
import EmailContext from "../../context/EmailContext";
import { useQuery } from "@apollo/client";
import jwtDecode from "jwt-decode";

function OTPPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const userMail = useContext(EmailContext);
  const [userid, setuserid] = useState();

  const [validateotp] = useMutation(VALIDATE_OTP);
  const { data, loading, refetch } = useQuery(COMPANY_DETAILS, {
    variables: {
      id: parseInt(
        localStorage.getItem("token")
          ? jwtDecode(localStorage.getItem("token"))[
              "https://hasura.io/jwt/claims"
            ]["X-Hasura-User-Id"]
          : 0
      ),
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (
      data &&
      data.company.length !== 0 &&
      localStorage.token &&
      props.title !== "signup"
    ) {
      navigate(`/dashboard?base_currency=${data.company[0].base_currency}`);
    } else if (userMail.email.toLowerCase() === "") {
      navigate("/");
    } else {
      let maskid = userMail.email
        .toLowerCase()
        .replace(
          /^(..)(.*)(@.*)$/,
          (_, a, b, c) => a + b.replace(/./g, "*") + c
        );
      setuserid(maskid);
    }
    // eslint-disable-next-line
  }, [userMail, location, data]);

  const handleChange = (code) => setCode(code);

  const handleLogin = (code) => {
    if (code.length !== 6) {
      setErrorMessage("Please enter the valid OTP");
    } else {
      setErrorMessage("");
      validateotp({
        variables: {
          email: userMail.email.toLowerCase(),
          otp: parseInt(code),
        },
      }).then((res) => {
        if (res?.data?.validateotp?.status === "success") {
          localStorage.setItem("token", res?.data?.validateotp?.token);
          // if (props.title === "signup") {
          //   navigate("/company-details");
          // } else {
          navigate("/google-auth");
          // }
        } else {
          setErrorMessage("Please enter the valid OTP");
        }
      });
    }
  };
  return (
    <div className="flex justify-center items-center h-screen">
      <Card
        style={{
          width: "460px",
        }}
        bordered={false}
      >
        <div
          className={`flex flex-col justify-center ${
            props.title === "signup" ? "items-left" : "items-center"
          } `}
        >
          <img
            src={logo}
            alt="expense-insights"
            style={{ width: "49px", height: "49px" }}
          />
          <p className="text3xl mt-8 mb-0">OTP </p>
          <p
            className={`${
              props.title === "signup"
                ? "text-text_18 font-semibold mt-6"
                : "textsm w-56 text-center mt-2"
            }`}
          >
            Enter the 6-digit verification code sent to{" "}
            <span className="text-indigo">{userid}</span>
          </p>
        </div>
        <div
          className={`${props.title === "signup" ? "py-6" : "p-6 shadow-lg"}`}
        >
          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={6}
            separator={<span style={{ width: "15px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              border: "1px solid #4F46E5",
              borderRadius: "8px",
              width: "40px",
              height: "45px",
              fontSize: "22px",
              color: "#4F46E5",
              fontWeight: "500",
            }}
            containerStyle={{ justifyContent: "center" }}
            focusStyle={{
              outline: "none",
            }}
          />
          <div>
            <p className="h-0.5 text-textRed mt-2">{errorMessage}</p>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className="buttonCss mt-4"
            block
            size="large"
            onClick={() => handleLogin(code)}
          >
            {props.title === "signup" ? "CONTINUE" : "LOG IN"}
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default OTPPage;
