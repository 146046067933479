import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import topExpenses from "../assets/topExpenses.svg";
import { calc } from "../utils";

export default function App(props) {
  const navigate = useNavigate();
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    // if (props.data.length !== 0) {
    setPieData(props.data);
    // }
  }, [props.data]);

  const CustomTooltip = ({ active, payload }) => {
    return (
      <div>
        {active && (
          <>
            <p className="label mb-0">{payload[0]?.payload?.payload?.title}</p>
            <p
              className={`label ${
                payload[0]?.payload?.payload?.expenseValue > 0
                  ? "text-indigo"
                  : "text-brightRed"
              }`}
            >{`Expense: ${calc(
              payload[0]?.payload?.payload?.value,
              props.base_currency
            )}`}</p>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {pieData.length !== 0 ? (
        <div className="flex justify-center items-center min-h-32">
          <div className="w-1/2">
            <ResponsiveContainer width={"100%"} height={250}>
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy={120}
                  innerRadius={80}
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className={`grid grid-cols-3 gap-4 w-1/2 `}>
            {pieData.map((entry, index) => (
              <div
                id="pieChart"
                key={`color-${index}`}
                className={`flex flex-col items-center justify-center mb-4`}
              >
                <div
                  style={{
                    width: 45,
                    height: 16,
                    backgroundColor: entry.color,
                    borderRadius: "10px",
                  }}
                ></div>
                <div
                  className={`${
                    entry.expenseValue > 0 ? "textsmPurple" : "text-brightRed"
                  } text-center pt-3 textOverflow`}
                  title={entry.title}
                >
                  {entry.title}
                </div>
                <p
                  className={`${
                    entry.expenseValue > 0 ? "text-indigo" : "text-brightRed"
                  }`}
                >
                  {props.total !== 0
                    ? `${((entry.value * 100) / props.total).toFixed(2)}%`
                    : ""}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center min-h-32">
          <img alt="topexpense" src={topExpenses} />
          <p className="text-lg-l5-semibold text-gray-500 mt-4">
            There is no data added yet
          </p>
          <Button
            type="primary"
            htmlType="submit"
            className="hidebutton font-bold"
            onClick={() => navigate("/upload")}
          >
            UPLOAD DATA
          </Button>
        </div>
      )}
    </>
  );
}
