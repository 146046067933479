import { Button } from "antd";
import React from "react";
import Protected from "../../assets/icons/protected.svg";
import Modal from "../Modal";
import { useNavigate } from "react-router-dom";

export default function DataProtectedModal({ visible, onCancel }) {
  const navigate = useNavigate();
  return (
    <div>
      <Modal closable={false} visible={visible} onCancel={onCancel} width={400}>
        <div className="text-center">
          <img src={Protected} alt="protected icon" className="mx-auto" />
          <div className="mt-5 text-lg-l6-medium text-gray-900">
            Data Protected
          </div>
          <div className="w-8/12 mx-auto mt-2 text-gray-500">
            Your data is highly secure with end-to-end encryption
          </div>
          <div className="mt-6">
            <Button
              className="uppercase"
              type="primary"
              block
              onClick={() => {
                navigate("/upload");
              }}
            >
              I UNDERSTAND
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
