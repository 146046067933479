import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
  query GET_USER_DETAILS {
    users {
      name
      email
      avatar
      company {
        id
        name
        info
        base_currency
      }
    }
  }
`;

export const UPDATE_USERNAME = gql`
  mutation UPDATE_USERNAME($name: String, $id: Int) {
    update_users(_set: { name: $name }, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_COMPANY_NAME = gql`
  mutation UPDATE_COMPANY_NAME($name: String, $id: Int) {
    update_company(where: { id: { _eq: $id } }, _set: { name: $name }) {
      affected_rows
    }
  }
`;

export const UPDATE_AVATAR = gql`
  mutation UPDATE_AVATAR($avatar: String, $id: Int) {
    update_users(_set: { avatar: $avatar }, where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        avatar
      }
    }
  }
`;

export const GET_DOWNLOAD_URL = gql`
  mutation GET_VIEW_URL($name: String!) {
    getviewurl(file_name: $name) {
      status
      url
    }
  }
`;

export const CATEGORY_LIST = gql`
  query CATEGORY_LIST {
    categories {
      category_text
    }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation UPDATE_EMAIL($email: String!) {
    update_email(email: $email) {
      message
      status
    }
  }
`;

export const VALIDATE_OTP_FOR_MAIL = gql`
  mutation VALIDATE_OTP_FOR_MAIL($otp: Int!) {
    validate_otp_for_mail(otp: $otp) {
      message
      status
    }
  }
`;
