import React, { useEffect, useMemo, useState } from "react";
import { Button, Spin } from "antd";
import { useLazyQuery, useQuery } from "@apollo/client";
import DatePicker from "../../../components/DatePicker";
import Select from "../../../components/Select";
import Table from "../../../components/Table";
import { GET_USER_DETAILS_ADMIN, GET_PLANS } from "../graphql";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";

export default function Users() {
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const [joinDateChanged, setJoinDateChanged] = useState(false);
  const [expiryDateChanged, setExpiryDateChanged] = useState(false);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [joinedDate, setJoinedDate] = useState([]);
  const [expiryDate, setexpiryDate] = useState([]);
  const [planValue, setPlanValue] = useState([]);
  const [searchTerm, setSearchTerm] = useState({});
  const [planList, setPlanList] = useState([]);
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        title: "DATE JOINED",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user",
      },
      {
        title: "email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Company Name",
        dataIndex: "companyName",
        key: "companyName",
      },
      {
        title: "PLAN",
        dataIndex: "plan",
        key: "plan",
      },
      {
        title: "EXPIRY DATE",
        dataIndex: "expiryDate",
        key: "expiryDate",
      },
    ],
    []
  );

  const [getDetails, { data: userDetails, loading: userLoading }] =
    useLazyQuery(GET_USER_DETAILS_ADMIN, {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

  const {
    data: plansList,
    loading: planloading,
    refetch,
  } = useQuery(GET_PLANS);

  useEffect(() => {
    refetch();
    getDetails({
      variables: {
        where: searchTerm,
      },
    });
  }, []);

  useEffect(() => {
    if (plansList) {
      let newArray = [];
      plansList.plans.map((item) => newArray.push(item.name));
      setPlanList(newArray);
    }
  }, [plansList]);

  useEffect(() => {
    getDetails({
      variables: {
        where: searchTerm,
      },
    });
  }, [searchTerm]);

  useEffect(() => {
    const today = moment();
    const startDate = moment().startOf("week");
    const endDate = moment().endOf("week");
    setJoinedDate(
      new URLSearchParams(search).get("dateRange") === "newUsers"
        ? [startDate, endDate]
        : new URLSearchParams(search).get("dateRange") === "allUsers"
        ? []
        : []
    );
    setJoinDateChanged(
      new URLSearchParams(search).get("dateRange") === null ? false : true
    );
  }, [location]);

  useEffect(() => {
    if (joinedDate && expiryDate && planValue) {
      if (
        window.location.href.includes("?") &&
        new URLSearchParams(search).get("searchText") !== null &&
        new URLSearchParams(search).get("searchText") !== "" &&
        new URLSearchParams(search).get("category") === "null"
      ) {
        setSearchTerm({
          _and: [
            {
              _or: [
                {
                  name: {
                    _ilike: `%${new URLSearchParams(search).get(
                      "searchText"
                    )}%`,
                  },
                },
                {
                  email: {
                    _ilike: `%${new URLSearchParams(search).get(
                      "searchText"
                    )}%`,
                  },
                },
                {
                  company: {
                    name: {
                      _ilike: `%${new URLSearchParams(search).get(
                        "searchText"
                      )}%`,
                    },
                  },
                },
              ],
            },
            {
              created_at:
                joinedDate.length !== 0 && joinedDate[0] && joinedDate[1]
                  ? {
                      _gte: moment(joinedDate[0].startOf("day")),
                      _lte: moment(joinedDate[1].endOf("day")),
                    }
                  : { _gte: joinedDate[0], _lte: joinedDate[1] },
            },
            { role_id: { _eq: 2 } },
            planValue.length !== 0
              ? { users_x_plans: { plan: { name: { _in: planValue } } } }
              : {},
          ],
        });
      } else if (
        window.location.href.includes("?") &&
        new URLSearchParams(search).get("searchText") !== null &&
        new URLSearchParams(search).get("searchText") !== "" &&
        new URLSearchParams(search).get("category") !== "null"
      ) {
        new URLSearchParams(search).get("category") === "User"
          ? setSearchTerm({
              _and: [
                {
                  name: {
                    _ilike: `%${new URLSearchParams(search).get(
                      "searchText"
                    )}%`,
                  },
                },
                {
                  created_at:
                    joinedDate.length !== 0 && joinedDate[0] && joinedDate[1]
                      ? {
                          _gte: moment(joinedDate[0].startOf("day")),
                          _lte: moment(joinedDate[1].endOf("day")),
                        }
                      : { _gte: joinedDate[0], _lte: joinedDate[1] },
                },
                { role_id: { _eq: 2 } },
                planValue.length !== 0
                  ? { users_x_plans: { plan: { name: { _in: planValue } } } }
                  : {},
              ],
            })
          : // setSearchTerm({
          //     expense_text: {
          //       _ilike: `%${new URLSearchParams(search).get("searchText")}%`,
          //     },
          //   })
          new URLSearchParams(search).get("category") === "Email"
          ? setSearchTerm({
              _and: [
                {
                  email: {
                    _ilike: `%${new URLSearchParams(search).get(
                      "searchText"
                    )}%`,
                  },
                },
                {
                  created_at:
                    joinedDate.length !== 0 && joinedDate[0] && joinedDate[1]
                      ? {
                          _gte: moment(joinedDate[0].startOf("day")),
                          _lte: moment(joinedDate[1].endOf("day")),
                        }
                      : { _gte: joinedDate[0], _lte: joinedDate[1] },
                },
                { role_id: { _eq: 2 } },
                planValue.length !== 0
                  ? { users_x_plans: { plan: { name: { _in: planValue } } } }
                  : {},
              ],
            })
          : // setSearchTerm({
            //     categories_x_expenses: {
            //       category: {
            //         category_text: {
            //           _ilike: `%${new URLSearchParams(search).get(
            //             "searchText"
            //           )}%`,
            //         },
            //       },
            //     },
            //   })
            setSearchTerm({
              _and: [
                {
                  company: {
                    name: {
                      _ilike: `%${new URLSearchParams(search).get(
                        "searchText"
                      )}%`,
                    },
                  },
                },
                {
                  created_at:
                    joinedDate.length !== 0 && joinedDate[0] && joinedDate[1]
                      ? {
                          _gte: moment(joinedDate[0].startOf("day")),
                          _lte: moment(joinedDate[1].endOf("day")),
                        }
                      : { _gte: joinedDate[0], _lte: joinedDate[1] },
                },
                { role_id: { _eq: 2 } },
                planValue.length !== 0
                  ? { users_x_plans: { plan: { name: { _in: planValue } } } }
                  : {},
              ],
            });

        // setSearchTerm({
        //     expense_tags: {
        //       _and: {
        //         tag: {
        //           tag_text: {
        //             _ilike: `%${new URLSearchParams(search).get(
        //               "searchText"
        //             )}%`,
        //           },
        //         },
        //       },
        //     },
        //   });
      } else {
        setSearchTerm({
          _and: [
            {
              created_at:
                joinedDate.length !== 0 && joinedDate[0] && joinedDate[1]
                  ? {
                      _gte: moment(joinedDate[0].startOf("day")),
                      _lte: moment(joinedDate[1].endOf("day")),
                    }
                  : { _gte: joinedDate[0], _lte: joinedDate[1] },
            },
            { role_id: { _eq: 2 } },
            planValue.length !== 0
              ? { users_x_plans: { plan: { name: { _in: planValue } } } }
              : {},
          ],
        });
      }
      setSearchEnabled(
        new URLSearchParams(search).get("searchText") === null ? false : true
      );
    }
  }, [joinedDate, expiryDate, planValue]);

  useEffect(() => {
    if (userDetails) {
      let object = JSON.parse(JSON.stringify(userDetails.users));
      object.map((item) => {
        item.date = moment(item.created_at).format("DD MMM YYYY");
        item.user = item?.name || "";
        item.companyName = item?.company?.name || "";
        item.plan = item?.users_x_plans[0]?.plan?.name;
        item.expiryDate = "-";
      });
      setData(object);
    }
  }, [userDetails]);

  const handleJoinedDate = (value) => {
    setJoinedDate(value);
    // setJoinedDate([
    //   moment(value[0].startOf("day")),
    //   moment(value[1].endOf("day")),
    // ]);
  };

  const handleExpirydate = (value) => {
    setexpiryDate(value);
  };

  const handleSelectedValue = (value, type) => {
    setPlanValue(value);
  };
  return (
    <Spin spinning={userLoading || planloading}>
      <div className="p-8">
        <div className="flex gap-5">
          <DatePicker
            placeholder="Date joined"
            separator=""
            pickerName="dateJoined"
            setDateChanged={setJoinDateChanged}
            handleDate={handleJoinedDate}
            value={joinedDate}
          />
          <Select
            placeholder="Plan"
            type="plan"
            data={planList}
            onChange={handleSelectedValue}
            value={planValue}
          />
          <DatePicker
            placeholder="Expiry Date"
            separator=""
            pickerName="expiryDate"
            setDateChanged={setExpiryDateChanged}
            handleDate={handleExpirydate}
            value={expiryDate}
          />
          <Button
            size="large"
            className={` border-metal text-metal ${
              planValue.length !== 0 ||
              joinDateChanged ||
              expiryDateChanged ||
              searchEnabled
                ? ""
                : "hidden"
            }`}
            onClick={() => {
              setJoinedDate([]);
              setexpiryDate([]);
              setJoinDateChanged(false);
              setExpiryDateChanged(false);
              setSearchEnabled(false);
              setPlanValue([]);
              navigate("/admin/users", { replace: true });
            }}
          >
            Clear Filters
          </Button>
        </div>
        <div className="pt-8">
          <Table
            locale={{ emptyText: "No results found" }}
            dataSource={data}
            columns={columns}
            pagination={true}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  navigate(`/admin/users/${record.id}`);
                  // setShowEditPopup(true);
                  // setExpense(record);
                  // form.setFieldsValue({
                  //   tags: record.addInfo,
                  //   transactionId: record.transaction_id,
                  // });
                }, // click row
              };
            }}
          ></Table>
        </div>
      </div>
    </Spin>
  );
}
