import { Button } from "antd";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import topCategories from "../assets/topCategories.svg";
import { useNavigate } from "react-router-dom";
import { numFormatter, calc } from "../utils";

export default function ExpenseBarChart(props) {
  const navigate = useNavigate();
  const highestValue = props.data.length !== 0 ? props.data[0].category : 5000;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div>
          <p className="label mb-0">{label}</p>
          <p
            className={`label ${
              payload[0].payload.total > 0 ? "text-indigo" : "text-brightRed"
            }`}
          >{`Expense: ${
            payload[0].payload.total > 0
              ? calc(payload[0].value, props.base_currency)
              : calc(payload[0].payload.total, props.base_currency)
          }`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomizedAxisTick = (props) => {
    const { x, y, payload, width, maxChars, lineHeight, fontSize, fill } =
      props;
    const rx = new RegExp(`.{1,${maxChars}}`, "g");
    const chunks = payload.value
      .replace(/ - /g, "-")
      .split(" ")
      .map((s) => s.match(rx))
      .flat();
    const tspans = chunks.map((s, i) => {
      return (
        <>
          {i < 3 && (
            <tspan x={0} y={lineHeight} dy={i * lineHeight}>
              {s}
            </tspan>
          )}
          {i === 3 && (
            <tspan x={0} y={lineHeight} dy={i * lineHeight}>
              {`${s.slice(0, 5)}...`}
            </tspan>
          )}
        </>
      );
    });
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          width={width}
          height="auto"
          textAnchor="middle"
          fontSize={fontSize}
          fill={fill}
        >
          {tspans}
        </text>
      </g>
    );
  };
  CustomizedAxisTick.defaultProps = {
    width: 50,
    maxChars: 10,
    fontSize: 12,
    lineHeight: 14,
    fill: "#9c9ca6",
  };

  return props.data.length !== 0 ? (
    <div id="barchart">
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          isAnimationActive={false}
        >
          <CartesianGrid stroke="#E7E8F2" vertical={false} />
          <XAxis
            interval={0}
            tick={<CustomizedAxisTick />}
            height={80}
            dataKey="name"
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            type="number"
            allowDataOverflow={true}
            domain={[0, highestValue]}
            tick={{ fill: "#9c9ca6" }}
            stroke="#9c9ca6"
            axisLine={false}
            tickLine={false}
            tickFormatter={numFormatter}
            tickCount={4}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ outline: "none" }}
            content={<CustomTooltip />}
          />
          <Bar dataKey={"expense"} fill="#4F46E5" barSize={30}>
            {props.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.total > 0 ? "#4F46E5" : "#EE4B2B"}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center py-4">
      <img
        alt="topcat"
        src={topCategories}
        style={{ width: 285, height: 200 }}
      />
      <p className="text-lg-l5-semibold text-gray-500 mt-4">
        There is no data added yet
      </p>

      <Button
        type="primary"
        htmlType="submit"
        className="hidebutton font-bold"
        onClick={() => navigate("/upload")}
      >
        UPLOAD DATA
      </Button>
    </div>
  );
}
