import { Tabs } from "antd";
import MyAccount from "./MyAccount";
import Categories from "./Categories";
import Billing from "./Billing";
import { useState } from "react";

const { TabPane } = Tabs;

function Settings() {
  const [resetTab, setResetTab] = useState(false);

  const onChange = (key) => {
    setResetTab(false);
  };

  return (
    <div style={{ padding: "24px" }} className="tabSettings min-h-screen">
      <Tabs
        defaultActiveKey="myAccount"
        onChange={onChange}
        tabPosition="left"
        size="large"
        tabBarStyle={{ width: "200px" }}
      >
        <TabPane
          key="myAccount"
          tab={<span className="textHeadings">My Account</span>}
        >
          <MyAccount />
        </TabPane>
        <TabPane
          tab={<span className="textHeadings">Categories</span>}
          key="categories"
        >
          <Categories />
        </TabPane>
        <TabPane
          tab={<span className="textHeadings">Billing</span>}
          key="billing"
        >
          <Billing setResetTab={setResetTab} resetTab={resetTab} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Settings;
