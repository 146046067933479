import { useMutation, useQuery } from "@apollo/client";
import { Input, Popover, Tabs, Spin, Button, Form, Avatar } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import BackIcon from "../../../assets/superadmin/icons/back.svg";
import MoreIcon from "../../../assets/superadmin/icons/more.svg";
import { avatarMap } from "../../../utils";
import Table from "../../../components/Table";
import { GET_USER_BY_PK, UPDATE_USERS, GET_DOWNLOAD_URL } from "../graphql";
import { useLocation } from "react-router-dom";
import Modal from "../../../components/Modal";
import { UserOutlined } from "@ant-design/icons";
import jwtDecode from "jwt-decode";
import moment from "moment";
const { TabPane } = Tabs;

const HeadingDesc = ({ heading, desc }) => {
  return (
    <div className="text-lg font-normal">
      <span className="text-indigo">{heading}</span>
      <span className="ml-1">{desc}</span>
    </div>
  );
};

export default function AdminUserDetails() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [roleName, setRoleName] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "user-name"
    ]
  );
  const [notesText, setNotesText] = useState("");
  const [comment, setComment] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const {
    data: userDetails,
    loading: userLoading,
    refetch: userRefetch,
  } = useQuery(GET_USER_BY_PK, {
    variables: {
      id: location.pathname.split("/")[3],
    },
    onCompleted: (data) => {
      let newArr =
        data?.users_by_pk?.comment === null
          ? []
          : [...data?.users_by_pk?.comment].sort((a, b) =>
              moment.utc(b.time).diff(moment.utc(a.time))
            );
      const fileDetails =
        data.users_by_pk.avatar !== null
          ? data.users_by_pk.avatar.split("/")
          : "";
      if (fileDetails[2] === process.env.REACT_APP_AWS_URL) {
        const fileKey = fileDetails[fileDetails.length - 1];
        getDownloadURL({
          variables: {
            name: fileKey,
          },
        }).then((res) => {
          const url = res.data.getviewurl.url;
          setAvatar(url);
        });
      } else {
        setAvatar(data.users_by_pk.avatar);
      }
      setComment(newArr);
    },
  });
  const [updateUsers] = useMutation(UPDATE_USERS);
  const [openNote, setOpenNote] = useState(false);
  const columns = useMemo(
    () => [
      {
        title: "DATE",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "SERVICE PERIOD",
        dataIndex: "expense",
        key: "expense",
      },
      {
        title: "PAYMENT METHOD",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "TOTAL",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Receipt",
        dataIndex: "addInfo",
        key: "addInfo",
      },
    ],
    []
  );
  const content = (
    <div className="text-sm-l5-semibold ">
      <p className="mb-3">Disable Account</p>
      <p className=" text-danger mb-0 ">Delete Account</p>
    </div>
  );

  useEffect(() => {
    userRefetch();
  }, []);

  return (
    <Spin spinning={userLoading} className="p-48">
      {userDetails && (
        <div className="p-8">
          <div className="flex justify-between">
            <Link to={"/admin/users"}>
              <div className="flex items-center justify-center">
                <img src={BackIcon} alt="back icon" />
                <span className="ml-4 text-lg-l6-medium text-black ">
                  Go back
                </span>
              </div>
            </Link>
            <div>
              <Popover
                placement="bottomRight"
                content={content}
                trigger="click"
              >
                <img
                  src={MoreIcon}
                  alt="More icon"
                  className="cursor-pointer"
                />
              </Popover>
            </div>
          </div>
          <div className="mt-8">
            <div className=" text-xl font-bold">
              {userDetails?.users_by_pk?.name}
            </div>
          </div>
          <div className="mt-4">
            {avatar ? (
              <Avatar
                src={avatar}
                className="bg-metal float-left"
                style={{
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 32,
                }}
                size={64}
              ></Avatar>
            ) : (
              <Avatar
                className="bg-indigo float-left"
                style={{
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 32,
                }}
                size={64}
              >
                {userDetails?.users_by_pk?.name !== null ? (
                  avatarMap(userDetails?.users_by_pk.name, 3)
                ) : (
                  <UserOutlined />
                )}
              </Avatar>
            )}
            <div className="ml-20 grid grid-cols-2 gap-y-8 gap-x-40 float-left">
              <HeadingDesc
                heading={"Email:"}
                desc={userDetails?.users_by_pk?.email}
              ></HeadingDesc>
              <HeadingDesc
                heading={"Plan:"}
                desc={userDetails?.users_by_pk?.users_x_plans[0]?.plan?.name}
              ></HeadingDesc>
              <HeadingDesc
                heading={"Base Currency:"}
                desc={(userDetails?.users_by_pk?.company === null
                  ? ""
                  : userDetails?.users_by_pk?.company?.base_currency
                ).toUpperCase()}
              ></HeadingDesc>
              <HeadingDesc
                heading={"Company Name:"}
                desc={userDetails?.users_by_pk?.company?.name}
              ></HeadingDesc>
              <HeadingDesc heading={"Expiry:"} desc="-"></HeadingDesc>
            </div>
            <div className="clear-both"></div>
          </div>
          <div className="mt-20">
            <Tabs defaultActiveKey="1">
              <TabPane
                key="1"
                tab={<span className="textHeadings">Billings</span>}
              >
                <Table
                  dataSource={[{}, {}]}
                  columns={columns}
                  pagination={true}
                ></Table>
              </TabPane>
              <TabPane
                tab={<span className="textHeadings">Notes</span>}
                key="2"
              >
                <div className="shadow-1 rounded-md p-4  h-325">
                  <div className="h-260 overflow-y-auto">
                    {comment && comment.length !== 0
                      ? comment.map((item) => (
                          <>
                            <div>
                              <p>{item.notes}</p>
                              <p>
                                <span className="ml-5 float-right ">
                                  {moment(item.time).fromNow()}
                                </span>
                                <span className="float-right font-semibold text-black">
                                  {item.adminName}
                                </span>
                              </p>
                            </div>
                            <p className="clear-both"></p>
                          </>
                        ))
                      : "No Notes added yet"}
                  </div>
                  <div>
                    <Button
                      type="primary"
                      ghost
                      className="float-right mt-1"
                      onClick={() => setOpenNote(true)}
                    >
                      Add Note
                    </Button>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
          <Modal
            title="Add a Note"
            visible={openNote}
            onCancel={() => {
              form.resetFields();
              setOpenNote(false);
            }}
            maskClosable={false}
          >
            <div className="flex flex-col  ">
              <Form layout="vertical" form={form}>
                <Form.Item label="Type in a note" name="notes">
                  <Input.TextArea
                    className="rounded-md"
                    autoSize={{ maxRows: 1 }}
                    value={notesText}
                    onChange={(e) => setNotesText(e.target.value)}
                  ></Input.TextArea>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    className="float-right"
                    disabled={notesText === "" ? true : false}
                    onClick={() => {
                      let newArray = [...comment];
                      newArray.push({
                        notes: notesText,
                        adminName: roleName,
                        time: moment(),
                      });
                      updateUsers({
                        variables: {
                          id: parseInt(userDetails?.users_by_pk?.id),
                          comment: newArray,
                        },
                      }).then(() => {
                        userRefetch();
                        form.resetFields();
                        setNotesText("");
                        setOpenNote(false);
                      });
                    }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Modal>
        </div>
      )}
    </Spin>
  );
}
