import { Table as AntTable } from "antd";
import React from "react";
import styled from "styled-components";
const TableStyle = styled.div`
  th {
    text-transform: uppercase;
  }
  tbody {
    tr td {
      font-size: 14px;
      padding-top: 20px;
      padding-bottom: 20px;
      color: #374151;
    }
  }
  table {
    border: 1px solid #e5e7eb;
    border-bottom: 0;
    overflow: hidden;
    /* box-shadow: 0px 1.29834px 3.89501px rgba(0, 0, 0, 0.1), 0px 1.29834px 2.59667px rgba(0, 0, 0, 0.06); */
    border-radius: 10px;
  }
  tr:nth-child(even) {
    background: #f8f7ff;
  }
`;
export default function Table({ dataSource, columns, pagination, ...rest }) {
  return (
    <TableStyle>
      <AntTable
        dataSource={dataSource}
        columns={columns}
        {...rest}
        pagination={pagination}
      />
    </TableStyle>
  );
}
