import { gql } from "@apollo/client";

export const REPORTS_TABLE = gql`
  query REPORTS_TABLE($where: expense_bool_exp) {
    expense(order_by: { date: desc }, where: $where) {
      amount
      expense_text
      date
      id
      transaction_id
      expense_tags {
        tag {
          tag_text
        }
      }
      categories_x_expenses {
        category {
          category_text
        }
      }
      upload {
        id
        url
        file_name
      }
    }
  }
`;

export const COMPANY_DETAILS = gql`
  query COMPANY_DETAILS($id: Int) {
    company(where: { users: { id: { _eq: $id } } }) {
      id
      name
      base_currency
    }
  }
`;

export const GET_TAGS = gql`
  query GET_TAGS($id: Int) {
    tags(where: { user_id: { _eq: $id } }) {
      id
      tag_text
      user_id
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES($id: Int) {
    categories(where: { user_id: { _eq: $id } }) {
      id
      category_text
      user_id
    }
  }
`;

export const GET_DOWNLOAD_URL = gql`
  mutation GET_VIEW_URL($name: String!) {
    getviewurl(file_name: $name) {
      status
      url
    }
  }
`;

export const GET_USER_PLAN = gql`
  query GET_USER_PLAN($id: Int) {
    users_x_plans(where: { user_id: { _eq: $id } }) {
      entry_count
      allowed_count
    }
  }
`;
