import { gql } from "@apollo/client";

export const INSERT_FILES = gql`
  mutation INSERT_FILES(
    $url: String
    $filename: String
    $status: String
    $userId: Int
  ) {
    insert_uploads(
      objects: {
        url: $url
        file_name: $filename
        status: $status
        user_id: $userId
      }
    ) {
      affected_rows
      returning {
        url
        file_name
        id
      }
    }
  }
`;

export const UPDATE_UPLOADS = gql`
  mutation UPDATE_UPLOADS($id: Int, $status: String) {
    update_uploads(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const GET_UPLOADS_LIST = gql`
  query GET_UPLOADS_LIST($id: Int) {
    uploads(
      where: { _and: { user_id: { _eq: $id }, status: { _eq: "success" } } }
      order_by: { id: desc }
    ) {
      file_name
      user_id
      created_at
      url
      status
    }
  }
`;

export const GET_DOWNLOAD_URL = gql`
  mutation GET_VIEW_URL($name: String!) {
    getviewurl(file_name: $name) {
      status
      url
    }
  }
`;

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES($id: Int) {
    categories(where: { user_id: { _eq: $id } }) {
      id
      category_text
      user_id
    }
  }
`;

export const GET_TAGS = gql`
  query GET_TAGS($id: Int) {
    tags(where: { user_id: { _eq: $id } }) {
      id
      tag_text
      user_id
    }
  }
`;

export const INSERT_CATEGORIES = gql`
  mutation INSERT_CATEGORIES($object: [categories_insert_input!]!) {
    insert_categories(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_TAGS = gql`
  mutation INSERT_TAGS($object: [tags_insert_input!]!) {
    insert_tags(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const INSERT_EXPENSE = gql`
  mutation INSERT_EXPENSE($object: [expense_insert_input!]!) {
    insert_expense(objects: $object) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const CATEGORIES_X_EXPENSE = gql`
  mutation CATEGORIES_X_EXPENSE(
    $object: [categories_x_expense_insert_input!]!
  ) {
    insert_categories_x_expense(objects: $object) {
      affected_rows
    }
  }
`;

export const INSERT_EXPENSE_TAGS = gql`
  mutation INSERT_EXPENSE_TAGS($object: [expense_tags_insert_input!]!) {
    insert_expense_tags(objects: $object) {
      affected_rows
    }
  }
`;

export const COMPANY_DETAILS = gql`
  query COMPANY_DETAILS($id: Int) {
    company(where: { users: { id: { _eq: $id } } }) {
      id
      name
      base_currency
    }
  }
`;

export const UPLOADFILE = gql`
  mutation GET_UPLOAD_URL($contentType: String!, $fileName: String!) {
    getuploadurl(content_type: $contentType, file_name: $fileName) {
      url
      status
    }
  }
`;

export const UPDATE_USER_PLAN = gql`
  mutation UPDATE_USER_PLAN($id: Int, $entryCount: Int) {
    update_users_x_plans(
      where: { user_id: { _eq: $id } }
      _set: { entry_count: $entryCount }
    ) {
      affected_rows
    }
  }
`;

export const GET_USER_PLAN = gql`
  query GET_USER_PLAN($id: Int) {
    users_x_plans(where: { user_id: { _eq: $id } }) {
      entry_count
      allowed_count
    }
  }
`;
