import { useEffect, useState } from "react";
import LoginPage from "./login";
import OTPPage from "./otpverification";
import CompanyDetails from "./companyDetails";
import { useLocation, useNavigate } from "react-router-dom";

function SignUpForm() {
  const navigate = useNavigate();
  let location = useLocation();
  const [pageName, setPageName] = useState("");

  useEffect(() => {
    setPageName(location.pathname.split("/")[1]);
  }, [location]);

  return (
    <div className="w-full h-screen">
      <div className="w-45 float-left ">
        {pageName === "signup-otp" ? (
          <OTPPage title="signup" />
        ) : pageName === "company-details" ? (
          <CompanyDetails />
        ) : (
          <LoginPage title="signup" />
        )}
      </div>
      {pageName === "company-details" ? (
        <div className="signupTemplate">
          <div>
            <p className="text-white text-text_27_32 font-semibold ">
              Share a few details to make your experience better!
            </p>
          </div>
          <div>
            <div className="font-normal text-white text-lg ">
              <p>
                Your company name will appear on the reports while you download
                them.
              </p>
              <p>You can view/change this later in settings.</p>
              <p>
                Insights for the financial year will be calculated using your
                base currency.
              </p>
              <p> We provide INR and USD options as of now.</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="signupTemplate">
          <div>
            <p className="text-white text-text_27_32 font-semibold ">
              Visualize your company expenses for better decisions
            </p>
          </div>
          <div>
            <div className="font-normal text-white text-lg">
              <p>
                Just upload your transactions as a CSV file and we'll do the
                rest!
              </p>
              <p className="underline font-semibold mb-0">
                Insightful dashboard
              </p>
              <p>
                View top expenses and categories on a monthly / quarterly /
                annual basis
              </p>
              <p className="underline font-semibold mb-0">
                Custom search and filters
              </p>
              <p>Search for specific dates, purpose, mode of payment</p>
              <p className="underline font-semibold mb-0">Report generation</p>
              <p>Download the insights as a PDF </p>
              <p className="underline font-semibold mb-0">
                Privacy and security
              </p>
              <p>End-to-end encryption of all financial data</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignUpForm;
