import { Button, Card } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import errorimage from "../assets/404.svg";

export default function ErrorBoundary() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-center items-center h-screen  ">
        <Card>
          <p className="text-sm-l5 text-gray-900 text-center">Page Not Found</p>
          <img
            src={errorimage}
            alt="empty state"
            className="flex max-w-sm mx-auto mt-3"
          />
          <Button
            type="primary"
            size="large"
            className="w-full mt-4"
            onClick={() => {
              navigate(-1);
            }}
          >
            GO BACK
          </Button>
        </Card>
      </div>
    </>
  );
}
