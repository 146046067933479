import { Button, Card, Form, Input, notification, Spin } from "antd";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import { useMutation } from "@apollo/client";
import { ADMIN_LOGIN } from "../graphql";

function LoginPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [enableLoader, setEnableLoader] = useState(false);

  useEffect(() => {
    if (
      localStorage.token &&
      props.title !== "signup" &&
      jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
        "x-hasura-allowed-roles"
      ][0] === "super_admin"
    ) {
      navigate("/admin/overview");
    } else if (
      localStorage.token &&
      props.title !== "signup" &&
      jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
        "x-hasura-allowed-roles"
      ][0] === "company_user"
    ) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, [location]);

  const [adminLoginEmail] = useMutation(ADMIN_LOGIN);

  const onFinish = (values) => {
    setEnableLoader(true);
    adminLoginEmail({
      variables: {
        email: values.email.toLowerCase(),
        password: values.password,
      },
    }).then((res) => {
      if (res?.data?.admin_login?.status === "success") {
        localStorage.setItem("token", res?.data?.admin_login?.token);
        setEnableLoader(false);
        navigate("/admin/overview");
      } else {
        if (res?.data?.admin_login?.message === "password error") {
          notification.error({ message: "Please enter a correct password" });
          setEnableLoader(false);
        } else {
          notification.error({ message: "Please enter a correct Email id" });
          setEnableLoader(false);
        }
      }
    });
  };

  return (
    <Spin spinning={enableLoader}>
      <div className="flex justify-center items-center h-screen">
        <Card
          style={{
            width: `${props.title === "signup" ? "448px" : "460px"} `,
          }}
          bordered={false}
        >
          <div className={` text-center `}>
            <img
              src={logo}
              alt="expense-insights"
              className="mx-auto"
              style={{ width: "49px", height: "49px" }}
            />

            <>
              <p className="text3xl mt-8 mb-0">Admin Login</p>
              <p className="textsm mt-2">Please enter your details</p>
            </>
          </div>
          <div className={`p-6 shadow-2 mt-8 rounded-lg`}>
            <Form
              name="login"
              layout="vertical"
              requiredMark={false}
              onFinish={onFinish}
            >
              <Form.Item
                label={<span className="textsm">Email</span>}
                name="email"
                rules={[
                  { message: "Enter your email", required: true },
                  { type: "email", message: "Enter a valid email!" },
                ]}
              >
                <Input
                  size="large"
                  type="text"
                  placeholder="Enter your email"
                  style={{ borderRadius: "6px" }}
                />
              </Form.Item>
              <Form.Item
                label={<span className="textsm">Password</span>}
                name="password"
                rules={[{ message: "Enter your password", required: true }]}
              >
                <Input
                  size="large"
                  type="password"
                  placeholder="Enter your password"
                  style={{ borderRadius: "6px" }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="buttonCss uppercase"
                  block
                  size="large"
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </div>
    </Spin>
  );
}

export default LoginPage;
