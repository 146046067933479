import { Button } from "antd";

function PlanCard(props) {
  return (
    <>
      <div
        id="plancard"
        style={{
          border: "2px solid #4F46E5",
          width: "700px",
          height: "400px",
        }}
        className="p-6 flex mt-8 rounded-lg "
      >
        <div className="float-left w-80">
          <p className="text-2xl text-gray-700 font-bold">{props.planName}</p>
          <ul className="text-base text-gray-700 font-medium">
            {props.planList.map((item) => {
              return <li>{item}</li>;
            })}
          </ul>
          <p className="text-2xl font-bold text-gray-700 mt-10">{props.rate}</p>
        </div>
        <div className="float-right">
          <img alt="freeplan" src={props.image} />
        </div>
      </div>
      <Button
        type="primary"
        className="font-semibold mt-12"
        size="large"
        onClick={props.handleUpgrade}
        disabled
      >
        UPGRADE YOUR PLAN
      </Button>
    </>
  );
}

export default PlanCard;
