import { Button, Form, Input, Select as ReactSelect, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import DataProtectedModal from "../../../components/DataProtectedModal";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import Tag from "../../../components/Tag";
import {
  UPDATE_UPLOADS,
  GET_CATEGORIES,
  INSERT_CATEGORIES,
  GET_TAGS,
  INSERT_TAGS,
  INSERT_EXPENSE,
  CATEGORIES_X_EXPENSE,
  INSERT_EXPENSE_TAGS,
  COMPANY_DETAILS,
  UPDATE_USER_PLAN,
  GET_USER_PLAN,
} from "../graphql";
import { capitalizeFirst } from "../../../utils";
import lightOrDarkColor from "@check-light-or-dark/color";
import moment from "moment";
import jwtDecode from "jwt-decode";
import namedColors from "color-name-list";
import { numberWithCommas } from "../../../utils";

const SelectCount = styled.div`
  .ant-select-selector {
    background: transparent !important ;
    border: none !important;
    & .ant-select-selection-item {
      background-color: transparent !important;
      border: none !important;
    }
  }
`;

export default function ReviewData() {
  // eslint-disable-next-line
  const [roleID, setRole] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "X-Hasura-User-Id"
    ]
  );
  const navigate = useNavigate();
  const search = useLocation().search;
  const { state } = useLocation();
  const [tableKey, setTableKey] = useState();
  const [updateUploads] = useMutation(UPDATE_UPLOADS);
  const [insertcategories] = useMutation(INSERT_CATEGORIES);
  const [inserttags] = useMutation(INSERT_TAGS);
  const [insertexpense] = useMutation(INSERT_EXPENSE);
  const [categories_x_expense] = useMutation(CATEGORIES_X_EXPENSE);
  const [tags_x_expense] = useMutation(INSERT_EXPENSE_TAGS);
  const [updateUserPlan] = useMutation(UPDATE_USER_PLAN);
  const [dataProtectedVisible, setDataProtectedVisible] = useState(false);
  const { tableData } = state;
  const [form] = Form.useForm();
  const [categoriesList, setcategoriesList] = useState([]);
  const [enableSaveModal, setEnableSaveModal] = useState(false);
  const [categoriesID, setCategoriesID] = useState();
  const [tagsID, setTagsID] = useState();
  const [tagList, setTagList] = useState([]);
  const [enablediscard, setEnablediscard] = useState(false);
  const [expenseName, setExpenseName] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);

  // eslint-disable-next-line
  const {
    data: company_details,
    loading: loadingCompanydetails,
    refetch: refetchCompany,
  } = useQuery(COMPANY_DETAILS, {
    variables: {
      id: parseInt(roleID),
    },
  });

  const {
    data: planDetails,
    loading: LoadingUserPlan,
    refetch: refetchUserPlan,
  } = useQuery(GET_USER_PLAN, {
    variables: {
      id: parseInt(roleID),
    },
  });
  // eslint-disable-next-line
  const {
    data: categories,
    loading: loadingCategoryList,
    refetch: refetchcategories,
  } = useQuery(GET_CATEGORIES, {
    variables: {
      id: parseInt(roleID),
    },
    onCompleted: (data) => {
      let newArray = [];
      let object = {};
      // eslint-disable-next-line
      data.categories.map((item, index) => {
        newArray.push(capitalizeFirst(item.category_text));
        object[capitalizeFirst(item.category_text)] = item.id;
      });
      setCategoriesID(object);
      setcategoriesList(newArray);
    },
  });
  // eslint-disable-next-line
  const {
    data: tags,
    loading: loadingTagList,
    refetch: refetchTags,
  } = useQuery(GET_TAGS, {
    variables: {
      id: parseInt(roleID),
    },
    onCompleted: (data) => {
      let newArray = [];
      let object = {};
      // eslint-disable-next-line
      data.tags.map((item) => {
        newArray.push(capitalizeFirst(item.tag_text));
        object[capitalizeFirst(item.tag_text)] = item.id;
      });
      setTagsID(object);
      setTagList(newArray);
    },
  });

  const [data, setData] = React.useState();

  useEffect(() => {
    refetchTags();
    refetchcategories();
    refetchCompany();
    refetchUserPlan();
  }, []);

  useEffect(() => {
    let newArray = [];
    let colorObject = {};
    let darkColorList = [];
    if (tableData && company_details && company_details.company.length !== 0) {
      let checkList = namedColors.slice(500, 1600);

      // eslint-disable-next-line
      checkList.map((key) => {
        if (lightOrDarkColor(key.hex) === "dark") {
          darkColorList.push(key.hex);
        }
      });
      // eslint-disable-next-line
      tableData.map((item, index) => {
        colorObject[
          capitalizeFirst(item.category.trim()).replace(/\s\s+/g, " ")
        ] = darkColorList[index];
      });
      tableData.forEach((item, index) => {
        newArray.push({
          key: index,
          date: moment(item.date, "DD/MM/YYYY").format("DD MMM YYYY"),
          expense: capitalizeFirst(item.expense),
          amountTable:
            item.amount.replace(/"/g, "").replaceAll(",", "") > 0
              ? `${
                  company_details.company[0].base_currency === "inr"
                    ? `Rs.${
                        item.amount !== undefined
                          ? parseFloat(
                              item.amount.replace(/"/g, "").replaceAll(",", "")
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                            })
                          : ""
                      }`
                    : `$${
                        item.amount !== undefined
                          ? parseFloat(
                              item.amount.replace(/"/g, "").replaceAll(",", "")
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })
                          : ""
                      }`
                } `
              : `${
                  company_details.company[0].base_currency === "inr"
                    ? `Rs.${
                        item.amount !== undefined
                          ? `(${Math.abs(
                              parseFloat(
                                item.amount
                                  .replace(/"/g, "")
                                  .replaceAll(",", "")
                              )
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                            })})`
                          : ""
                      }`
                    : `$${
                        item.amount !== undefined
                          ? `(${Math.abs(
                              parseFloat(
                                item.amount
                                  .replace(/"/g, "")
                                  .replaceAll(",", "")
                              )
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                            })})`
                          : ""
                      }`
                } `,
          category: capitalizeFirst(item.category.trim()).replace(
            /\s\s+/g,
            " "
          ),
          amount:
            item.amount !== undefined
              ? item.amount.replace(/"/g, "").replaceAll(",", "")
              : "",
          tags:
            item.addInfo === ""
              ? []
              : item.addInfo.replaceAll('"', "").split(","),
          addInfo:
            item.addInfo !== undefined ? item.addInfo.replace(/"/g, "") : null,
          transaction_id: item.transaction_id,
          colorList: colorObject,
        });
      });
      setData(newArray);
    }
  }, [tableData, company_details]);

  const onFinish = (values) => {
    let tagArray = [];
    let newArray = JSON.parse(JSON.stringify(data));

    newArray[tableKey].tagsList =
      values.tags === "" ? [] : values.tags.replaceAll('"', "").split(",");
    tagArray = newArray[tableKey].tagsList.filter((item) => {
      return item !== "";
    });
    newArray[tableKey].addInfo = tagArray.toString();
    newArray[tableKey].tags = tagArray;
    console.log(tagArray);

    newArray[tableKey].transaction_id = values.transactionId.replaceAll(
      " ",
      ""
    );
    setShowEditPopup(false);
    setData(newArray);
  };

  const handleDiscard = () => {
    setEnablediscard(true);
  };
  console.log(data);
  const handleSave = async () => {
    setLoadingSave(true);
    let catObject = {},
      tagObject = [],
      expenseObject = {},
      tableCategory = [],
      catIDArray = {},
      tagIDArray = [],
      expenseCount = 0;

    let save = true;
    // eslint-disable-next-line
    data.map((item) => {
      expenseCount += 1;
      tableCategory.push(item.category);
      item.addInfo.split(",").map((tag) => {
        tagObject.push(capitalizeFirst(tag));
      });

      if (item.tags.length === 0) {
        save = false;
        setEnableSaveModal(true);
        // eslint-disable-next-line
        return;
      }
    });
    if (save) {
      let uniqueChars = [...new Set(tableCategory)];
      let uniqueTags = [...new Set(tagObject)];
      for (let cat = 0; cat < uniqueChars.length; cat++) {
        if (!categoriesList.includes(capitalizeFirst(uniqueChars[cat]))) {
          catObject = {
            company_id: parseInt(company_details.company[0].id),
            category_text: capitalizeFirst(uniqueChars[cat]),
          };
          const res = await insertcategories({
            variables: {
              object: catObject,
            },
          });

          catIDArray[uniqueChars[cat]] =
            res?.data?.insert_categories?.returning[0]?.id;
        }
      }
      for (let tag = 0; tag < uniqueTags.length; tag++) {
        if (!tagList.includes(capitalizeFirst(uniqueTags[tag]))) {
          tagObject = {
            // user_id: parseInt(roleID),
            tag_text: capitalizeFirst(uniqueTags[tag]),
          };
          const res = await inserttags({
            variables: {
              object: tagObject,
            },
          });
          tagIDArray[uniqueTags[tag]] =
            res?.data?.insert_tags?.returning[0]?.id;
        }
      }
      // uniqueChars.forEach((cat) => {
      //   if (!categoriesList.includes(capitalizeFirst(cat))) {
      //     catObject = {
      //       company_id: parseInt(company_details.company[0].id),
      //       category_text: capitalizeFirst(cat),
      //     };
      //     insertcategories({
      //       variables: {
      //         object: catObject,
      //       },
      //     }).then((res) => {
      //       catIDArray[cat] = res?.data?.insert_categories?.returning[0]?.id;
      //     });
      //     // return catIDArray;
      //   }
      // });
      // uniqueTags.map((tag) => {
      //   if (!tagList.includes(capitalizeFirst(tag))) {
      //     tagObject = {
      //       // user_id: parseInt(roleID),
      //       tag_text: capitalizeFirst(tag),
      //     };
      //     inserttags({
      //       variables: {
      //         object: tagObject,
      //       },
      //     }).then((res) => {
      //       tagIDArray[tag] = res?.data?.insert_tags?.returning[0]?.id;
      //     });
      //   }
      // });
      data.map((item) => {
        expenseObject = {
          expense_text: capitalizeFirst(item.expense),
          amount: parseFloat(item.amount).toFixed(2),
          transaction_id: item.transaction_id,
          upload_id: parseInt(new URLSearchParams(search).get("fileid")),
          user_id: parseInt(roleID),
          date: item.date,
        };

        insertexpense({
          variables: {
            object: expenseObject,
          },
        })
          .then((res) => {
            let expenseID = res?.data?.insert_expense?.returning[0]?.id;
            let newCategoriesList = { ...catIDArray, ...categoriesID };
            let newTagList = { ...tagIDArray, ...tagsID };
            // console.log(newCategoriesList, "newCategoriesList");
            // console.log(newTagList, "newTagList");
            // console.log(
            //   item.category,
            //   newCategoriesList[capitalizeFirst(item.category)],
            //   "newCategoriesList"
            // );
            categories_x_expense({
              variables: {
                object: {
                  categories_id:
                    newCategoriesList[capitalizeFirst(item.category)],
                  expense_id: expenseID,
                },
              },
            }).then(() => {
              item.addInfo.split(",").map((tag) => {
                tags_x_expense({
                  variables: {
                    object: {
                      tag_id: newTagList[capitalizeFirst(tag)],
                      expense_id: expenseID,
                    },
                  },
                });
              });
            });
          })
          .then(() => {
            updateUploads({
              variables: {
                id: parseInt(new URLSearchParams(search).get("fileid")),
                status: "success",
              },
            }).then(() => {
              let totalCount =
                expenseCount + planDetails?.users_x_plans[0]?.entry_count;
              updateUserPlan({
                variables: {
                  id: parseInt(roleID),
                  entryCount: totalCount,
                },
              });
            });
          });
        setDataProtectedVisible(true);
      });
    }
  };

  const [showEditPopup, setShowEditPopup] = React.useState(false);
  const columns = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        className: "w-40",
      },
      {
        title: "Expense",
        dataIndex: "expense",
        key: "expense",
      },
      {
        title: "Amount",
        dataIndex: "amountTable",
        key: "amountTable",
        className: "w-48",
        render: (text, record) => {
          return (
            <>
              {record.amount > 0 ? (
                <span>{text}</span>
              ) : (
                <span className="text-brightRed">{text}</span>
              )}
            </>
          );
        },
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        render: (text, record) => {
          let colorname;
          if (record.colorList) {
            colorname = record.colorList;
          }
          return (
            <>
              {colorname && (
                <Tag className="capitalize text-xs" color={colorname[text]}>
                  {text.length > 20 ? (
                    <div className="flex flex-col">
                      <span>
                        {capitalizeFirst(text.slice(0, text.length / 2))}
                      </span>
                      <span>{text.slice(text.length / 2, text.length)}</span>
                    </div>
                  ) : (
                    <span>{capitalizeFirst(text)}</span>
                  )}
                </Tag>
              )}
            </>
          );
        },
      },
      {
        title: "Additional Info (Tags)",
        dataIndex: "tags",
        key: "tags",
        className: "w-64",
        render: (text, record, index) => {
          return (
            record.tags.length !== 0 && (
              <>
                <span>{`${
                  record.tags[0].length > 15
                    ? `${record.tags[0].slice(0, 15)}...`
                    : record.tags[0]
                } `}</span>
                <span className="text-indigo">
                  {record.tags.length > 1 && `,  +${record.tags.length - 1}`}
                </span>
              </>
              // <SelectCount>
              //   <ReactSelect
              //     className="w-full max-w-[200px] text-sm"
              //     mode="tags"
              //     placeholder=""
              //     maxTagPlaceholder={(values) => (
              //       <span className="text-indigo text-sm pl-3 ">{`+${values.length}`}</span>
              //     )}
              //     maxTagCount="responsive"
              //     value={record.tags}
              //     tagRender={(props) => {
              //       return (
              //         <div className="text-sm-l5 pl-1">{`${capitalizeFirst(
              //           props.label
              //         )} ${
              //           record.tags.length > 1
              //             ? record.tags[record.tags.length - 1] !== props.label
              //               ? ", "
              //               : ""
              //             : ""
              //         } `}</div>
              //       );
              //     }}
              //   />
              // </SelectCount>
            )
          );
        },
      },
      {
        title: "",
        dataIndex: "edit",
        width: "50px",
        key: "edit",
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => {
              setTableKey(record.key);
              setShowEditPopup(true);
              setExpenseName(record.expense);
              form.setFieldsValue({
                tags: record.addInfo,
                transactionId: record.transaction_id,
              });
            }}
          >
            Edit
          </Button>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <Spin
      spinning={
        loadingCompanydetails ||
        loadingCategoryList ||
        loadingTagList ||
        LoadingUserPlan
      }
    >
      <div className="mt-8 flex justify-end mr-8 mb-7">
        <Button
          size="large"
          type="ghost"
          className="text-darkSilver hover:text-darkSilver border-danger hover:border-danger hover:border-opacity-80"
          onClick={() => handleDiscard()}
        >
          Discard
        </Button>
        <Button
          size="large"
          className="uppercase ml-6 bg-primaryGreen hover:bg-primaryGreen hover:text-white hover:border-primaryGreen border-primaryGreen hover:border-opacity-80 hover:bg-opacity-80 text-white"
          onClick={() => handleSave()}
          disabled={loadingSave}
        >
          Save
        </Button>
      </div>

      <Table dataSource={data} columns={columns} pagination={true}></Table>
      <Modal
        title={expenseName}
        visible={showEditPopup}
        maskClosable={false}
        keyboard={false}
        onCancel={() => {
          setShowEditPopup(false);
          // form.resetFields();
        }}
      >
        <Form
          name="login"
          layout="vertical"
          requiredMark="optional"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label={<span className="textsm">Tags</span>}
            name="tags"
            required
            rules={[
              () => ({
                validator(_, value) {
                  let alphanumeric = /^([ A-Za-z0-9,]){1,}$/;
                  if (value === "") {
                    return Promise.reject("Please add atleast one tag.");
                  } else if (value && !alphanumeric.test(value)) {
                    return Promise.reject(
                      "Only alphabets and numbers allowed."
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              size="large"
              type="text"
              placeholder="Enter your tags"
              className="rounded-md"
            />
          </Form.Item>
          <Form.Item
            label={<span className="textsm">Transaction ID </span>}
            name="transactionId"
            rules={[
              () => ({
                validator(_, value) {
                  let alphanumeric = /^([ A-Za-z0-9]){1,}$/;
                  if (value && !alphanumeric.test(value)) {
                    return Promise.reject(
                      "Only alphabets and numbers allowed."
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              size="large"
              type="text"
              placeholder="Enter your transaction id"
              className="rounded-md"
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              size="large"
              type="primary"
              className="uppercase"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <DataProtectedModal
        visible={dataProtectedVisible}
        onCancel={() => {}}
      ></DataProtectedModal>
      <Modal
        title={<p className="textHeadings text-center">Discard Data</p>}
        visible={enablediscard}
        footer={null}
        closable={false}
        bodyStyle={{ padding: "0 24px 24px 24px" }}
        mask={true}
        maskClosable={false}
        keyboard={false}
        onCancel={() => {
          setEnablediscard(false);
        }}
      >
        <div className="flex flex-col  ">
          <p className="text-sm-l5 text-gray-500 text-center">
            Are you sure you want to discard this data?
          </p>
          <div className="w-full flex justify-center">
            <Button
              type="primary"
              size="large"
              className="w-28 float-left"
              onClick={() => {
                setEnablediscard(false);
              }}
            >
              GO BACK
            </Button>
            <Button
              type="primary"
              size="large"
              ghost
              className="w-28 ml-8 float-left"
              onClick={() => {
                updateUploads({
                  variables: {
                    id: parseInt(new URLSearchParams(search).get("fileid")),
                    status: "declined",
                  },
                });
                setEnablediscard(false);
                navigate("/upload");
              }}
            >
              DISCARD
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={<p className="textHeadings text-center">Save Data</p>}
        visible={enableSaveModal}
        footer={null}
        closable={false}
        maskClosable={false}
        keyboard={false}
        bodyStyle={{ padding: "0 24px 24px 24px" }}
        mask={true}
        onCancel={() => {
          setEnableSaveModal(false);
          setLoadingSave(false);
        }}
      >
        <div className="flex flex-col  ">
          <p className="text-sm-l5 text-gray-500 text-center">
            Your changes have not been saved. Some entries don't have a tag.
            Please add atleast 1 tag.
          </p>
          <div className="w-full flex justify-center">
            <Button
              type="primary"
              size="large"
              className="w-28 float-left"
              onClick={() => {
                setEnableSaveModal(false);
                setLoadingSave(false);
              }}
            >
              OK, GOT IT
            </Button>
          </div>
        </div>
      </Modal>
    </Spin>
  );
}
