import { Modal as ReactModal } from "antd";
import React from "react";
import CloseIcon from "../../assets/icons/closeIcon.svg";

export default function Modal({ visible, children, ...rest }) {
  return (
    <ReactModal
      visible={visible}
      // onCancel={() => {
      //   onCancel(false);
      // }}
      footer={null}
      closeIcon={<img src={CloseIcon} alt="close icon" />}
      centered
      {...rest}
    >
      {children}
    </ReactModal>
  );
}
