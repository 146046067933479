import { useEffect, useState } from "react";
import {
  Button,
  Avatar,
  Upload,
  Input,
  Form,
  Space,
  Tooltip,
  Spin,
  notification,
} from "antd";
import { avatarMap } from "../../utils";
import jwtDecode from "jwt-decode";
import {
  GET_USER_DETAILS,
  UPDATE_USERNAME,
  UPDATE_COMPANY_NAME,
  UPDATE_AVATAR,
  GET_DOWNLOAD_URL,
  UPDATE_EMAIL,
  VALIDATE_OTP_FOR_MAIL,
} from "./graphql";
import { useMutation, useQuery } from "@apollo/client";
import { getS3UploadUrl, uploadFiles } from "../../utils";
import Modal from "../../components/Modal";
import { UserOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input";

function MyAccount() {
  const [roleID, setRoleID] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "X-Hasura-User-Id"
    ]
  );
  const [form] = Form.useForm();
  const [companyId, setCompanyId] = useState();
  const [enableName, setEnableName] = useState(false);
  const [enableEmail, setEnableEmail] = useState(false);
  const [enableusername, setUserName] = useState(false);
  const { data, loading, refetch: getUserDetails } = useQuery(GET_USER_DETAILS);
  const [updateUsername] = useMutation(UPDATE_USERNAME);
  const [updateCompanyName] = useMutation(UPDATE_COMPANY_NAME);
  const [updateAvatar] = useMutation(UPDATE_AVATAR);
  const [updateEmail] = useMutation(UPDATE_EMAIL);
  const [avatar, setAvatar] = useState(null);
  const [enableOTP, setEnableOTP] = useState(false);
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const [validateOTP] = useMutation(VALIDATE_OTP_FOR_MAIL);
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState("");
  const [enteremail, setEnteremail] = useState(false);

  useEffect(() => {
    if (data && data.users.length !== 0) {
      setCompanyId(data.users[0].company.id);
      const fileDetails =
        data.users[0].avatar !== null ? data.users[0].avatar.split("/") : "";
      if (fileDetails[2] === process.env.REACT_APP_AWS_URL) {
        const fileKey = fileDetails[fileDetails.length - 1];
        getDownloadURL({
          variables: {
            name: fileKey,
          },
        }).then((res) => {
          const url = res.data.getviewurl.url;
          setAvatar(url);
        });
      } else {
        setAvatar(data.users[0].avatar);
      }
      form.setFieldsValue({
        username: data.users[0].name,
        company_name: data.users[0].company.name,
        company_mail: data.users[0].email,
        currencyType: data.users[0].company.base_currency,
      });
    }
  }, [data]);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const result = re.test(email);
    return result;
  };

  const handleChange = (code) => setCode(code);

  return (
    <Spin spinning={loading}>
      <div className="px-4 py-2">
        <p className="text_base">Avatar</p>
        <div className="flex">
          <div className="w-24 h-24 justify-center items-center rounded-full flex">
            {avatar ? (
              <Avatar
                src={avatar}
                className="bg-metal"
                style={{
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 32,
                }}
                size={64}
              ></Avatar>
            ) : (
              <Avatar
                className="bg-indigo"
                style={{
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 32,
                }}
                size={64}
              >
                {data &&
                data.users.length !== 0 &&
                data.users[0].name !== null ? (
                  avatarMap(data.users[0].name, 3)
                ) : (
                  <UserOutlined />
                )}
              </Avatar>
            )}
          </div>
          <div className="flex ml-11 justify-center items-center">
            <Upload
              multiple={false}
              accept=".png,.jpg,.jpeg"
              showUploadList={false}
              progress={false}
              beforeUpload={async (file) => {
                let signedURL = await getS3UploadUrl(file.name, "text/csv");
                if (signedURL[1].statuscode === 200) {
                  let URLs = await uploadFiles([signedURL[0]], [file]);
                  updateAvatar({
                    variables: {
                      id: parseInt(roleID),
                      avatar: URLs[0],
                    },
                  }).then((res) => {
                    const fileDetails =
                      res?.data?.update_users?.returning[0]?.avatar.split("/");
                    const fileKey = fileDetails[fileDetails.length - 1];
                    getDownloadURL({
                      variables: {
                        name: fileKey,
                      },
                    }).then((res) => {
                      const url = res.data.getviewurl.url;
                      setAvatar(url);
                      window.location.reload();
                    });
                  });
                } else {
                  notification.error({
                    message: "An error occurred",
                  });
                }
                return true;
              }}
            >
              <Button type="primary" size="large">
                Upload
              </Button>
            </Upload>
            <Button
              type="primary"
              ghost
              size="large"
              className="ml-6 flex"
              onClick={() => {
                updateAvatar({
                  variables: {
                    id: parseInt(roleID),
                    avatar: null,
                  },
                }).then(() => {
                  setAvatar(null);
                  window.location.reload();
                });
              }}
            >
              Remove
            </Button>
          </div>
        </div>
        <div className="mt-12 ">
          <Form
            name="profile_form"
            layout="vertical"
            className="w-96"
            form={form}
          >
            <Space>
              <Form.Item
                name="username"
                label={
                  <div className="text-charcoal w-96">
                    <span className="float-left">Full name</span>
                    <span className="text-metal float-right">
                      Visible to others
                    </span>
                  </div>
                }
              >
                <Input
                  placeholder="Full name will be here"
                  disabled={!enableusername}
                ></Input>
              </Form.Item>
              <Space style={{ width: "12rem" }}>
                <Tooltip className="mt-1">
                  {!enableusername ? (
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        setUserName(!enableusername);
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <div className="mt-1">
                      <Button
                        type="primary"
                        style={{
                          background: "#53B700",
                          borderColor: "#53B700",
                        }}
                        onClick={() => {
                          if (enableusername) {
                            updateUsername({
                              variables: {
                                id: parseInt(roleID),
                                name: form.getFieldValue("username"),
                              },
                            }).then(() => {
                              getUserDetails();
                            });
                          }
                          setUserName(!enableusername);
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        type="primary"
                        ghost
                        className="ml-4"
                        onClick={() => {
                          setUserName(!enableusername);
                          form.setFieldsValue({ username: data.users[0].name });
                        }}
                      >
                        Discard
                      </Button>
                    </div>
                  )}
                </Tooltip>
              </Space>
            </Space>
            <Space>
              <Form.Item
                name="company_name"
                style={{ width: "24rem" }}
                label={<span className="text-charcoal">Company name</span>}
              >
                <Input
                  className="mt-2"
                  placeholder="This will be the company name"
                  disabled={!enableName}
                ></Input>
              </Form.Item>

              <Space style={{ width: "12rem" }}>
                <Tooltip className="mt-1">
                  {!enableName ? (
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        setEnableName(!enableName);
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <div className="mt-1">
                      <Button
                        type="primary"
                        style={{
                          background: "#53B700",
                          borderColor: "#53B700",
                        }}
                        onClick={() => {
                          if (enableName) {
                            updateCompanyName({
                              variables: {
                                id: parseInt(companyId),
                                name: form.getFieldValue("company_name"),
                              },
                            }).then(() => {
                              getUserDetails();
                            });
                          }
                          setEnableName(!enableName);
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        type="primary"
                        ghost
                        className="ml-4"
                        onClick={() => {
                          setEnableName(!enableName);
                          form.setFieldsValue({
                            company_name: data.users[0].company.name,
                          });
                        }}
                      >
                        Discard
                      </Button>
                    </div>
                  )}
                </Tooltip>
              </Space>
            </Space>
            <Space>
              <Form.Item
                name="company_mail"
                style={{ width: "24rem" }}
                label={<span className="text-charcoal">Company Email</span>}
                rules={[{ type: "email", message: "Enter a valid email!" }]}
              >
                <Input
                  className="mt-2"
                  placeholder="This will be the company email"
                  disabled={!enableEmail}
                ></Input>
              </Form.Item>
              <Space style={{ width: "12rem" }}>
                <Tooltip className="mt-1">
                  {!enableEmail ? (
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        setEnableEmail(!enableEmail);
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <div className="mt-1">
                      <Button
                        type="primary"
                        style={{
                          background: "#53B700",
                          borderColor: "#53B700",
                        }}
                        onClick={() => {
                          if (
                            enableEmail &&
                            form.getFieldValue("company_mail") !== "" &&
                            validateEmail(form.getFieldValue("company_mail"))
                          ) {
                            updateEmail({
                              variables: {
                                email: form.getFieldValue("company_mail"),
                              },
                            }).then((res) => {
                              if (res?.data?.update_email?.status === "error") {
                                notification.error({
                                  message: res?.data?.update_email?.message,
                                });
                                setEnableEmail(false);
                              } else {
                                setCode("");
                                setEnableOTP(true);
                              }
                            });
                          } else {
                            notification.error({
                              message: "Please enter a valid email ID",
                            });
                          }
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        type="primary"
                        ghost
                        className="ml-4"
                        onClick={() => {
                          setEnableEmail(!enableEmail);
                          setEnteremail(false);
                          form.setFieldsValue({
                            company_mail: data.users[0].email,
                          });
                        }}
                      >
                        Discard
                      </Button>
                    </div>
                  )}
                </Tooltip>
              </Space>
            </Space>
            <Form.Item
              label={<span className="textsm">Base Currency</span>}
              name="currencyType"
            >
              <Input className="mt-2 uppercase" disabled />
            </Form.Item>
          </Form>
        </div>
        <div className="mt-36">
          <p className="text-black text-base">Delete Account</p>
          <p>
            Send us an email at{" "}
            <a
              href="mailto:support@nfnlabs.in"
              className="decoration-solid underline"
            >
              support@nfnlabs.in
            </a>{" "}
            to delete your account.
          </p>
        </div>
      </div>
      <Modal
        title={<p className="textHeadings text-center">Verification Code</p>}
        visible={enableOTP}
        footer={null}
        closable={false}
        bodyStyle={{ padding: "0 24px 24px 24px" }}
        mask={true}
      >
        <div className="flex flex-col  ">
          <p className="text-sm-l5 text-gray-500 text-center">
            Enter the verification code sent to your email.
          </p>
          <OtpInput
            value={code}
            onChange={handleChange}
            numInputs={6}
            separator={<span style={{ width: "15px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            inputStyle={{
              border: "1px solid #4F46E5",
              borderRadius: "8px",
              width: "40px",
              height: "45px",
              fontSize: "22px",
              color: "#4F46E5",
              fontWeight: "500",
            }}
            containerStyle={{ justifyContent: "center" }}
            focusStyle={{
              outline: "none",
            }}
          />
          <div>
            <p className="h-0.5 text-textRed mt-2">{errorMessage}</p>
          </div>
          <Button
            type="primary"
            size="large"
            className="w-full mt-8"
            onClick={() => {
              if (code.length !== 6) {
                setErrorMessage("Please enter the valid OTP");
              } else {
                setErrorMessage("");
                validateOTP({
                  variables: {
                    otp: parseInt(code),
                  },
                }).then((res) => {
                  if (res?.data?.validate_otp_for_mail?.status === "success") {
                    setEnableOTP(false);
                    setEnableEmail(!enableEmail);
                    getUserDetails();
                  } else {
                    setErrorMessage("Please enter the valid OTP");
                  }
                });
              }
            }}
          >
            CONTINUE
          </Button>
        </div>
      </Modal>
    </Spin>
  );
}

export default MyAccount;
