export const avatarMap = (name, maxInitials) => {
  return name
    .split(/\s/)
    .map(function (part) {
      return part.substring(0, 1).toUpperCase();
    })
    .filter(function (v) {
      return !!v;
    })
    .slice(0, maxInitials)
    .join("");
};

export const getCurrentFinancialYear = (base_cur) => {
  var fiscalyear = "";
  var today = new Date();
  if (base_cur === "inr") {
    if (today.getMonth() + 1 <= 3) {
      fiscalyear = today.getFullYear() - 1 + "-" + today.getFullYear();
    } else {
      fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1);
    }
  } else if (base_cur === "usd") {
    if (today.getMonth() + 1 <= 9) {
      fiscalyear = today.getFullYear() - 1 + "-" + today.getFullYear();
    } else {
      fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1);
    }
  }
  return fiscalyear;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// export function numberWithCommas(x, cur) {
//   console.log(x, cur);
//   if (cur === "inr") {
//     return x.toLocaleString("en-IN");
//   } else if (cur === "usd") {
//     return x.toLocaleString("en-US");
//   } else {
//     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   }
// }

export async function getS3UploadUrl(fileName, contentType) {
  const query = {
    query: `mutation GET_UPLOAD_URL($contentType: String!, $fileName: String!) {
      getuploadurl(content_type: $contentType, file_name: $fileName) {
      url
      status
    }
  }`,
    variables: {
      fileName: fileName,
      contentType: contentType,
    },
  };
  let postData = await fetch(`${process.env.REACT_APP_API}`, {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const { data: uploadLink } = await postData.json();
  return [uploadLink.getuploadurl.url, { statuscode: postData.status }];
}

export function uploadFiles(urlList, fileList) {
  return new Promise((resolve, reject) => {
    const formUploadPromiseArray = fileList.map(
      (file, index) =>
        new Promise((resolveUpload, rejectUpload) => {
          const xhr = new XMLHttpRequest();
          xhr.file = file;
          xhr.onreadystatechange = function (e) {
            if (this.readyState === 4 && this.status === 200) {
              resolveUpload(urlList[index]);
            } else if (this.readyState === 4 && this.status !== 200) {
              rejectUpload(e);
            }
          };
          xhr.open("put", urlList[index], true);
          if (
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "application/pdf" ||
            file.type === "text/csv"
          ) {
            xhr.setRequestHeader("Content-Type", file.type);
          }
          xhr.send(file);
        })
    );

    Promise.all(formUploadPromiseArray)
      .then((urls) => {
        resolve(urls.map((url) => url.split("?")[0]));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const capitalizeFirst = (str) => {
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export function numFormatter(number) {
  if (number > 100000000) {
    return Math.ceil(number / 100000000) + "B";
  } else if (number > 100000) {
    return Math.ceil(number / 100000) + "M";
  } else if (number > 1000) {
    return Math.ceil(number / 1000) + "K";
  } else {
    return number;
  }
}

export const getToken = () => {
  const user = localStorage.getItem("token");
  if (user) {
    return true;
  } else {
    return false;
  }
};

export const calc = (num, base_cur) => {
  let with2Decimal;
  if (num % 1 !== 0) {
    let val = num;
    if (num.toString().split(".")[1].length === 1) {
      with2Decimal =
        base_cur === "inr"
          ? `${val.toLocaleString("en-IN")}0`
          : `${val.toLocaleString("en-US")}0`;
    } else {
      with2Decimal =
        base_cur === "inr"
          ? parseFloat(
              val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
            ).toLocaleString("en-IN")
          : parseFloat(
              val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
            ).toLocaleString("en-US");
    }
    // with2Decimal =
    //   num.toString().split(".")[1].length === 1
    //     ? `${val.toLocaleString("en-US", {
    //         minimumFractionDigits: 2,
    //       })}0`
    //     : val
    //         .toLocaleString("en-US", {
    //           minimumFractionDigits: 2,
    //         })
    //         .match(/^-?\d+(?:\.\d{0,2})?/)[0];
  } else {
    with2Decimal =
      base_cur === "inr"
        ? num.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
          })
        : num.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          });
  }
  return with2Decimal;
};
function isBool(x) {
  return typeof x === "boolean";
}

function getKeys(obj, prefix = "") {
  if (typeof obj === "undefined" || obj === null) return [];
  return [
    ...Object.keys(obj).map((key) => `${prefix}${key}`),
    ...Object.entries(obj).reduce((acc, [key, value]) => {
      if (typeof value === "object")
        return [...acc, ...getKeys(value, `${prefix}${key}.`)];
      return acc;
    }, []),
  ];
}
function flatObject(obj, prefix = "") {
  if (typeof obj === "undefined" || obj === null) return {};
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === "object")
      return { ...acc, ...flatObject(value, `${prefix}${key}.`) };
    return { ...acc, [`${prefix}${key}`]: value };
  }, {});
}

function escapeCsvValue(cell) {
  if (cell.replace(/ /g, "").match(/[\s,"]/)) {
    return '"' + cell.replace(/"/g, '""') + '"';
  }
  return cell;
}

const getRenderData = (data) => {
  let str = "";
  if (typeof data !== "string")
    if (Array.isArray(data.props?.children)) {
      data.props?.children.map((child) => {
        if (child) {
          if (typeof child === "object") {
            str += getRenderData(child);
          } else {
            str += child;
          }
        }
      });
      // str += data.props?.children.join("");
    } else if (typeof data.props?.children === "object") {
      str += getRenderData(data.props?.children);
    } else if (typeof data.props?.children === "boolean") {
      str = "";
    } else {
      str +=
        data.props?.children !== 0
          ? data.props?.children
          : data.props?.children === 0
          ? 0
          : "";
    }
  else {
    str += data;
  }
  return str;
};

function objectsToCsv(arrayOfObjects, colKeys) {
  // collect all available keys
  const keys = new Set(
    arrayOfObjects.reduce((acc, item) => [...acc, ...getKeys(item)], [])
  );
  const objectKeys = new Set();
  for (let key of keys.keys()) {
    objectKeys.add(colKeys[key]);
  }
  // for each object create all keys
  const values = arrayOfObjects.map((item) => {
    const fo = flatObject(item);
    const val = Array.from(keys).map((key) =>
      key in fo ? escapeCsvValue(`${fo[key]}`) : ""
    );
    return val.join(",");
  });
  return `${Array.from(objectKeys).join(",")}\n${values.join("\n")}`;
}

export function downloadCSVFile(records, columns, fileName) {
  let finalRecord = (records || []).map((record, idx) => {
    const finalRecord = {};

    for (const col of columns) {
      const propName = col.dataIndex || col.key;
      if (propName && isBool(col.isExportable) ? col.isExportable : true) {
        const text = record[propName];
        finalRecord[propName] = text;
        //
        if (col.render) {
          finalRecord[propName] = getRenderData(col.render(text, record, idx)); //col.render(text, record, idx);
        } else {
          finalRecord[propName] = text !== 0 ? text : text === 0 ? 0 : "";
        }
      }
    }
    return finalRecord;
  });
  let colKeys = {};
  columns.map((column) => {
    const propName = column.dataIndex || column.key;
    if (propName) {
      colKeys[propName] = column.title;
    }
  });
  let csv = objectsToCsv(finalRecord, colKeys);
  const blob = new Blob([csv]);
  const a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = `${fileName || "tableData"}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
