import { useQuery } from "@apollo/client";
import { CATEGORY_LIST } from "./graphql";
import Categoriesimage from "../../assets/Categoriesimage.svg";
import { useEffect, useState } from "react";

function Categories() {
  const { data, refetch } = useQuery(CATEGORY_LIST);
  const [categoriesList, setCategoryList] = useState([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data) {
      let newArray = [];
      data.categories.map((item) => newArray.push(item.category_text));
      setCategoryList(newArray);
    }
  }, [data]);

  return (
    <div>
      {categoriesList.length === 0 ? (
        <div className="pl-80 pt-48">
          <p className="text-lg-l5-semibold text-gray-500 mt-4">
            There is no categories added yet
          </p>
          <img
            style={{ width: "360px", height: "250px" }}
            src={Categoriesimage}
            alt="categories"
          />
        </div>
      ) : (
        <>
          <p className="textHeadings text-gray-700">Categories</p>
          {categoriesList.map((item, index) => {
            return <p className="textxlpurple mb-4 capitalize">{item}</p>;
          })}
        </>
      )}
    </div>
  );
}

export default Categories;
