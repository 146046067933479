import { Avatar, Button, Form, Input, Upload, notification, Spin } from "antd";
import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { getS3UploadUrl, uploadFiles, avatarMap } from "../../../utils";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_USER_BY_PK,
  GET_DOWNLOAD_URL,
  UPDATE_AVATAR,
  ADMIN_PASSWORD_CHANGE,
} from "../graphql";
import { UserOutlined } from "@ant-design/icons";

export default function AdminSettings() {
  const [roleID, setRoleID] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "X-Hasura-User-Id"
    ]
  );
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const {
    data,
    loading,
    refetch: getUserDetails,
  } = useQuery(GET_USER_BY_PK, {
    variables: {
      id: parseInt(roleID),
    },
  });
  const [updateAvatar] = useMutation(UPDATE_AVATAR);
  const [updatePassword] = useMutation(ADMIN_PASSWORD_CHANGE);
  const [avatar, setAvatar] = useState(null);
  const [form] = Form.useForm();
  const [disableConfirm, setDisableConfirm] = useState(true);

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (data && data?.users_by_pk.length !== 0) {
      const fileDetails =
        data?.users_by_pk.avatar !== null
          ? data?.users_by_pk.avatar.split("/")
          : "";
      if (fileDetails[2] === process.env.REACT_APP_AWS_URL) {
        const fileKey = fileDetails[fileDetails.length - 1];
        getDownloadURL({
          variables: {
            name: fileKey,
          },
        }).then((res) => {
          const url = res.data.getviewurl.url;
          setAvatar(url);
        });
      } else {
        setAvatar(data?.users_by_pk.avatar);
      }
      form.setFieldsValue({
        email: data?.users_by_pk.email,
      });
    }
  }, [data]);

  const onFinish = (values) => {
    updatePassword({
      variables: {
        email: values.email,
        currentPassword: values.oldPassword,
        newPassword: values.newPassword,
      },
    }).then((res) => {
      if (res?.data?.admin_change_password?.status === "success") {
        notification.success({
          message: "Password changed",
        });
        form.resetFields();
        form.setFieldsValue({
          email: data?.users_by_pk.email,
        });
        setDisableConfirm(true);
      } else if (res?.data?.admin_change_password?.status === "error") {
        res?.data?.admin_change_password?.message === "current password error"
          ? notification.error({
              message: "Current password is wrong",
            })
          : notification.error({
              message: "Error in password update. Please try again.",
            });
      }
    });
  };

  return (
    <Spin spinning={loading}>
      <div className="p-8">
        <div className="flex">
          <div className="w-24 h-24 justify-center items-center rounded-full flex">
            {avatar ? (
              <Avatar
                src={avatar}
                className="bg-metal"
                style={{
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 32,
                }}
                size={64}
              ></Avatar>
            ) : (
              <Avatar
                className="bg-indigo"
                style={{
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 32,
                }}
                size={64}
              >
                {data &&
                data.users_by_pk.length !== 0 &&
                data.users_by_pk.name !== null ? (
                  avatarMap(data.users_by_pk.name, 3)
                ) : (
                  <UserOutlined />
                )}
              </Avatar>
            )}
          </div>
          <div className="flex ml-11 justify-center items-center">
            <Upload
              multiple={false}
              accept=".png,.jpg,.jpeg"
              showUploadList={false}
              progress={false}
              beforeUpload={async (file) => {
                let signedURL = await getS3UploadUrl(file.name, "text/csv");
                if (signedURL[1].statuscode === 200) {
                  let URLs = await uploadFiles([signedURL[0]], [file]);
                  updateAvatar({
                    variables: {
                      id: parseInt(roleID),
                      avatar: URLs[0],
                    },
                  }).then((res) => {
                    const fileDetails =
                      res?.data?.update_users?.returning[0]?.avatar.split("/");
                    const fileKey = fileDetails[fileDetails.length - 1];
                    getDownloadURL({
                      variables: {
                        name: fileKey,
                      },
                    }).then((res) => {
                      const url = res.data.getviewurl.url;
                      setAvatar(url);
                      window.location.reload();
                    });
                  });
                } else {
                  notification.error({
                    message: "An error occurred",
                  });
                }
                return true;
              }}
            >
              <Button type="primary" size="large">
                Upload
              </Button>
            </Upload>
            <Button
              type="primary"
              ghost
              size="large"
              className="ml-6 flex"
              onClick={() => {
                updateAvatar({
                  variables: {
                    id: parseInt(roleID),
                    avatar: null,
                  },
                }).then(() => {
                  setAvatar(null);
                  window.location.reload();
                });
              }}
            >
              Remove
            </Button>
          </div>
        </div>
        <div className="mt-11 max-w-3xl">
          <Form
            name="login"
            layout="vertical"
            requiredMark={false}
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label={<span className="textsm text-charcoal">Admin Email</span>}
              name="email"
              className="w-1/2"
            >
              <Input
                size="large"
                type="text"
                placeholder="Admin Email"
                disabled
                className=" text-base  rounded-md"
              />
            </Form.Item>
            <div className="my-11 font-medium">Change Password</div>
            <div className=" grid grid-cols-2 gap-x-8">
              <div>
                <Form.Item
                  label={
                    <span className="textsm text-charcoal">
                      Current Password
                    </span>
                  }
                  name="oldPassword"
                  rules={[
                    { message: "Enter the current password", required: true },
                  ]}
                >
                  <Input
                    size="large"
                    type="password"
                    placeholder="Enter old password"
                    className=" text-base  rounded-md"
                  />
                </Form.Item>
              </div>
              <div></div>
              <div>
                <Form.Item
                  label={
                    <span className="textsm text-charcoal">New Password</span>
                  }
                  name="newPassword"
                  rules={[
                    { message: "Enter the new password", required: true },
                  ]}
                >
                  <Input
                    size="large"
                    type="password"
                    placeholder="Enter new password"
                    className=" text-base  rounded-md"
                    onChange={(e) => {
                      e.target.value === ""
                        ? setDisableConfirm(true)
                        : setDisableConfirm(false);
                    }}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label={
                    <span className="textsm text-charcoal">
                      Confirm New Password
                    </span>
                  }
                  name="confirmNewPassword"
                  rules={[
                    { message: "Enter the confirm password", required: true },
                    () => ({
                      validator(_, value) {
                        if (value !== form.getFieldValue("newPassword")) {
                          return Promise.reject(
                            "Password should be same as new password"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    type="password"
                    placeholder="Re-enter new password"
                    className=" text-base  rounded-md"
                    disabled={disableConfirm}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="flex justify-end items-center">
              <Form.Item>
                <Button
                  htmlType="button"
                  type="primary"
                  ghost
                  size="large"
                  className="mr-6"
                  onClick={() => {
                    form.resetFields();
                    form.setFieldsValue({
                      email: data?.users_by_pk.email,
                    });
                    setDisableConfirm(true);
                  }}
                >
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary" size="large">
                  Update
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
}
